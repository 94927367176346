/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import classnames from 'classnames';
import _, {noop, isEmpty, flow} from 'lodash';
import flatMap from 'lodash/fp/flatMap';
import sortBy from 'lodash/fp/sortBy';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

import {ArrowForward, ArrowRight} from 'assets/img/all-icons';
import Header from 'components/common/Header/Header';
import FlightDetails from 'components/processes/monitoring/FlightDetails/FlightDetails';
import CriticalPath from 'components/processes/monitoring/criticalPath/CriticalPath';
import TurnaroundDetails from 'components/turnaround/turnaroundDetails/TurnaroundDetails';
import EventFrame from '../Frame/Frame';
import DetailTable from '../DetailTable/DetailTable';
import EventFrameModal from '../eventFrameModal/EventFrameModal';
import '../Flight.scss';
import NewEventModal from "../eventFrameModal/NewEventModal";

const FlightMonitoringView = () => {
    const measureRef = useRef();
    const processesRef = useRef();
    const eventFramesContainerRef = useRef();
    const [isTimelineOpen, setTimelineOpen] = useState(true);
    const [areDetailsOpen, setDetailsOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [containerStyles, setContainerStyles] = useState({
        height: '0',
        overflow: 'hidden',
        transition: 'all 0.2s ease',
    });

    const [processesStyles, setProcessesStyles] = useState({
        height: '0',
        overflow: 'hidden',
        transition: 'all 0.2s ease',
    });

    const {turnaround, error} = useSelector((state) => state.turnaround);

    const toggleTimeline = useCallback(() => {
        setTimelineOpen((prevState) => !prevState);
    }, []);

    const openTurnaroundDetails = useCallback(() => {
        setDetailsOpen(true);
    }, []);

    const closeTurnaroundDetails = useCallback(() => {
        setDetailsOpen(false);
    }, []);

    const handleFrameClick = useCallback((event, error) => {
        if (!error) {
            setSelectedEvent(event);
        }

    }, []);

    const closeFrameModal = useCallback(() => {
        setSelectedEvent(null);
    }, []);

    const eventsToRender = useMemo(() => {
        return flow([
            flatMap('event_classes'),
            sortBy('actual'),
        ])(turnaround.processes);
    }, [turnaround.processes]);

    const processesToDisplayAsCritical = useMemo(() => turnaround.processes?.filter(process => process.is_critical), [turnaround.processes]);

    if (error) {
        toast.error(error);
    }

    useEffect(() => {
        setContainerStyles(prevState => ({
            ...prevState,
            height: isTimelineOpen && !isEmpty(eventsToRender) ? `${200}px` : '0',
        }));

        setProcessesStyles((prevState) => ({
            ...prevState,
            height: !isTimelineOpen ? `${processesRef.current?.clientHeight + 200}px` : '0',
        }));
    }, [isTimelineOpen, eventsToRender, processesRef.current?.clientHeight]);

    return (
        <TurnaroundDetails isOpen={areDetailsOpen} turnaround={turnaround} onCloseClick={closeTurnaroundDetails}>
            <div className="flight-tracker">
                <div className="flight-tracker-header">

                    {/*Main header page - nav menu*/}
                    <Header>
                        <div className="top-panel">
                            <div className="general-flight-details" style={{width: areDetailsOpen ? '100%' : '85%'}}>
                                <FlightDetails turnaround={turnaround}/>
                            </div>
                            {!areDetailsOpen && (
                                <div role="button" tabIndex={0} onKeyPress={noop}
                                     className="turnaround-details" onClick={openTurnaroundDetails}
                                >
                                    <h6 className="title">Turnaround details</h6>
                                    <ArrowForward width="20px" height="20px"/>
                                </div>
                            )}
                        </div>
                    </Header>
                    {/*Main header page - nav menu*/}
                </div>

                <div>
                    <div style={processesStyles}>
                        <div ref={processesRef}>
                            <CriticalPath processes={processesToDisplayAsCritical}/>
                        </div>
                    </div>
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyPress={noop}
                        className="info-toggle table-max__width pointer"
                        onClick={toggleTimeline}
                    >
                        <span className="info-toggle-title">{isTimelineOpen ? 'Critical' : 'Timeline'}</span>
                        <div className="info-toggle-line">
                            <div className="arrow-box">
                                <ArrowRight
                                    width="32px"
                                    className={classnames('timeline-arrow', {'timeline-arrow_open': isTimelineOpen})}
                                />
                            </div>
                        </div>
                    </div>


                    {/*print Critical images*/}
                    <div className="images-container" style={containerStyles}>
                        <div ref={eventFramesContainerRef} style={{overflow: 'hidden'}}>
                            <div ref={measureRef} className="cameras-box scroll-bar__style">
                                {eventsToRender.map(event => {
                                    return event['events'].map(item => {
                                       return <EventFrame
                                                ref={eventFramesContainerRef}
                                                key={event['_id']}
                                                event={item}
                                                airportIATA={turnaround.current_airport}
                                                onClick={handleFrameClick}
                                        />
                                    })
                                })}
                            </div>
                        </div>
                        {/*print Critical*/}
                    </div>
                </div>


                {/*DetailTable where we have process and other*/}
                <DetailTable/>
                {/*DetailTable where we have process and other*/}

                {/*Modal image - when we click on some image in TimeLine component*/}
                <NewEventModal isOpen={!!selectedEvent} handleModalClose={closeFrameModal} airportIATA={turnaround.current_airport} event={selectedEvent}/>
                {/*<EventFrameModal isOpen={!!selectedEvent} handleModalClose={closeFrameModal}*/}
                {/*                 airportIATA={turnaround.current_airport} event={selectedEvent}/>*/}
                {/*Modal image - when we click on some image in TimeLine component*/}
            </div>
        </TurnaroundDetails>
    );
};

export default FlightMonitoringView;