import React from 'react';

import { CAMERA_MODES } from 'store/constants/camera.constants';
import { ActiveMode, FixesMode, OnboardingMode } from 'assets/img/all-icons';

export const getMode = (statuses) => {
  if (
    statuses.includes(CAMERA_MODES.FIXES) &&
    statuses.includes(CAMERA_MODES.ONBOARDING)
  ) {
    return CAMERA_MODES.ONBOARDING_FIXES;
  }

  if (statuses.includes(CAMERA_MODES.FIXES)) {
    return CAMERA_MODES.FIXES;
  }

  if (statuses.includes(CAMERA_MODES.ONBOARDING)) {
    return CAMERA_MODES.ONBOARDING;
  }

  if (statuses.includes(CAMERA_MODES.ACTIVE)) {
    return CAMERA_MODES.ACTIVE;
  }

  return 'Error';
};

export const getIconByMode = (mode) => {
  switch (mode) {
    case CAMERA_MODES.ACTIVE:
      return <ActiveMode width="16px" height="16px" />;
    case CAMERA_MODES.FIXES:
      return <FixesMode width="14px" height="16px" />;
    case CAMERA_MODES.ONBOARDING:
      return <OnboardingMode width="16px" height="16px" />;
    case CAMERA_MODES.ONBOARDING_FIXES:
      return <OnboardingMode width="16px" height="16px" />;
    default:
      return '';
  }
};
