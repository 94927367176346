/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Rect, Image, Layer, Stage } from 'react-konva';

import Modal from 'components/common/Modal/Modal';
import { fetchCameraFrame } from 'store/controllers/camera.controllers';

import { isEqual } from 'lodash';
import s from './EventFrameModal.module.scss';
import {Preloader} from "../../../components/common/Preloader/Preloader";
import {defaultFormat} from "../../../utils/helpers";

const img = new window.Image();

const EventFrameModal = ({ isOpen, handleModalClose, airportIATA, event }) => {
  console.log(event, 'event__event__event')
  const editorRef = useRef();
  const imageRef = useRef();
  const containerRef = useRef();
  const [image, setImage] = useState('');
  const [imageObj, setImageObj] = useState(null);

  const [frameLoading, setFrameLoading] = useState(false);
  const [frameError, setFrameError] = useState(null);

  const onModalClose = useCallback(() => {
    handleModalClose();
  }, [handleModalClose]);

  const fitStage = useCallback(() => {
    const stage = editorRef.current;
    const konvaImage = imageRef.current;
    const currentContainerRef = containerRef.current

    if (currentContainerRef && konvaImage) {
      const width = containerRef.current?.offsetWidth || 0;
      const height = containerRef.current?.offsetHeight || 0;

      stage.width(width);
      stage.height(height);

      const imageWidth = 1920
          // img.width;
      const imageHeight = 1080
          // img.height;

      const widthFit = width / imageWidth;
      const heightFit = height / imageHeight;

      const scale = widthFit > heightFit ? heightFit : widthFit;

      konvaImage?.width(imageWidth * scale);
      konvaImage?.height(imageHeight * scale);
      konvaImage?.x((stage?.width() - konvaImage?.width()) / 2);
      konvaImage?.y((stage?.height() - konvaImage?.height()) / 2);

      konvaImage?.draw();
    }
  }, [editorRef, imageRef, containerRef]);

  // TODO: figure out where this gets invoked
  const handleImageLoad = useCallback(() => {
    fitStage();
    setImageObj(img);
    img.removeEventListener('load', handleImageLoad);
  }, [fitStage]);

  const handleImageError = useCallback(
    (e) => {
      console.error(e);

      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageError);
    },
    [handleImageLoad],
  );

  useEffect(() => {
    if (!event) return;
    const params = {frame_id: `/${event['frame_id']}`, airport: airportIATA,}
    console.log(event['frame_id'], 'EventFrameModal__EventFrameModal')
    fetchCameraFrame(params, setFrameLoading, setFrameError).then((data) => {setImage(data);});
  }, [event, airportIATA]);

  useEffect(() => {
    if (image) {
      img.src = image;
      img.addEventListener('load', handleImageLoad);
      img.addEventListener('error', handleImageError);
    }
  }, [handleImageLoad, image, handleImageError]);

  useEffect(() => {

    if (!isOpen) {
      setImageObj(null);
      setImage('');
    } else {
      handleImageLoad()
    }
  }, [isOpen]);

  if (!event) return null;

  const {bbox: [x, y, width, height]} = event;
  // console.log(width, height, "width", "height", x, 'X', y, 'Y');

  // console.log(imageRef, 'imageRef')

  return (
    <Modal isOpen={isOpen} className={s.modal} onModalOpen={handleImageLoad} onModalClose={onModalClose}>
      <div ref={containerRef} className={s.imageContainer}>
        <button className={s.closeButton} onClick={onModalClose}>X</button>
        <Stage ref={editorRef}>
          <Layer>
            {/*<Image image={imageObj} ref={imageRef} listening={false} />*/}
            {frameLoading && <Preloader size="small"/>}
            {imageObj && <Image image={imageObj} ref={imageRef} listening={false} />}
            {frameError && <span>Failed load</span>}
            {/*{[1].map(() => {*/}
            {/*  if (!imageRef.current) return null;*/}
            {/*  const transform = imageRef.current?.getAbsoluteTransform().copy();*/}

            {/*  const imageWidth = imageRef.current?.width();*/}
            {/*  const imageHeight = imageRef.current?.height();*/}
            {/*  // console.log(imageWidth, imageHeight, 'imageWidth, imageHeight')*/}

            {/*  const absoluteGatePoint = transform.point({*/}
            {/*    x: x * imageWidth,*/}
            {/*    y: y * imageHeight,*/}
            {/*  });*/}

            {/*  return <Rect x={absoluteGatePoint.x} y={absoluteGatePoint.y} width={width * imageWidth}*/}
            {/*              height={height * imageHeight} stroke="#1f80b4" strokeWidth={3}/>*/}
            {/*})}*/}
          </Layer>
        </Stage>
        <div className={s.imgInformation}>
          <h4>{event['display']}</h4>
          <h4>{defaultFormat(event['timestamp'])}</h4>
        </div>
      </div>
    </Modal>
  );
};

EventFrameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  airportIATA: PropTypes.string.isRequired,
};

export default React.memo(
  EventFrameModal,
  (prevProps, nextProps) => isEqual(prevProps.event, nextProps.event) && prevProps.isOpen === nextProps.isOpen,
);
