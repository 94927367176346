import {useState, useEffect} from 'react';
import {endOfDay, differenceInCalendarDays} from 'date-fns';
import PropTypes from 'prop-types';
import {calculateTimePercent} from 'utils/helpers';
import useNow from 'utils/useNow';
import {useSelector} from 'react-redux';

const decideColor = (isDelayed) => (isDelayed ? '#e85c5c' : '#54d771');

const FlightStatus = ({expectedStart, expectedEnd, realStart, realEnd, isDelayed}) => {
    if (!expectedEnd) {
        expectedEnd = realEnd;
    }


    const now = useNow();
    const [statusColor, setStatusColor] = useState(decideColor(expectedEnd < (realEnd || now)));

    const {filter} = useSelector(({processes}) => processes);

    if (differenceInCalendarDays(expectedEnd, expectedStart) > 0) {
        expectedEnd = endOfDay(new Date());
    }

    if (!realEnd && differenceInCalendarDays(now, filter.date) > 0) {
        realEnd = endOfDay(new Date());
    }

    useEffect(() => {
        setStatusColor(decideColor(expectedEnd < (realEnd || now)));
    }, [expectedEnd, isDelayed, now, realEnd]);

    useEffect(() => {
        return () => {
            console.log('return Fligth_Status_COmponent')
            setStatusColor(null)
        }
    }, [])

    return (
        <>
      <span
          className="expected-status"
          style={{
              left: `${calculateTimePercent(expectedStart)}%`,
              width: `${calculateTimePercent(expectedEnd) - calculateTimePercent(expectedStart)}%`,
          }}
      />
            <span className="real-status" style={{
                    left: `${calculateTimePercent(realStart)}%`,
                    width: `${calculateTimePercent(realEnd || now) - calculateTimePercent(realStart)}%`,
                    background: statusColor,
                    ...(Math.trunc(realEnd / 1000) < Math.trunc(Date.now() / 1000) && {
                        borderRadius: 4,
                    }),
                }}
            />
        </>
    );
};

FlightStatus.propTypes = {
    realStart: PropTypes.number,
    realEnd: PropTypes.number,
    expectedStart: PropTypes.number,
    expectedEnd: PropTypes.number,
    isDelayed: PropTypes.bool.isRequired,
};

FlightStatus.defaultProps = {
    realStart: null,
    realEnd: null,
    expectedStart: null,
    expectedEnd: null,
};

export default FlightStatus;
