export const PAGE_CHANGE = 'PAGE_CHANGE';
export const PROCESS_VIEW_CHANGE = 'PROCESS_VIEW_CHANGE';

export const checkPath = () => {
  const path_setting = JSON.parse(localStorage.getItem('path_setting'))
  if (path_setting) {
    return path_setting['location']
  }
  return '/turnaround/'
}

export const routes = {
  HOME: '/',
  TURNAROUND: '/turnaround/',
  STANDARDS: '/standards',
  USER_PROFILE: '/profile',
  TURNAROUND_SETTINGS: '/turnaround_settings',
  CAMERAS: '/cameras',
  AIRPORT_SETTINGS: '/airport-settings',
  ACCOUNTS: '/accounts',
  ADD_ACCOUNT: '/accounts/add',
  EDIT_ACCOUNT: '/accounts',
  API: '/api',
  SIGNIN: '/sign-in',
  TOGGLE_PATH: checkPath()
};

export const processViews = {
  ADMIN: 'admin',
  MONITORING: 'monitoring',
};
