import {startOfDay} from 'date-fns';
import {
    FETCH_PROCESSES_REQUEST,
    FETCH_PROCESSES_ERROR,
    FETCH_PROCESSES_SUCCESS,
    ESTABLISH_CONNECTION_ERROR,
    PAGE_SIZE,
    CHANGE_DATES,
    ESTABLISH_CONNECTION_SUCCESS,
    CLOSE_CONNECTION_SUCCESS,
    UPDATE_TURNAROUND_FILTERS,
    REMOVE_TURNAROUND_FILTER,
    SET_PAGINATE,
    CHANGE_FLIGHTS_PAGE,
    UPDATE_PROCESSES, CLEAR_ERROR_STATE, UPDATE_MAIN_DATA, ESTABLISH_CONNECTION_PROCESSES,
} from 'store/constants/process.constants';

const startDay = () => {
    let data = JSON.parse(localStorage.getItem('path_setting'))
    if (data) {
        return new Date(data.date)
    }
    return new Date()
}

const initialState = {
    processes: [],
    isLoading: true,
    isConnected: false,
    error: '',
    perPage: PAGE_SIZE,
    paginate: false,
    page: 1,
    total: 1,
    filter: {
        airlines: [],
        airports: [],
        airport: null,
        flights: [],
        terminals: [],
        gates: [],
        date: startOfDay(startDay())
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ESTABLISH_CONNECTION_SUCCESS:
            console.log('ЗАШЕЛ СЮДА')
            return {...state, isConnected: true};

        case ESTABLISH_CONNECTION_PROCESSES:
            return {...state, isLoading: false, isConnected: false, error: action.payload,};

        case CLOSE_CONNECTION_SUCCESS:
            return {...state, isConnected: false, processes: []};

        case FETCH_PROCESSES_REQUEST:
            return {...state, isLoading: true};
        case FETCH_PROCESSES_ERROR:
            return {...state, error: action.payload, isLoading: false, isConnected: false}; // test isConnected in this case
        case FETCH_PROCESSES_SUCCESS:
            return {...state, processes: action.payload, isLoading: false, error: '',};
        case UPDATE_MAIN_DATA:
            return {...state, processes: action.payload};
        case UPDATE_PROCESSES:
            const copyProcesses = [...state.processes];
            const index = copyProcesses.findIndex(({_id}) => _id === action.payload['_id']);
            if (index > -1) {
                copyProcesses[index] = {...copyProcesses[index], ...action.payload}
            } else {
                copyProcesses.push(action.payload)
            }
            return {...state, processes: copyProcesses, isLoading: false, error: '',};
        // case FETCH_TURNAROUND_SUCCESS:
        //     return {
        //         ...state,
        //         processes: state.processes.find(({id}) => action.payload.id === id)
        //             ? state.processes.map((process) => (process.id === action.payload.id ? action.payload : process))
        //             : !isEmpty(action.payload)
        //                 ? state.processes.concat(action.payload)
        //                 : state.processes,
        //         isLoading: false,
        //         error: '',
        //     };
        case CHANGE_DATES:
            return {...state, filter: {...state.filter, date: action.payload}};
        case UPDATE_TURNAROUND_FILTERS:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.alias || 'unknown']: (state.filter[action.payload.alias || 'unknown'] || []).concat(action.payload),
                    airport: action.payload
                },
                page: 1,
            };
        case REMOVE_TURNAROUND_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.type]: state.filter[action.payload.type].filter(({id}) => id !== action.payload.id),
                    airport: null
                },
            };
        case SET_PAGINATE:
            return {...state, paginate: action.payload};
        case CHANGE_FLIGHTS_PAGE:
            return {...state, page: action.payload};
        default:
            return state;
    }
};
