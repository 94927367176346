import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { differenceInMinutes } from 'date-fns';
import { concat, flow, last, map, uniq } from 'lodash';
import flatMap from 'lodash/fp/flatMap';
import sortBy from 'lodash/fp/sortBy';

import { Clock } from 'assets/img/all-icons';
import useNow from 'utils/useNow';
import { getFormattedDuration, defaultFormat, parseTurnType } from 'utils/helpers';
import { getIconByMode, getMode } from 'utils/modes';
import { routes } from 'store/constants/ui.constants';

import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import s from '../FlightsTable.module.scss';

const getStatusCircle = (status) => {
  switch (status) {
    case 'delay':
      return <div className={classnames(s.statusCircle, s.red)} />;
    case 'fixes':
      return <div className={classnames(s.statusCircle, s.orange)} />;
    default:
      return <div className={classnames(s.statusCircle, s.green)} />;
  }
};

const TurnaroundViewRecord = ({ turnaround }) => {
  const now = useNow();

  const [isModeDetailsPopupOpen, setModeDetailsPopupOpen] = useState(false);
  const [modeDetailsCameras, setModeDetailsCameras] = useState([]);
  const [hoveredGate, setHoveredGate] = useState('');

  const openModeDetails = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setModeDetailsPopupOpen(true);
  }, []);

  const closeModeDetails = useCallback(() => {
    setModeDetailsPopupOpen(false);
  }, []);

  const handleGateHover = useCallback(
    (e) => {
      const { type } = e.target.dataset;
      setHoveredGate(type);
      setModeDetailsCameras(turnaround[`${type}_gate`]?.cameras || []);
    },
    [turnaround],
  );

  const handleGateUnhover = useCallback(() => {
    setHoveredGate('');
    setModeDetailsCameras([]);
  }, []);

  const status = useMemo(() => {
    return '';
  }, []);

  const arrivalFlight = useMemo(() => {
    const { airline, departure_flight_number } = turnaround;

    return `${airline} ${departure_flight_number}`;
  }, [turnaround]);

  const departureFlight = useMemo(() => {
    const { airline, arrival_flight_number } = turnaround;

    return `${airline} ${arrival_flight_number}`;
  }, [turnaround]);

  const turnaroundMode = useMemo(() => {
    const statuses = flow([concat, uniq])(
      map(turnaround.incoming_gate?.cameras, 'mode'),
      map(turnaround.outgoing_gate?.cameras, 'mode'),
      map(turnaround.parking_gate?.cameras, 'mode'),
    );

    return getMode(statuses);
  }, [turnaround]);

  // const route = useMemo(() => {
  //   const { current_airport: currentAirport, arrival_airport: arrivalAirport } = turnaround;
  //
  //   return `${currentAirport} - ${arrivalAirport}`;
  // }, [turnaround]);

  const route = useMemo(() => {
    const { current_airport, arrival_airport } = turnaround;

    return `${current_airport} - ${arrival_airport}`;
  }, [turnaround]);

  const turnType = useMemo(() => parseTurnType(turnaround.turn_type, true), [turnaround.turn_type]);

  const groundTime = useMemo(() => {
    const start = turnaround.ata || now;
    const end = turnaround.atd && now > turnaround.atd ? turnaround.atd : now;

    return getFormattedDuration(end - start);
  }, [turnaround, now]);

  const latestEvent = useMemo(() => {
    // const latest = flow([flatMap('classes'), sortBy('actual_ts'), last])(turnaround.processes);
    const latest = flow([flatMap('classes'), sortBy('actual'), last])(turnaround.processes);

    return latest;
  }, [turnaround.processes]);

  const eobt = useMemo(() => {
    const duration = turnaround.std ? <span className={s.blue}>{defaultFormat(turnaround.std)}</span> : '';

    let delay = '';
    const diff = differenceInMinutes(turnaround.atd || now, turnaround.std);

    if (diff > 0) {
      delay = <span style={{ color: 'red', marginLeft: 5 }}>{`(+${diff})`}</span>;
    } else if (!turnaround.atd) {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>(0)</span>;
    } else {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>{`(${diff})`}</span>;
    }

    return (
      <>
        {duration} {delay}
      </>
    );
  }, [now, turnaround.atd, turnaround.std]);

  return (
    <div>
      <Link to={`${routes.TURNAROUND}/${turnaround['_id']}`}>
        <div className={s.row}>
          <div className={classnames(s.td)} style={{ flexBasis: '35px' }}>
            {getStatusCircle(status)}
          </div>
          <div className={classnames(s.td, s.flex)}>{arrivalFlight}</div>
          <div className={classnames(s.td, s.flex)}>{departureFlight}</div>
          <div className={classnames(s.td, s.flex)}>{route}</div>
          <div className={classnames(s.td, s.flex)}>{turnaround.aircraft_type}</div>
          <div className={classnames(s.td, s.flex)}>{turnaround.aircraft_reg_number}</div>
          <div className={classnames(s.td, s.flex)}>{turnType}</div>
          <div className={classnames(s.td, s.flex)}>
            <div className={s.paddingRight}>
              <Clock width="18px" fill="#1f80b4" />
            </div>
            {groundTime}
          </div>
          <div className={classnames(s.td)} style={{ flexBasis: '15%' }}>

            {latestEvent?.name || <span className={s.gray}>No events</span>}
            {/*{latestEvent['event_class_name'] || <span className={s.gray}>No events</span>}*/}
          </div>
          <div className={classnames(s.td, s.flex, s.bold)}>{eobt}</div>
          <div className={classnames(s.td, s.capitalize, s.blue, s.turnaroundMode)} style={{ flexBasis: '30px' }}>
            <div className={s.paddingRight} onClick={openModeDetails}>
              {getIconByMode(turnaroundMode)}
            </div>
            <ClickAwayListener onClick={closeModeDetails}>
              {isModeDetailsPopupOpen && (
                <div className={s.modePopup} style={{ width: 220 }}>
                  <div className={s.mode}>
                    <span className={s.modeLabel}>Mode:</span> {turnaroundMode}
                  </div>
                  <div className={classnames(s.item, s.title)}>Gates:</div>
                  {turnaround.incoming_gate && (
                    <div
                      className={classnames(s.item, s.action)}
                      datatype="incoming"
                      onMouseEnter={handleGateHover}
                      onMouseLeave={handleGateUnhover}
                    >
                      <div>{turnaround.incoming_gate}</div>

                      <div>{getMode(map('active', 'mode'))}</div>
                      {/*<div>{getMode(map(turnaround.incoming_gate.cameras, 'mode'))}</div>*/}
                    </div>
                  )}
                  {turnaround.outgoing_gate && (
                    <div
                      className={classnames(s.item, s.action)}
                      datatype="outgoing"
                      onMouseEnter={handleGateHover}
                      onMouseLeave={handleGateUnhover}
                    >
                      <div>{turnaround.outgoing_gate}</div>
                      {/*<div>{getMode(map(turnaround.outgoing_gate.cameras, 'mode'))}</div>*/}
                      <div>{getMode(map('active', 'mode'))}</div>
                    </div>
                  )}
                  {turnaround.parking_gate && (
                    <div
                      className={classnames(s.item, s.action)}
                      datatype="parking"
                      onMouseEnter={handleGateHover}
                      onMouseLeave={handleGateUnhover}
                    >
                      <div>{turnaround.parking_gate}</div>

                    </div>
                  )}

                  {hoveredGate && (
                    <div className={s.modePopup}>
                      <div className={classnames(s.item, s.title)}>Cameras:</div>
                      {modeDetailsCameras.map((camera) => (
                        <div key={camera.id} className={s.item}>
                          <div>{camera.url}</div>
                          <div>{camera.status}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </ClickAwayListener>
          </div>
        </div>
      </Link>
    </div>
  );
};

TurnaroundViewRecord.propTypes = {
  turnaround: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    arrival_flight_number: PropTypes.number || PropTypes.string,
    departure_flight_number: PropTypes.number || PropTypes.string,
    airline: PropTypes.string,
    arrival_airport: PropTypes.string,
    departure_airport: PropTypes.string,
    current_airport: PropTypes.string,
    turn_type: PropTypes.string,
    std: PropTypes.instanceOf(Date),
    atd: PropTypes.instanceOf(Date),
    sta: PropTypes.instanceOf(Date),
    ata: PropTypes.instanceOf(Date),
    incoming_gate: PropTypes.string,
    outgoing_gate: PropTypes.string,
    parking_gate: PropTypes.string,
    processes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  }).isRequired,
};

export default TurnaroundViewRecord;
