import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from 'components/common/Header/Header';
import SearchApi from 'components/api/searchApi/SearchApi';
import ApiList from 'components/api/apiList/ApiList';
import RequestSetup from 'components/api/requestSetup/RequestSetup';
import RequestPlayground from 'components/api/requestPlayground/RequestPlayground';
import { Preloader } from 'components/common/Preloader/Preloader';

import { fetchAPIInfo } from 'store/controllers/api.controllers';
import s from './Api.module.scss';

const ApiPage = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ api }) => api);

  useEffect(() => {
    dispatch(fetchAPIInfo());
  }, [dispatch]);

  if (isLoading) return <Preloader />;

  return (
    <div className={s.container}>
      <Header />

      <div className={s.content}>
        <div className={s.left}>
          <div className={s.searchBox}>
            <SearchApi />
          </div>
          <div className={s.apiList}>
            <ApiList />
          </div>
        </div>

        <div className={s.center}>
          <RequestSetup />
        </div>
        <div className={s.right}>
          <RequestPlayground />
        </div>
      </div>
    </div>
  );
};

export default ApiPage;
