import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import TableHeader from 'components/ui/TableHeader/TableHeader';
import Header from 'components/common/Header/Header';
import {Clock} from 'assets/img/all-icons';
import Paginator from 'components/common/Paginator/Paginator';

import processActions from 'store/actions/process.actions';
import {defaultFormat} from 'utils/helpers';
import Select from 'react-select';
import TurnaroundViewTable from './flightsTable/turnaroundView/TurnaroundViewTable';
import ProcessFilter from '../ProcessFilter';

import s from './Administration.module.scss';
import GateViewTable from './flightsTable/gateView/GateViewTable';

const viewOptions = [
    {
        value: 'turnaround',
        label: 'Turnaround view',
    },
    {
        value: 'gate',
        label: 'Gate view',
    },
];

const AdminView = () => {
    const dispatch = useDispatch();
    const [clickedFlight, setClickedFlight] = useState(null);
    const [hoveredFlightType, setHoveredFlightType] = useState('');
    const [selectedView, setSelectedView] = useState(
        localStorage.getItem('administration-view')
            ? JSON.parse(localStorage.getItem('administration-view'))
            : viewOptions[0],
    );

    const {processes: flights, total: totalPages, page, perPage, filter} = useSelector(({processes}) => processes);
    const {alerts} = useSelector((state) => state.alerts);

    const changePage = useCallback(
        (p) => {
            dispatch(processActions.changeFlightsPage(p));
        },
        [dispatch],
    );

    const onDatesChange = useCallback(
        (newDates) => {
            dispatch(processActions.changeDates(newDates));
        },
        [dispatch],
    );

    const handleFlightClick = useCallback(
        (turnaround) => {
            if (turnaround.uuid === clickedFlight?.id) {
                setClickedFlight(null);
                setHoveredFlightType('');
            } else {
                setClickedFlight(turnaround);
            }
        },
        [clickedFlight],
    );

    const onFlightMouseEnter = useCallback(
        (id, type) => {
            setHoveredFlightType(clickedFlight?.id === id ? type : '');
        },
        [clickedFlight],
    );

    const onFlightMouseLeave = useCallback(() => {
        setHoveredFlightType('');
    }, []);

    const handleFlightsViewChange = useCallback((view) => {
        setSelectedView(view);
        localStorage.setItem('administration-view', JSON.stringify(view));
    }, []);

    useEffect(() => {
        dispatch(processActions.setPaginate(true));
    }, [dispatch]);

    const detailsWidthStyle = clickedFlight ? 'table-details' : 'd-none';

    return (
        <div className={s.processes}>
            <Header>
                <TableHeader dates={filter.date} onChange={onDatesChange}/>
            </Header>

            <div className={s.topPanel}>
                <ProcessFilter/>
                <div className={s.viewDropdown}>
                    <Select
                        options={viewOptions}
                        value={selectedView}
                        onChange={handleFlightsViewChange}
                        isSearchable={false}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                background: 'transparent',
                                boxShadow: 'none',
                                cursor: 'pointer',
                            }),
                            indicatorSeparator: () => ({
                                display: 'none',
                            }),
                        }}
                    />
                </div>
            </div>

            <div className={s.table}>
                {selectedView.value === 'turnaround' ? (
                    <TurnaroundViewTable
                        items={flights}
                        handleFlightClick={handleFlightClick}
                        handleFlightMouseEnter={onFlightMouseEnter}
                        handleFlightMouseLeave={onFlightMouseLeave}
                    />
                ) : (
                    <GateViewTable
                        items={flights}
                        alerts={alerts}
                        handleFlightClick={handleFlightClick}
                        handleFlightMouseEnter={onFlightMouseEnter}
                        handleFlightMouseLeave={onFlightMouseLeave}
                    />
                )}
                <div className={detailsWidthStyle}>
                    {hoveredFlightType ? (
                        <>
                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">Aircraft type</h2>
                                <ul className="table-more-info__list">{clickedFlight.aircraft_type}</ul>
                            </div>

                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">{hoveredFlightType === 'arrival' ? 'Arrive from' : 'Fly to'}</h2>
                                <ul className="table-more-info__list">
                                    {hoveredFlightType === 'arrival' ? clickedFlight.departure_airport : clickedFlight.arrival_airport}
                                </ul>
                            </div>

                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">Incoming Gate</h2>
                                <ul className="table-more-info__list">{clickedFlight.incoming_gate}</ul>
                            </div>

                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">Outgoing Gate</h2>
                                <ul className="table-more-info__list">{clickedFlight.outgoing_gate}</ul>
                            </div>

                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">Parking Gate</h2>
                                <ul className="table-more-info__list">{clickedFlight.parking_gate}</ul>
                            </div>

                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">{hoveredFlightType === 'arrival' ? 'STA' : 'STD'}</h2>
                                <ul className="table-more-info__list">
                                    <ul className="table-more-info__list">
                                        {hoveredFlightType === 'arrival'
                                            ? defaultFormat(clickedFlight.sta)
                                            : defaultFormat(clickedFlight.std)}
                                    </ul>
                                </ul>
                            </div>

                            <div className="table__more-info table-more-info">
                                <h2 className="table-more-info__title">{hoveredFlightType === 'arrival' ? 'ATA' : 'ATD'}</h2>
                                <ul className="table-more-info__list">
                                    <ul className="table-more-info__list">
                                        {hoveredFlightType === 'arrival'
                                            ? defaultFormat(clickedFlight.ata) || 'Pending'
                                            : defaultFormat(clickedFlight.atd) || 'Pending'}
                                    </ul>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                            {clickedFlight?.processes?.map((process) => (
                                <div key={process.name} className="table__more-info table-more-info">
                                    <h2 className="table-more-info__title">{process.name}</h2>
                                    <ul className="table-more-info__list">
                                        {process.events.map((event) => (
                                            <li key={event.name} className="table-more-info__list-item">
                                                <div className="time">
                                                    <Clock width="36px"/>
                                                    <span>{defaultFormat(event.scheduled_ts)}</span>
                                                </div>
                                                <span>{event.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
            <div className="processes-paginator">
                <Paginator
                    totalPages={totalPages}
                    totalItemsCount={flights.length}
                    pageSize={perPage}
                    currentPage={page}
                    onPageChanged={changePage}
                />
            </div>
        </div>
    );
};

export default AdminView;
