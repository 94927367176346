import React from 'react';
import classnames from 'classnames';
import './MySelect.scss';

const MySelect = ({ id, name, label, options, onChange, value, className }) => {
  return (
    <div className={classnames('select-wrap', className)}>
      {label && <div className="select-wrap__label">{label}</div>}
      <select className="select-wrap__select" id={`input_${id}`} value={value} onChange={onChange} name={name}>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default MySelect;
