import { Clock, Feedback } from 'assets/img/all-icons';
import Modal from 'components/common/Modal/Modal';
import Button from 'components/ui/Button/Button';
import Input from 'components/ui/MyInput/MyInput';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { defaultFormat } from 'utils/helpers';
import config from 'config/config.json';

import s from './TurnaroundEventModal.module.scss';

const autoFeedbackOptions = [
  {value: 'incorrect-event', label: 'Incorrect detection of event'},
  {value: 'incorrent-alert', label: 'Incorrect detection of alert'},
  {value: 'delayed', label: 'Detection was delayed'},
  {value: 'custom', label: 'Your own feedback'},
];
const HUB_FILES_PATH = 'files/turnaround-events';

const TurnaroundEventModal = ({ item, onClose }) => {
  console.log(item, 'ITEM_PROPS_TurnaroundEventModal')
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [customFeedback, setCustomFeedback] = useState('');

  // const url = `http://0.0.0.0:5000/${HUB_FILES_PATH}/${item?.image}`;
  const url = `${config.HUB_CORE_URL}/${HUB_FILES_PATH}/${item?.image}`;

  console.log(url)

  const handleFeedbackTypeSelect = useCallback((type) => {
    setSelectedFeedbackType(type);
  }, []);

  const handleCustomFeedbackChange = useCallback((e) => {
    setCustomFeedback(e.target.value);
  }, []);

  return (
    <Modal isOpen={!!item} onModalClose={onClose} className={s.modal}>
      {item && (
        <div className={s.container}>
          <div className={s.header}>
            <div className={s.tag}>
              <div className={s.label}>Process:</div>
              <div>{item.process}</div>
            </div>

            <div className={s.tag}>
              <div className={s.label}>Event class:</div>
              <div>{item.eventClass}</div>
            </div>

            <div className={s.tag}>
              <div className={s.label}>Event:</div>
              <div>{item.event}</div>
            </div>
          </div>

          <div className={s.card}>
            <div className={s.image}>
              <img src={url} alt={item.event} />
            </div>
            <div className={s.info}>
              <div className={s.infoItem}>
                <div className={s.label}>
                  <Clock width="18px" fill="#1f80b4" />
                  <div className={s.labelText}>Scheduled time</div>
                </div>
                <div>{defaultFormat(item.scheduledTime)}</div>
              </div>
              <div className={s.infoItem}>
                <div className={s.label}>
                  <Clock width="18px" fill="#1f80b4" />
                  <div className={s.labelText}>Actual time</div>
                </div>
                <div>{defaultFormat(item.actualTime)}</div>
              </div>
            </div>
          </div>

          <div className={s.feedbackContainer}>
            <div className={s.heading}>Feedback</div>

            <div className={s.autoFeedback}>
              <Select
                options={autoFeedbackOptions}
                value={selectedFeedbackType}
                onChange={handleFeedbackTypeSelect}
                isSearchable={false}
                menuPlacement="top"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    background: 'transparent',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                }}
              />
            </div>
            {selectedFeedbackType?.value === 'custom' && (
              <div className={s.customFeedback}>
                <Input
                  id="feedback"
                  placeholder="Input your feedback here"
                  value={customFeedback}
                  onChange={handleCustomFeedbackChange}
                />
              </div>
            )}

            <Button className={s.feedbackButton} icon={<Feedback width="20px" height="20px" />}>
              Send feedback
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TurnaroundEventModal;
