import React from 'react';

import DropdownLeft from 'components/common/DropdownLeft/DropdownLeft';
import decideMethodColor from 'utils/decideMethodColor';

import s from './Requests.module.scss';

const noop = () => {};

const Requests = ({ entries, isOpen, title, onRequestClick }) => {
  return (
    <DropdownLeft
      title={<div className={s.apiCategory}>{title}</div>}
      isOpen={isOpen}
    >
      <div className={s.container}>
        {entries.map((entry) => (
          <div
            key={entry.name}
            role="button"
            className={s.request}
            onClick={() => onRequestClick(entry.id)}
            tabIndex={0}
            onKeyPress={noop}
          >
            <span
              style={{ color: decideMethodColor(entry.method) }}
              className={s.requestType}
            >
              {entry.method}
            </span>
            <span className={s.requestName}>{entry.name}</span>
          </div>
        ))}
      </div>
    </DropdownLeft>
  );
};

export default Requests;
