export const ESTABLISH_CONNECTION_ERROR = 'ESTABLISH_CONNECTION_ERROR';
export const ESTABLISH_CONNECTION_PROCESSES = 'ESTABLISH_CONNECTION_ERROR';
export const ESTABLISH_CONNECTION_SUCCESS = 'ESTABLISH_CONNECTION_SUCCESS';
export const CLOSE_CONNECTION_SUCCESS = 'CLOSE_CONNECTION_SUCCESS';

export const FETCH_PROCESSES_REQUEST = 'FETCH_PROCESSES_REQUEST';
export const FETCH_PROCESSES_ERROR = 'FETCH_PROCESSES_ERROR';
export const FETCH_PROCESSES_SUCCESS = 'FETCH_PROCESSES_SUCCESS';
export const UPDATE_PROCESSES = 'UPDATE_PROCESSES';

export const CHANGE_DATES = 'CHANGE_DATES';
export const UPDATE_TURNAROUND_FILTERS = 'UPDATE_TURNAROUND_FILTERS';
export const REMOVE_TURNAROUND_FILTER = 'REMOVE_TURNAROUND_FILTER';

export const PAGE_SIZE = 8;
export const SET_PAGINATE = 'SET_PAGINATE';
export const CHANGE_FLIGHTS_PAGE = 'FLIGHTS.CHANGE_PAGE';



export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE'


export const CHECK_IS_CONNECTION = 'CHECK_IS_CONNECTION'


export const UPDATE_MAIN_DATA = 'UPDATE_MAIN_DATA'
export const UPDATE_TURN_DATA = 'UPDATE_TURN_DATA'
export const CLOSE_TEST_CONNECTION = 'CLOSE_TEST_CONNECTION'

