import {useMemo} from 'react';
import PropTypes from 'prop-types';
import './FlightDetails.scss';
import { differenceInMinutes } from 'date-fns';
import { defaultFormat, parseTurnType } from 'utils/helpers';
import useNow from 'utils/useNow';
import CriticalPath from '../criticalPath/CriticalPath';
import ButtonsViewAndClose from "./ButtonsViewAndClose";
import {useSelector} from "react-redux";


const FlightDetails = ({onDetailClose, turnaround, handleGoToTurn, check}) => {
  const {isCriticalPath, turnaround: {processes}, isCriticalLoading} = useSelector(state => state['turnaround'])

  const {
    _id,
    airline,
    arrival_airport,
    departure_airport,
    current_airport,
    aircraft_type,
    departure_flight_number,
    arrival_flight_number,
    turn_type,
    std,
    sta,
    parking_gate,
    aircraft_reg_number,
    rst,
    ret
  } = turnaround;
  const now = useNow();

  const handleShowTurn = () => {
    handleGoToTurn()
    onDetailClose()
  }

  const parsedTurnType = useMemo(() => {
    if (!turn_type) return '';

    return parseTurnType(turn_type);
  }, [turn_type]);


  const processesToDisplayAsCritical = useMemo(() => processes?.filter(({is_critical}) => is_critical), [processes]);

  const eobt = useMemo(() => {
    // const duration = std ? <span style={{ color: '#1f80b4' }}>{defaultFormat(std)}</span> : '';
    const duration = ret ? <span style={{ color: '#1f80b4' }}>{defaultFormat(ret)}</span> : '';

    let delay = '';
    const diff = differenceInMinutes(ret || now, std);

    if (diff > 0) {
      delay = <span style={{ color: 'red', marginLeft: 5 }}>{`(+${diff})`}</span>;
    } else if (!ret) {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>(0)</span>;
    } else {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>{`(${diff})`}</span>;
    }

    return (
      <>
        {duration} {delay}
      </>
    );
  }, [now, ret, std]);

  const eibt = useMemo(() => {
    // const duration = sta ? <span style={{ color: '#180b4' }}>{defaultFormat(sta)}</span> : '';
    const duration = rst ? <span style={{ color: '#180b4' }}>{defaultFormat(rst)}</span> : '';

    let delay = '';
    const diff = differenceInMinutes(rst || now, sta);

    if (diff > 0) {
      delay = <span style={{ color: 'red', marginLeft: 5 }}>{`(+${diff})`}</span>;
    } else if (!rst) {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>(0)</span>;
    } else {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>{`(${diff})`}</span>;
    }

    return (
      <>
        {duration} {delay}
      </>
    );
  }, [now, rst, sta]);

  return (
    <div className="airplane-detail" ref={check}>
      <div className="main airplane">
        <div className="table-content">
          <div className="airplane-detail-header ">
            {isCriticalPath
                ? (
              <div className="airplane-detail-header-content">
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Airline</span>
                  <span className="airplane-detail-header__text">{airline}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Turn type</span>
                  <span className="airplane-detail-header__text">{parsedTurnType}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Aircraft Type</span>
                  <span className="airplane-detail-header__text">{aircraft_type}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Tail Number</span>
                  <span className="airplane-detail-header__text">{aircraft_reg_number}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Gate</span>
                  <span className="airplane-detail-header__text">{parking_gate}</span>
                </div>

                {!isCriticalPath
                    && (
                  <>
                    <div className="airplane-detail-header__column">
                      <span className="airplane-detail-header__title">EOBT</span>
                      <span className="airplane-detail-header__text">{eobt}</span>
                    </div>
                  </>
                )}

                {isCriticalPath
                    && (
                  <>
                    <div className="airplane-detail-header__column">
                      <span className="airplane-detail-header__title">Departure</span>
                      <span className="airplane-detail-header__text">{departure_flight_number}</span>
                    </div>
                    <div className="airplane-detail-header__column">
                      <span className="airplane-detail-header__title">Route</span>
                      <span className="airplane-detail-header__text">{`${departure_airport} - ${current_airport} - ${arrival_airport}`}</span>
                    </div>
                    <div className="airplane-detail-header__column">
                      <span className="airplane-detail-header__title">EOBT</span>
                      <span className="airplane-detail-header__text">{eobt}</span>
                    </div>

                    <div className="separator" />

                    <div className="airplane-detail-header__column">
                      <span className="airplane-detail-header__title">Arrival</span>
                      <span className="airplane-detail-header__text">{arrival_flight_number}</span>
                    </div>
                    <div className="airplane-detail-header__column">
                      <span className="airplane-detail-header__title">EIBT</span>
                      <span className="airplane-detail-header__text">{eibt}</span>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="airplane-detail-header-content">
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Arrival Flight</span>
                  <span className="airplane-detail-header__text">{`${airline} ${departure_flight_number}`}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Departure Flight</span>
                  <span className="airplane-detail-header__text">{`${airline} ${arrival_flight_number}`}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Turn type</span>
                  <span className="airplane-detail-header__text">{parsedTurnType}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Aircraft Type</span>
                  <span className="airplane-detail-header__text">{aircraft_type}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Tail Number</span>
                  <span className="airplane-detail-header__text">{aircraft_reg_number}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Route</span>
                  <span className="airplane-detail-header__text">{`${departure_airport} - ${current_airport} - ${arrival_airport}`}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">EOBT</span>
                  <span className="airplane-detail-header__text">{eobt}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">EIBT</span>
                  <span className="airplane-detail-header__text">{eibt}</span>
                </div>
                <div className="airplane-detail-header__column">
                  <span className="airplane-detail-header__title">Gate</span>
                  <span className="airplane-detail-header__text">{parking_gate}</span>
                </div>
              </div>
            )}
            <div>{isCriticalPath
                && <ButtonsViewAndClose onClose={onDetailClose} handleShowTurn={handleShowTurn}/>}</div>
          </div>
          {isCriticalPath
              && (
            <div className="airplane-detail-content">
              {isCriticalLoading && <div style={{height: '70px', width: '100%', textAlign: 'center'}}>loading.....</div>}
              {!isCriticalLoading && <CriticalPath processes={processesToDisplayAsCritical}/>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FlightDetails.propTypes = {
  turnaround: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    airline: PropTypes.string.isRequired,
    aircraft_reg_number: PropTypes.string.isRequired,
    aircraft_type: PropTypes.string || PropTypes.number,
    departure_airport: PropTypes.string.isRequired,
    current_airport: PropTypes.string.isRequired,
    arrival_airport: PropTypes.string.isRequired,
    departure_flight_number: PropTypes.number || PropTypes.string,
    arrival_flight_number: PropTypes.number || PropTypes.string,
    turn_type: PropTypes.string.isRequired,
    std: PropTypes.instanceOf(Date),
    ret: PropTypes.instanceOf(Date),
    sta: PropTypes.instanceOf(Date),
    rst: PropTypes.instanceOf(Date),
    incoming_gate: PropTypes.string,
    outgoing_gate: PropTypes.string,
    parking_gate: PropTypes.string,
    processes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        sst: PropTypes.instanceOf(Date),
        ast: PropTypes.instanceOf(Date),
        sft: PropTypes.instanceOf(Date),
        aft: PropTypes.instanceOf(Date),
        events: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
};

export default FlightDetails;
