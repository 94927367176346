/* eslint-disable import/no-anonymous-default-export */
import {
  AIRPORT_SELECT,
  AIRPORT_UPDATE,
  FETCH_AIRPORT_SUCCESS,
  FETCH_AIRPORT_LOADING,
  CREATE_TERMINAL,
  UPDATE_TERMINAL,
  DELETE_TERMINAL,
  CREATE_GATE,
  UPDATE_GATE,
  DELETE_GATE,
} from 'store/constants/airport.constants';

export default {
  selectAirport(airport = {}) {
    return { type: AIRPORT_SELECT, payload: airport };
  },
  updateAirport(updates) {
    return { type: AIRPORT_UPDATE, payload: updates };
  },
  getAirportSuccess(airport) {
    return { type: FETCH_AIRPORT_SUCCESS, payload: airport };
  },
  getAirportLoading(isLoading) {
    return { type: FETCH_AIRPORT_LOADING, payload: isLoading };
  },
  createTerminal(terminal) {
    return { type: CREATE_TERMINAL, payload: terminal };
  },
  updateTerminal(terminal) {
    return { type: UPDATE_TERMINAL, payload: terminal };
  },
  deleteTerminal(terminalId) {
    return { type: DELETE_TERMINAL, payload: terminalId };
  },
  createGate(gate) {
    return { type: CREATE_GATE, payload: gate };
  },
  updateGate(gate) {
    return { type: UPDATE_GATE, payload: gate };
  },
  deleteGate(gate) {
    return { type: DELETE_GATE, payload: gate };
  },
};
