import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Input from 'components/ui/MyInput/MyInput';
import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';

import useDebounce from 'utils/useDebounce';
import {createGetAirportsHandler} from 'store/handlers';

import {fetchAirlines} from 'store/controllers/airline.controllers';
import Button from '../Button/Button';
import s from './AirportFilter.module.scss';

const fetchAirports = createGetAirportsHandler();

const AirportFilter = ({title, type, isOpen, onClick, query, onQueryChange, closeFilter, selectedItem, onAirportClick, position}) => {
    const [items, setItems] = useState([]);
    const [areAirportsLoading, setAirportsLoading] = useState(false);

    const debouncedFilterQuery = useDebounce(query, 500);

    const queryFunc = useMemo(() => (type === 'airport' ? fetchAirports : fetchAirlines), [type]);

    useEffect(() => {
        setAirportsLoading(true);
        queryFunc(debouncedFilterQuery || 50)
            .then((data) => {
                setItems(data);
                setAirportsLoading(false);
            })
            .catch(console.error);
    }, [debouncedFilterQuery, queryFunc]);

    const positionStyle = useMemo(() => {
        switch (position) {
            case 'left':
                return {right: 0};
            case 'right':
                return {left: 0};
            default:
                return {};
        }
    }, [position]);

    const key = useMemo(() => (type === 'airport' ? 'iata' : 'icao'), [type]);

    return (
        <ClickAwayListener onClick={closeFilter}>
            <div className={s.root}>
                <Button type="secondary" onClick={onClick}>
                    {title}
                </Button>
                {isOpen && (
                    <div className={s.listContainer} style={positionStyle}>
                        <div className={s.searchInput}>
                            <Input id="filter" value={query} onChange={onQueryChange} placeholder="Search..."/>
                        </div>
                        <div className={s.itemsList}>
                            {areAirportsLoading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                items.map((item) => (
                                    <div
                                        key={item[key]}
                                        onClick={() => onAirportClick(item)}
                                        tabIndex="0"
                                        role="button"
                                        onKeyPress={() => {
                                        }}
                                        className={classnames(s.item, {
                                            [s.item_active]: item[key] === selectedItem?.[key],
                                        })}
                                    >
                                        <span
                                            className={s.itemLabel}>{`${item.name} ${type === 'airport' ? 'Airport' : 'Airline'}`}</span>
                                        <span className={s.itemCountry}>{item[key]}</span>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

AirportFilter.propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['airport', 'airline']),
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    closeFilter: PropTypes.func.isRequired,
    selectedItem: PropTypes.shape({
        iata: PropTypes.string,
        icao: PropTypes.string,
    }),
    onAirportClick: PropTypes.func.isRequired,
    position: PropTypes.string,
};

AirportFilter.defaultProps = {
    title: 'Airport Filter',
    type: 'airport',
    position: 'left',
    selectedItem: {},
};

export default AirportFilter;
