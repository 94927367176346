import { getHours, getMinutes, getSeconds, format, subMinutes, isValid, addMilliseconds } from 'date-fns';
import { capitalize, isFinite, last, range } from 'lodash';

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

export const transToMilliseconds = date => new Date(date).getTime()

export const calculateTimePercent = (time) => {
  const timeInMinutes = getHours(time) * MINUTES_IN_HOUR + getMinutes(time);
  return (timeInMinutes / MINUTES_IN_DAY) * 100;
};

export const calculateDurationRatio = (begin, end, time) => {
  const range = new Date(begin) - new Date(end);
  const ellapsed = new Date(time) - new Date(begin);
  return ellapsed / range;
};

export const splitDateRangeIntoEqualParts = (end, start, numberOfParts) => {
  const r = end - start;
  const interval = r / numberOfParts;

  return range(numberOfParts).reduce(
    (acc) => {
      acc.push(addMilliseconds(last(acc), interval));
      return acc;
    },
    [start],
  );
};

export const getUTCDate = (localDate) => {
  if (!localDate && !isValid(new Date(localDate))) return null;

  const date = new Date(localDate);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const getLocalDateFromUTC = (date) => isValid(new Date(date)) && subMinutes(new Date(date), new Date().getTimezoneOffset());

export const getFormattedDuration = (duration) => {
  if (!isFinite(duration) && !isValid(new Date(duration))) return '';

  if (duration === 0) {
    return '00:00:00';
  }

  const helperDate = getUTCDate(duration);
  const hours = getHours(helperDate);
  const minutes = getMinutes(helperDate);
  const seconds = getSeconds(helperDate);
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const getFormattedTime = (time) => {
  if (!isFinite(time) || !isValid(new Date(time))) return '';

  if (time === 0) return `0m`;

  const helperDate = getUTCDate(time);
  const hours = getHours(helperDate);
  const minutes = getMinutes(helperDate);
  const formattedHours = hours > 0 ? `${hours}h` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}m` : '';

  return `${formattedHours} ${formattedMinutes}`;
};

export const defaultFormat = (time) => {
  if (!time) return '';
  return format(new Date(time), 'HH:mm');
};

export const parseTurnType = (turnType, isFull = false) => {
  if (!turnType) return '';

  const [first, second] = turnType.split(' ');

  if (!isFull) {
    return `${capitalize(first)}-${second[0].toUpperCase()}`;
  }

  return `${capitalize(first)} ${capitalize(second)}`;
};
