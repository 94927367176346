import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Input from 'components/ui/MyInput/MyInput';
import decideMethodColor from 'utils/decideMethodColor';
import Button from 'components/ui/Button/Button';
import { CopyIcon } from 'assets/img/all-icons';

import DropdownLeft from 'components/common/DropdownLeft/DropdownLeft';
import s from './RequestSetup.module.scss';
import RequestParameter from './requestParameter/RequestParameter';

const copyNotify = () => toast.info('API Key is copied to the clipboard');

const RequestSetup = () => {
  const [apiKey, setApiKey] = useState(
    'be0d130f7fmsh864456459857278p1c907cjsnd0cb8bd6a714',
  );
  const { selectedRequest } = useSelector(({ api }) => api);

  const onGenerateClick = useCallback(() => {
    setApiKey(
      [...Array(30)]
        .map(() => Math.random().toString(36)[2])
        .join('')
        .repeat(4),
    );
    toast.success('API Key was successfully updated');
  }, []);

  return (
    <div>
      <div className={s.requestInfo}>
        <div>
          <span
            style={{ color: decideMethodColor(selectedRequest.method) }}
            className={s.requestType}
          >
            {selectedRequest.method}
          </span>
          <span className={s.endpoint}>{selectedRequest.endpoint}</span>
        </div>

        {selectedRequest.name && <Button>Test Endpoint</Button>}
      </div>

      <div className={s.apiKeyContainer}>
        <div className={s.apiKey}>
          <Input label="API Key" value={apiKey} readOnly />
          <CopyToClipboard onCopy={copyNotify} text={apiKey}>
            <CopyIcon width="18px" className={s.copyIcon} />
          </CopyToClipboard>
        </div>
        <Button type="secondary-light" onClick={onGenerateClick}>
          Generate
        </Button>
      </div>

      {selectedRequest.headers?.length > 0 && (
        <div className={s.parametersWrap}>
          <DropdownLeft
            isOpen
            title={<b>Header Parameters</b>}
            styles={{ header: s.parametersHeader }}
          >
            <div className={s.parametersContainer}>
              {selectedRequest.headers?.map((param) => (
                <div key={param.name} className={s.parameter}>
                  <RequestParameter
                    name={param.name}
                    type={param.type}
                    isRequired={param.required}
                    description={param.description}
                  />
                </div>
              ))}
            </div>
          </DropdownLeft>
        </div>
      )}

      {selectedRequest.required?.length > 0 && (
        <div className={s.parametersWrap}>
          <DropdownLeft
            isOpen
            title={<b>Required Parameters</b>}
            styles={{ header: s.parametersHeader }}
          >
            <div className={s.parametersContainer}>
              {selectedRequest.required.map((param) => (
                <div key={param.name} className={s.parameter}>
                  <RequestParameter
                    name={param.name}
                    type={param.type}
                    isRequired
                    description={param.description}
                  />
                </div>
              ))}
            </div>
          </DropdownLeft>
        </div>
      )}

      {selectedRequest.optional?.length > 0 && (
        <div className={s.parametersWrap}>
          <DropdownLeft
            isOpen
            title={<b>Optional Parameters</b>}
            styles={{ header: s.parametersHeader }}
          >
            <div className={s.parametersContainer}>
              {selectedRequest.optional.map((param) => (
                <div key={param.name} className={s.parameter}>
                  <RequestParameter
                    name={param.name}
                    type={param.type}
                    description={param.description}
                    value={param.default}
                  />
                </div>
              ))}
            </div>
          </DropdownLeft>
        </div>
      )}
    </div>
  );
};

export default RequestSetup;
