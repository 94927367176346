import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Input from 'components/ui/MyInput/MyInput';
import Button from 'components/ui/Button/Button';

import {createEvent, fetchEvents, deleteEvent} from 'store/controllers/services.controllers';

import Confirmation from 'components/common/Confirmation/Confirmation';
import s from './EventList.module.scss';

const EventList = () => {
    const dispatch = useDispatch();

    const [newEvent, setNewEvent] = useState({name: ''});
    const [eventToDelete, setEventToDelete] = useState(null);

    const {events} = useSelector((state) => state.services);

    useEffect(() => {
        dispatch(fetchEvents());
    }, [dispatch]);

    const onDeleteClick = useCallback((eventId) => {
        setEventToDelete(eventId);
    }, []);

    const handleEventDelete = useCallback(() => {
        dispatch(deleteEvent(eventToDelete));
        setEventToDelete(null);
    }, [dispatch, eventToDelete]);

    const onDeleteCancel = useCallback(() => {
        setEventToDelete(false);
    }, []);

    const handleNewEventNameChange = useCallback((e) => {
        setNewEvent({name: e.target.value});
    }, []);

    const handleEventCreate = useCallback(() => {
        dispatch(createEvent(newEvent));
        setNewEvent({name: ''});
    }, [dispatch, newEvent]);

    return (
        <div>
            <div className={s.newProcessContainer}>
                <h6>Create new event</h6>
                <div className={s.newProcessInner}>
                    <div className={s.newProcessInput}>
                        <Input id="new-process-name" value={newEvent.name} onChange={handleNewEventNameChange}/>
                    </div>
                    <Button onClick={handleEventCreate}>Create</Button>
                </div>
            </div>

            <div className={s.list}>
                {events.map((event) => (
                    <div key={event.id} className={s.container}>
                        <div className={s.header}>
                            <div className={s.title} style={{fontSize: 18}}>
                                {event.name}
                            </div>
                            <Button type="secondary-light" onClick={() => onDeleteClick(event.id)}>
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <Confirmation
                question="Do you want to delete an event?"
                isOpen={!!eventToDelete}
                onConfirm={handleEventDelete}
                onCancel={onDeleteCancel}
            />
        </div>
    );
};

export default EventList;
