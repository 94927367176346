import { IntellActAPI } from './api';

export default {
  getGatesByAirport(airportIATA) {
    const payload = IntellActAPI.get(`/airport/${airportIATA}/gates`);
    return payload
  },
  addGate(gate, airportIATA) {
    const payload = IntellActAPI.post(`/airport/${airportIATA}/gates/`, gate);
    return payload
  },
  updateGate(gateId, gate, airportIATA) {
    console.log(gateId, '-------gateId---------', gate, '----------GATE--------', airportIATA, '------------airportIATA-------------' )
    return IntellActAPI.put(`/airport/${airportIATA}/gates/${gateId}/`, gate);
  },
  deleteGate(gateId, airportIATA) {
    return IntellActAPI.delete(`/airport/${airportIATA}/gates/${gateId}/`);
  },
};
