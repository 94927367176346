import {
  FETCH_API_LOADING,
  FETCH_API_SUCCESS,
  CHANGE_API_QUERY,
  SELECT_API_REQUEST,
} from 'store/constants/api.constants';

const initialState = {
  isLoading: false,
  query: '',
  selectedRequest: {},
  api: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_API_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case FETCH_API_SUCCESS:
      return {
        ...state,
        api: action.payload,
      };

    case CHANGE_API_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case SELECT_API_REQUEST:
      return {
        ...state,
        selectedRequest: state.api
          .flatMap((category) => category.entries)
          .find((request) => request.id === action.payload),
      };

    default:
      return state;
  }
};
