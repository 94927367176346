import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uiActions from 'store/actions/ui.actions';
import { fetchAccounts } from 'store/controllers/account.controllers';
import { routes } from 'store/constants/ui.constants';
import Paginator from 'components/common/Paginator/Paginator';
import Header from 'components/common/Header/Header';
import { Preloader } from 'components/common/Preloader/Preloader';
import AccountsList from './AccountsList';
import './Accounts.scss';
import AccountsFilter from './AccountsFilter';

const Accounts = () => {
  const dispatch = useDispatch();

  const { accounts, isFetching, perPage, currentPage, total, filter } = useSelector(
    ({ accounts: accountsState }) => accountsState,
  );

  useEffect(() => {
    dispatch(fetchAccounts(currentPage, perPage, filter));
  }, [perPage, currentPage, dispatch, filter]);

  useEffect(() => {
    dispatch(uiActions.changePage(routes.ACCOUNTS));
    return () => dispatch(uiActions.changePage(''));
  }, [dispatch]);

  const onPageChanged = useCallback(
    (pageNumber) => {
      dispatch(fetchAccounts(pageNumber, perPage));
    },
    [dispatch, perPage],
  );

  return (
    <div className="accounts-container">
      <div className="accounts">
        <Header>
          <AccountsFilter />
        </Header>
        {isFetching ? <Preloader /> : <AccountsList data={accounts} />}
      </div>
      <div className="accounts-paginator">
        <Paginator
          totalPages={total}
          totalItemsCount={accounts.length}
          pageSize={perPage}
          currentPage={currentPage}
          onPageChanged={onPageChanged}
        />
      </div>
    </div>
  );
};

export default Accounts;
