import React from 'react';

const AccordeonInnerItemContent = ({ url, position, view, status }) => {
    return (
        <div className="d-flex">
            <div className="col p-0" />
            <div className="col-3 col_border">
                <div className="col__text">
                    {url.match(/([0-9]{1,3}(\.|:)+)+:?\d+/g)?.join('') || url}
                </div>
            </div>
            <div className="col-3 col_border">
                <div className="col__text">{`${position} | ${view}`}</div>
            </div>
            <div className="col-3 col_border">
                <div className="col__text event">{status}</div>
            </div>
        </div>
    );
};

export default AccordeonInnerItemContent;
