import { toast } from 'react-toastify';
import { gateAPI } from 'services/api';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

export const fetchGatesByAirport = async (airportIATA) => {
  try {
    const response = await gateAPI.getGatesByAirport(airportIATA);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while fetching gates: ${errorMessage}`);
  }
  return null;
};
