import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import Header from 'components/common/Header/Header';
import AirportFilter from 'components/ui/AirportFilter/AirportFilter';

import { fetchStandards } from 'store/controllers/standards.controllers';
import StandardsList from 'components/standards/StandardsList';
import s from './Standards.module.scss';

const Standards = () => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [airportSearchQuery, setAirportSearchQuery] = useState('');
  const [selectedAirline, setSelectedAirline] = useState(null);
  const [airlineStandards, setAirlineStandards] = useState([]);

  const onSearchAirlineClick = useCallback(() => {
    setSearchOpen((prevState) => !prevState);
  }, []);

  const closeSearch = useCallback(() => {
    setSearchOpen(false);
  }, []);

  const handleAirlineSelect = useCallback((airline) => {
    setSelectedAirline(airline);
  }, []);

  const onSearchQueryChange = useCallback((e) => {
    if (isEmpty(e.target.value)) setSelectedAirline(null);
    setAirportSearchQuery(e.target.value);
  }, []);

  const handleStandardCreated = useCallback(() => {
    setLoading(true);
    fetchStandards(selectedAirline.icao).then((data) => {
      setLoading(false);
      setAirlineStandards(data);
    });
  }, [selectedAirline]);

  useEffect(() => {
    if (selectedAirline) {
      setLoading(true);
      fetchStandards(selectedAirline.icao).then((data) => {
        setLoading(false);
        setAirlineStandards(data);
      });
    }
  }, [dispatch, selectedAirline]);

  return (
    <div>
      <Header>
        <h2 className={s.header}>Airline Standards</h2>
        <AirportFilter
          title="Search airline"
          isOpen={isSearchOpen}
          type="airline"
          onClick={onSearchAirlineClick}
          closeFilter={closeSearch}
          query={airportSearchQuery}
          onQueryChange={onSearchQueryChange}
          onAirportClick={handleAirlineSelect}
          selectedItem={selectedAirline}
        />
      </Header>
      <div className={s.container}>
        {selectedAirline ? (
          <>
            <div>
              <span>Airline:</span>
              <h5>{selectedAirline.name}</h5>
              <hr />
            </div>

            <StandardsList
              isLoading={isLoading}
              standards={airlineStandards}
              airlineICAO={selectedAirline.icao}
              onStandardCreated={handleStandardCreated}
            />
          </>
        ) : (
          <div className={s.noAirline}>
            <h4>No airline selected</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default Standards;
