import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button/Button';
import Modal from '../Modal/Modal';

import s from './Confirmation.module.scss';

const Confirmation = ({ question, isOpen, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} onModalClose={onCancel} className={s.container}>
      <h5>{question}</h5>
      <div className={s.buttonsGroup}>
        <Button onClick={onConfirm} className={s.confirm}>
          Confirm
        </Button>
        <Button type="secondary-light" onClick={onCancel} className={s.confirm}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

Confirmation.propTypes = {
  question: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Confirmation;
