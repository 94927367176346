import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {LoginIcon, Logo} from 'assets/img/all-icons';
import {routes} from 'store/constants/ui.constants';
import {login} from 'store/controllers/user.controllers';

import './Login.scss';

const renderField = ({label, input, meta: {touched, error}, ...custom}) => (
    <input
        className="login-content-form-input"
        label={label}
        placeholder={label}
        {...input}
        {...custom}
    />
);

const validate = (values) => {
    const errors = {};
    const requiredFields = ['login', 'password'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const onSubmitSuccess = (dispatch) => {
    console.log('_________SUCCESS LOGIN FORM SUBMIT_________');
};

const onSubmitFail = (errors) => {
    console.log('_________FAILED LOGIN FORM SUBMIT_________');
};

const LoginForm = ({handleSubmit, submitting, error}) => {
    return (
        <form onSubmit={handleSubmit} className="login-content-form">
            <Field
                type="text" component={renderField} required id="email"
                label="Email or username" name="login" autoComplete="email" autoFocus
            />
            <Field
                type="password" component={renderField} required
                id="password" label="Password" name="password" autoComplete="email"
            />
            {error && <span className="login-content-form-error">{error}</span>}
            <button disabled={submitting} id="loginButton"
                    type="submit" className="login-content-form-button pointer hover-blue-button"
            >
                <LoginIcon width="19px"/>
                <span className="login-content-form-button__text ">Login now</span>
            </button>
            <div className="login-content-form-forgot pointer">
                <span>Forgot Your Password?</span>
            </div>
        </form>
    );
};

const LoginReduxForm = reduxForm({
    form: 'loginForm',
    validate,
    onSubmitSuccess,
    onSubmitFail,
})(LoginForm);

const Login = ({login, isAuthorized}) => {
    const onSubmit = (loginData) => {
        return login(loginData.login, loginData.password);
    };

    if (isAuthorized) {
        return <Redirect to={routes.TOGGLE_PATH}/>;
    }

    return (
        <div className="login__bg login-box__position">
            <div className="login">
                <div className="login-logo-box">
                    <Logo width="64px"/>
                </div>
                <div className="login-content">
                    <p className="login-content-heading">Sign in to continue</p>
                    <LoginReduxForm onSubmit={onSubmit}/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthorized: state.user.isAuthorized,
});

export default connect(mapStateToProps, {login})(Login);
