import React, {useState, useCallback, useEffect} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import { UserPlus, BentoMenu, AdminCog, Monitoring } from 'assets/img/all-icons';

import { routes, processViews } from 'store/constants/ui.constants';
import { logout } from 'store/controllers/user.controllers';
import uiActions from 'store/actions/ui.actions';
import Can from 'services/Can';
import { VIEW, ADD } from 'services/ability';

import SidebarItem from './SidebarItem';

import './Sidebar.scss';
import {useLocation} from "react-router";

const items = [
  {
    label: 'Turnaround',
    path: routes.TURNAROUND,
    icon: 'repeat',
    key: 'turnaround',
  },
  {
    label: 'Airport Settings',
    path: routes.AIRPORT_SETTINGS,
    icon: 'settings',
    key: 'settings',
  },
  {
    label: 'Cameras',
    path: routes.CAMERAS,
    icon: 'camera',
    key: 'cameras',
  },
  {
    label: 'Accounts',
    path: routes.ACCOUNTS,
    icon: 'users',
    key: 'accounts',
  },
];

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation().pathname;

  const [isUserPopupOpen, setUserPopupOpen] = useState(false);
  const [isMenuPopupOpen, setMenuPopupOpen] = useState(false);

  const user = useSelector(({ user: u }) => u);
  const view = useSelector(({ ui }) => ui.processView);
  const currentPage = useSelector(({ ui }) => ui.page);
  const {filter: {date}} = useSelector(state => state.processes)

  const [name, surname] = user.name.split(' ');
  const initials = name[0] + (surname?.[0] || '');

  useEffect(() => {
    localStorage.setItem('path_setting', JSON.stringify({location, date}))
  }, [location, date]);

  useEffect(() => {
    return () => {
      console.log('return from Sidebar component')
      setUserPopupOpen(false)
      setMenuPopupOpen(false)
    }
  }, [])

  const toggleUserPopup = useCallback(() => {
    setUserPopupOpen((prevState) => !prevState);
  }, []);

  const closeUserPopup = useCallback(() => {
    setUserPopupOpen(false);
  }, []);

  const toggleMenuPopup = useCallback(() => {
    setMenuPopupOpen((prevState) => !prevState);
  }, []);

  const closeMenuPopup = useCallback(() => {
    setMenuPopupOpen(false);
  }, []);

  return (
    <aside className="sidebar">
      <ClickAwayListener onClick={closeMenuPopup}>
        <div className="bento-menu-container">
          <BentoMenu width="36px" onClick={toggleMenuPopup} />
          {isMenuPopupOpen && (
            <div className="popup menu-popup">
              <button
                type="button"
                onClick={() => {
                  dispatch(uiActions.changeProcessView(processViews.ADMIN));
                  closeMenuPopup();
                }}
                className={classNames('item action', {
                  active: view === processViews.ADMIN,
                })}
              >
                <AdminCog width="20px" className="icon" />
                Administration
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(uiActions.changeProcessView(processViews.MONITORING));
                  closeMenuPopup();
                }}
                className={classNames('item action', {
                  active: view === processViews.MONITORING,
                })}
              >
                <Monitoring width="20px" className="icon" />
                Monitoring
              </button>
            </div>
          )}
        </div>
      </ClickAwayListener>

      <div className="sidebar-menu">
        <ul className="sidebar-list">
          {items.map((item) => (
            <Can key={item.path} I={VIEW} a={item.path}>
              <SidebarItem active={currentPage === item.path} {...item}/>
            </Can>
          ))}
        </ul>
      </div>

      <Can I={ADD} an={routes.ACCOUNTS}>
        {() => (
          <Link to={routes.ADD_ACCOUNT}>
            <div className="sidebar-add-account">
              <UserPlus className="icon" width="20px" height="16px" />
            </div>
          </Link>
        )}
      </Can>

      <ClickAwayListener onClick={closeUserPopup}>
        <div className="sidebar-user-box" onClick={toggleUserPopup}>
          <div className="sidebar-user-box-img__border">
            {user.photo ? <img src={user.photo} alt="" /> : initials.toUpperCase()}
          </div>
          {isUserPopupOpen && (
            <div className="popup">
              <Link to={routes.USER_PROFILE}>
                <div className="item action">{user.name}</div>
              </Link>
              <Can I={VIEW} a={routes.TURNAROUND_SETTINGS}>
                <Link to={routes.TURNAROUND_SETTINGS}>
                  <div className="item action">Settings</div>
                </Link>
              </Can>
              <Can I={VIEW} a={routes.STANDARDS}>
                <Link to={routes.STANDARDS}>
                  <div className="item action">Standards</div>
                </Link>
              </Can>
              <div
                tabIndex="0"
                role="button"
                className="item action"
                onClick={() => dispatch(logout())}
                onKeyPress={() => {}}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </aside>
  );
};

export default Sidebar;
