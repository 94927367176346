import React from 'react';
import ReactDOM from 'react-dom';
import IntellActApp from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./store/store";
import {BrowserRouter as Router} from "react-router-dom";
import AxiosInterceptor from "./hoc/AxiosInterceptor";
import {AbilityContext} from "./services/Can";
import ability from "./services/ability";
import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AxiosInterceptor>
                <AbilityContext.Provider value={ability}>
                    <IntellActApp />
                </AbilityContext.Provider>
            </AxiosInterceptor>
            <ToastContainer position="bottom-right" limit={1} pauseOnFocusLoss={false}/>
        </Router>
    </Provider>,
    document.getElementById('root'));
