import React, {useCallback, useState} from 'react';
import {useDispatch} from "react-redux";
import {createGate, deleteGate, updateGate} from "../../store/controllers/airport.controllers";
import SmoothDropdown from "../../components/common/SmoothDropdown/SmoothDropdown";
import classnames from "classnames";
import Can from "../../services/Can";
import {EDIT} from "../../services/ability";
import {Tooltip} from "../../assets/img/all-icons";
import CloseIcon from "../../assets/img/close-red.svg";
import AccordeonInnerItemContent from "./AccordeonInnerItemContent";

const AccordeonInnerItem = React.forwardRef(({iata, gate, shouldAutoFocusGate, setShouldAutoFocusGate, terminalId, allowedToManage,}, ref,) => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(false);
    const [stateGate, setStateGate] = useState(gate);

    const handleNameChange = useCallback(({ target: { value } }) => {
        setStateGate((prevState) => ({ ...prevState, name: value }));
    }, []);

    const unfreezeFieldInput = useCallback((e) => {
        e.stopPropagation();
        e.target.focus();
    }, []);

    const handleDropdownClick = useCallback(() => {
        setActive((prevState) => !prevState);
    }, []);

    const handleGateDelete = useCallback(
        (e) => {
            e.stopPropagation();
            dispatch(deleteGate({ id: stateGate.id, terminal: terminalId }, iata));
        },
        [dispatch, iata, stateGate.id, terminalId],
    );

    const onKeyDownHandler = useCallback((e) => {
        e.stopPropagation();
        if (e.keyCode === 13) e.target.blur();
    }, []);

    const onBlurGateHandler = useCallback(() => {
        if (stateGate.new) {
            dispatch(createGate({ terminal: terminalId, name: stateGate.name }, iata));
        } else {
            dispatch(updateGate(stateGate.id, {terminal: terminalId, name: stateGate.name}, iata));
        }
        setShouldAutoFocusGate(false);
    }, [stateGate.new, stateGate.name, stateGate.id, setShouldAutoFocusGate, dispatch, terminalId, iata,]);

    const itemStyle = isActive
        ? 'accordion-item-box accordion-item-box_active'
        : 'accordion-item-box';

    return (
        <SmoothDropdown
            title={
            <>
          <span className={classnames({ 'accordion-item-title_active': isActive })}>
            Gate
          </span>
                    <input
                        readOnly={!allowedToManage}
                        disabled={!allowedToManage}
                        size={stateGate.name.length || 1}
                        value={stateGate.name}
                        onChange={handleNameChange}
                        onBlur={onBlurGateHandler}
                        className={classnames('accordion-item-edit-field', {
                            'accordion-item-edit-field__active': isActive,
                        })}
                        onKeyDown={onKeyDownHandler}
                        onClick={unfreezeFieldInput}
                        autoFocus={shouldAutoFocusGate}
                    />
                </>
            }
            rightContent={
                isActive && (
                    <>
                        <div className="col-3 p-0">
                            <p className="col__title">URL</p>
                        </div>
                        <div className="col-3 p-0">
                            <p className="col__title">Position</p>
                        </div>
                        <div className="col-3 p-0">
                            <p className="col__title">Status</p>
                        </div>
                        <Can I={EDIT} this={{ type: 'airports', iata }}>
                            <div className="accordion-item-close-container">
                                <div className="attention">
                                    <Tooltip width="15px" />
                                    <div className="attention__wrap">
                                        <p className="attention__title">Remove related Cameras</p>
                                        <p className="attention__text">
                                            If you will delete a gate it will remove related Cameras
                                        </p>
                                    </div>
                                </div>
                                <button type="button" onClick={handleGateDelete}>
                                    <img src={CloseIcon} alt="Close" />
                                </button>
                            </div>
                        </Can>
                    </>
                )
            }
            isOpen={isActive}
            onToggleDropdown={handleDropdownClick}
            styles={{root: itemStyle, dropdown: 'accordion-inner-item', active: 'accordion-inner-item__active',}}
        >
            <div ref={ref} className="d-flex w-100">
                {stateGate.cameras.length > 0 ? (
                    <div className="accordion-inner-item-row">
                        {stateGate.cameras.map((item) => (<AccordeonInnerItemContent key={item.id} {...item} />))}
                    </div>
                ) : (
                    <h6 style={{ margin: '20px' }}>
                        This gate currently has no cameras.
                    </h6>
                )}
            </div>
        </SmoothDropdown>
    );
});

export default AccordeonInnerItem;