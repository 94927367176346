import { Ability, AbilityBuilder, detectSubjectType } from '@casl/ability';
import store from 'store/store';
import {
  SUPERUSER_ROLE,
  CLIENT_MANAGER_ROLE,
  AIRLINE_USER_TYPE,
} from 'store/constants/user.constants';
import { routes, processViews } from 'store/constants/ui.constants';

export const VIEW = 'view';
export const ADD = 'add';
export const EDIT = 'edit';
export const FILTER = 'filter';

const detectAppSubjectType = (subject) => {
  if (subject && typeof subject === 'object' && subject.type) {
    return subject.type;
  }

  return detectSubjectType(subject);
};

const defineRulesFor = (user) => {
  const { can, cannot, rules } = new AbilityBuilder();
  if (user.role === SUPERUSER_ROLE || user.role === CLIENT_MANAGER_ROLE) {
    can(VIEW, [
      routes.TURNAROUND,
      processViews.ADMIN,
      processViews.MONITORING,
      routes.AIRPORT_SETTINGS,
      routes.CAMERAS,
      routes.LOGS,
    ]);
  }

  if (user.role === SUPERUSER_ROLE) {
    can([VIEW, EDIT, ADD], routes.ACCOUNTS);
    can([EDIT, FILTER], 'airports');
    can(VIEW, routes.TURNAROUND_SETTINGS);
    can(VIEW, routes.STANDARDS);
  } else {
    cannot(VIEW, routes.ACCOUNTS);
    cannot(VIEW, routes.TURNAROUND_SETTINGS);
    can(EDIT, 'airports', {
      iata: {
        $in: user.permissions.reduce(
          (acc, { airport, is_manager: isManager }) =>
            isManager ? acc.concat(airport) : acc,
          [],
        ),
      },
    });
  }

  if (user.type === AIRLINE_USER_TYPE) {
    can(FILTER, 'airports');
  }

  return rules;
};

const ability = new Ability({}, { detectSubjectType: detectAppSubjectType });

// track redux user role changes
let currentUser;
store.subscribe(() => {
  const prevUser = currentUser;
  currentUser = store.getState().user;
  if (prevUser !== currentUser) {
    ability.update(defineRulesFor(currentUser));
  }
});

export default ability;