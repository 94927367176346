import {
  FETCH_CAMERAS_LOADING,
  FETCH_CAMERAS_ERROR,
  FETCH_CAMERAS_SUCCESS,
  UPDATE_CAMERA,
  CREATE_CAMERA,
  DELETE_CAMERA,
  CHANGE_PAGE,
} from 'store/constants/camera.constants';

export default {
  fetchCamerasLoading(isLoading) {
    return { type: FETCH_CAMERAS_LOADING, payload: isLoading };
  },
  fetchCamerasError(error) {
    return { type: FETCH_CAMERAS_ERROR, payload: error };
  },
  fetchCamerasSuccess(cameras) {
    console.log(cameras, '-------fetchCamerasSuccess in camera.action--------')
    return { type: FETCH_CAMERAS_SUCCESS, payload: cameras };
  },
  updateCamera(updatedCamera) {
    return { type: UPDATE_CAMERA, payload: updatedCamera };
  },
  createCamera(newCamera) {
    return { type: CREATE_CAMERA, payload: newCamera };
  },
  deleteCamera(cameraId) {
    return { type: DELETE_CAMERA, payload: cameraId };
  },
  changePage(page) {
    return { type: CHANGE_PAGE, payload: page };
  },
};
