import React, {useEffect, useState, useCallback, useRef, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import range from 'lodash/range';
import isToday from 'date-fns/isToday';
import { calculateTimePercent } from 'utils/helpers';
import requestInterval from 'utils/requestInterval';
import FlightDetails from '../FlightDetails/FlightDetails';
import TimeLine from '../TimeLine/TimeLine';
import FlightRow, { CELL_WIDTH } from './FlightRow';

import './FlightsTracker.scss';
import turnaroundActions from "../../../../store/actions/turnaround.actions";
import {updateListen, watchTurnaround} from "../../../../store/controllers/process.controllers";
import {useHistory} from "react-router-dom";


const TABLE_BLOCK_SIZE = 52;

const FlightsTracker = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {isCriticalPath, turnaround} = useSelector(state => state['turnaround']);
  const { processes, filter } = useSelector(({ processes }) => processes);

  const [timelineOffset, setTimelineOffset] = useState(0);
  const [turn_id, setTurn_id] = useState(null);
  const [flightDetails, setFlightDetails] = useState(null);

  const containerRef = useRef();
  const dataPanelRef = useRef();
  const wrapperRef = useRef();
  const detailsRef = useRef();

  const handleGoToTurn = () => {
    history.push(`/turnaround//${turn_id}`)
  }

  const onClickDetail = useCallback((details) => {
    setTurn_id(details['_id'])
    dispatch(turnaroundActions.isCriticalPath(true));
    setFlightDetails(details);
  }, []);

  const onDetailClose = useCallback(() => {
    dispatch(turnaroundActions.isCriticalPath(false));
    dispatch(turnaroundActions.closeConnection(async (socket) => socket.closeConnection()))
    setTurn_id(null);
    setFlightDetails(null);
  }, []);

  const calculateOffset = useCallback(() => {
    const currentTimeInPercent = calculateTimePercent(Date.now());
    return (
      dataPanelRef.current?.clientWidth +
      (currentTimeInPercent / 100) * (containerRef.current?.clientWidth - dataPanelRef.current?.clientWidth)
    );
  }, []);



  // SHIT
  const addEmptyColumn = useMemo(() => { // looks like shit - will try to find another solution
    let windowHeight = window.innerHeight;

    const sumWithoutExtraItems = windowHeight - (TABLE_BLOCK_SIZE * 3);

    const total = Math.max(0, Math.round((sumWithoutExtraItems / TABLE_BLOCK_SIZE) - processes.length));

    const emptyItems = Array(total).fill({});

    return [...processes, ...emptyItems];
  }, [processes, window.innerHeight]);


  const resizeTable = useMemo(() => { // looks like shit - will try to find another solution
    const windowHeight = window.innerHeight
    const getTableSize = windowHeight - (TABLE_BLOCK_SIZE * 2);
    const details = detailsRef?.current

    if (details) {
        return getTableSize - details?.clientHeight
    }

    return window.innerHeight
  }, [window.innerHeight, detailsRef.current])

  // SHIT


  useEffect(() => {
    let offset = calculateOffset();
    setTimelineOffset(offset);

    const interval = requestInterval(() => {
      offset = calculateOffset();
      setTimelineOffset(offset);
    }, 1000);

    return () => clearInterval(interval.value);
  }, [calculateOffset]);

  useEffect(() => {
    if (isToday(filter.date)) {
      wrapperRef.current.scrollLeft += CELL_WIDTH * (new Date().getHours() - 1);
      wrapperRef.current.scroll(wrapperRef.current.scrollLeft, 0);
    }
  }, [filter.date]);


  useEffect(() => {
    if (isCriticalPath) {
        dispatch(turnaroundActions.establishCriticalPathConnection(async (socket) => socket.connect('critical')));
    }
  }, [isCriticalPath]);


  useEffect(() => {
    if (turn_id) {
        dispatch(turnaroundActions.criticalPathsListener(async (socket, dispatch, getState) => {
          return watchTurnaround(socket, dispatch, getState, {emit: {turnaround_id: turn_id}, event: {type: 'turnaround', meta: 'criticalPath'}})
        }))
    }
  }, [turn_id])

  useEffect(() => {
    if (Object.keys(turnaround).length) {
          dispatch(turnaroundActions.criticalPathsListener(async (socket, dispatch, getState) => {
              return updateListen(socket, dispatch, getState, {type: 'turnaround', meta: 'criticalPath'})
          }))
    }
  }, [turnaround])


  useEffect(() => {
    return () => {
      console.log('RETURN FLIGHT_TRACKER COMPOENBNT')
      setTimelineOffset(0)
      setTurn_id(null)
      setFlightDetails(null)
    }
  }, [])

  return (
    <>
      <div ref={wrapperRef} className="table-content table-max__width scroll-bar__style shift" style={{height: `${resizeTable}px`}}>
        <div className="table-content-columns width-for-sticky-scroll table-content-header">
          <div ref={dataPanelRef} className="table-content-columns title">
            <span className="table-content-columns__width" />
            <span className="table-content-columns__width">Flight</span>
            <span className="table-content-columns__width">Route</span>
            <span className="table-content-columns__width">Turn type</span>
          </div>

          <div className="table-content-columns time">
            {range(24).map(i => {
              return (
                  <span key={i} className="table-content-columns__width" style={{ width: CELL_WIDTH }}>
                    {i < 10 ? `0${i}:00` : `${i}:00`}
                  </span>
                )
            })}
          </div>
        </div>

        <div ref={containerRef} className="table-content-row">
          {isToday(filter.date) && <TimeLine offset={`${timelineOffset}px`} />}

          {addEmptyColumn?.map((item, i) => <FlightRow key={item['_id'] || i} flight={item} onClickDetail={onClickDetail} index={addEmptyColumn.length - i}/>)}
        </div>
      </div>
      {flightDetails && <FlightDetails check={detailsRef} turnaround={flightDetails} onDetailClose={onDetailClose} handleGoToTurn={handleGoToTurn}/>}
    </>
  );
};

export default FlightsTracker;