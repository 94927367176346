import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ArrowRight } from '../../../assets/img/all-icons';

import './SmoothDropdown.scss';

const SmoothDropdown = ({
  title,
  styles,
  children,
  isOpen,
  onToggleDropdown,
  numberOfChildren,
  innerRef,
  rightContent,
}) => {
  const [isListOpen, setListOpen] = useState(isOpen);
  const contentRef = useRef();
  const [containerStyles, setContainerStyles] = useState({});
  numberOfChildren = numberOfChildren || 1;

  useEffect(() => {
    setContainerStyles({
      transition: `all ${isListOpen ? Math.min(numberOfChildren * 0.4, 1) : 0.4}s ease`,
      maxHeight: isListOpen ? `${numberOfChildren * (80 + Math.max(innerRef.current?.clientHeight || 0, 300))}px` : 0,
      height: isListOpen && `auto`,
      overflow: isListOpen ? 'visible' : 'hidden',
    });
  }, [isListOpen, numberOfChildren, innerRef.current?.clientHeight]);

  const toggleList = useCallback(() => {
    setListOpen((prevState) => !prevState);
    onToggleDropdown();
  }, [onToggleDropdown]);

  return (
    <div>
      <div
        className={classnames('dropdown d-flex', {
          [styles.dropdown]: styles.dropdown,
          [styles.active]: styles.active && isListOpen,
        })}
        onClick={toggleList}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        <div className="col d-flex p-0">
          <ArrowRight
            width="36px"
            className={classnames('dropdown-arrow', {
              'dropdown-arrow__active': isListOpen,
            })}
          />
          <div
            className={classnames('dropdown-title', {
              [styles.title]: styles.title,
            })}
          >
            {title}
          </div>
        </div>
        {rightContent}
      </div>
      <div style={containerStyles}>
        <div ref={contentRef} className={classnames('list', { [styles.content]: styles.content })}>
          {children}
        </div>
      </div>
    </div>
  );
};

SmoothDropdown.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onToggleDropdown: PropTypes.func,
  styles: PropTypes.shape({
    dropdown: PropTypes.string,
    active: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    root: PropTypes.string,
  }),
  numberOfChildren: PropTypes.number,
  innerRef: PropTypes.shape({}),
};

SmoothDropdown.defaultProps = {
  isOpen: false,
  onToggleDropdown: () => {},
  numberOfChildren: 1,
  innerRef: {},
  styles: {},
};

export default SmoothDropdown;
