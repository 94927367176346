export default (requestType) => {
  switch (requestType) {
    case 'GET':
      return 'rgb(27, 128, 50)';
    case 'PUT':
      return 'rgb(245, 166, 35)';
    case 'POST':
      return 'rgb(74, 144, 226)';
    case 'DELETE':
      return 'rgb(226, 74, 74)';
    default:
      return '#303030';
  }
};
