import {
    FETCH_SAFETY_ALERTS_LOADING,
    FETCH_SAFETY_ALERTS_SUCCESS,
    CREATE_SAFETY_ALERT,
    UPDATE_SAFETY_ALERT,
    DELETE_SAFETY_ALERT,
} from 'store/constants/safetyAlerts.constants';

const initialState = {
    safetyAlerts: [],
    isLoading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SAFETY_ALERTS_SUCCESS:
            return {...state, safetyAlerts: action.payload};
        case FETCH_SAFETY_ALERTS_LOADING:
            return {...state, isLoading: action.payload};
        case CREATE_SAFETY_ALERT:
            return {...state, safetyAlerts: [action.payload].concat(state.safetyAlerts),};
        case UPDATE_SAFETY_ALERT:
            return {
                ...state,
                safetyAlerts: state.safetyAlerts.map((alert) => (alert.id === action.payload.id ? action.payload : alert)),
            };
        case DELETE_SAFETY_ALERT:
            return {...state, safetyAlerts: state.safetyAlerts.filter((alert) => alert.id !== action.payload),};
        default:
            return state;
    }
};
