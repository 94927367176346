import { IntellActAPI } from './api';

export default {
  createTerminal(terminal, airportIATA) {
    return IntellActAPI.post(`/airport/${airportIATA}/terminals/`, terminal);
  },
  updateTerminal(terminalId, terminal, airportIATA) { // endpoint doesn't exist
    return IntellActAPI.put(
      `/airport/${airportIATA}/terminal/${terminalId}`,
      terminal,
    );
  },
  deleteTerminal(terminalId, airportIATA) {
    return IntellActAPI.delete(
      `/airport/${airportIATA}/terminals/${terminalId}/`,
    );
  },
};
