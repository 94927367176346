export const SET_AUTH_USER_DATA = 'SET_AUTH_USER_DATA';

export const SUPERUSER_ROLE = 'superuser';
export const CLIENT_MANAGER_ROLE = 'client-manager';

export const AIRPORT_USER_TYPE = 'airport';
export const AIRLINE_USER_TYPE = 'airline';

export const SITE_MANAGER_PERMISSION = 'site-manager';
export const VIEWER_PERMISSION = 'viewer';

export const GET_COOKIE_DATA = 'GET_COOKIE_DATA'

export const ADD_TOKEN_TO_STORE = 'ADD_TOKEN_TO_STORE'


export const GET_INITIAL_STATE = 'GET_INITIAL_STATE'





export const LOGOUT = 'LOGOUT'

export const ERROR_FETCH = 'ERROR_FETCH'



export const CHANGE_DEF_AIRPORT = 'CHANGE_DEF_AIRPORT'