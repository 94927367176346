import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AppRouter from 'pages/Router';
import {initializeApp} from './store/app';
import {Preloader} from './components/common/Preloader/Preloader';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {useLocation} from "react-router";
import userActions from "./store/actions/user.actions";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";


const IntellActApp = () => {
    const location = useLocation();
    const history = useHistory()
    const initialized = useSelector(state => state.app['initialized']);
    const user = useSelector(state => state.user);

    const dispatch = useDispatch();


    useEffect(() => {
        if (user['isError'] && user?.isError['status'] === 401) {
            dispatch(userActions.logout())
            toast.error(`${user?.isError['statusText']}, please re-login`);
        }
    }, [user['isError'], location.pathname])


    useEffect(() => {
        dispatch(initializeApp())
    }, []);

    if (!initialized || user.isLoading) {
        return <Preloader/>;
    }

    return (
        <div className="app">
            <AppRouter/>
        </div>
    );
};

export default IntellActApp;
