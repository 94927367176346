import { PAGE_CHANGE, PROCESS_VIEW_CHANGE } from 'store/constants/ui.constants';

export default {
  changePage(page = '') {
    return { type: PAGE_CHANGE, payload: page };
  },
  changeProcessView(view = '') {
    localStorage.setItem('processView', view);
    return { type: PROCESS_VIEW_CHANGE, payload: view };
  },
};
