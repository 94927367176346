import React, { useCallback } from 'react';

import MyCheckbox from 'components/ui/MyCheckbox/MyCheckbox';

const ServiceEvents = ({ events, onChange, allowedEvents }) => {
  const onCheckboxClick = useCallback(
    (e) => {
      onChange(parseInt(e.target.name, 10), e.target.checked);
    },
    [onChange],
  );

  return (
    <div className="check-group">
      <div className="check-group__items">
        {events.map((item) => (
          <MyCheckbox
            key={item.id}
            label={item.name}
            name={item.id}
            onChange={onCheckboxClick}
            id={item.id}
            checked={allowedEvents.includes(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default ServiceEvents;
