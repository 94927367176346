import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Logo, ArrowRight } from 'assets/img/all-icons';

import s from './Header.module.scss';

const Header = ({ children }) => {
  const history = useHistory();

  return (
    <div className={s.header}>
      <div className={s.navigation}>
        <button type="button" className={s.prevPage} onClick={history.goBack}>
          <ArrowRight width="25px" className={s.arrow} style={{ transform: 'rotate(180deg)' }} />
        </button>
        <Logo width="120px" className={s.logo} />
      </div>

      {children}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
