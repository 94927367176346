import { toast } from 'react-toastify';
import apiActions from 'store/actions/api.actions';

export const fetchAPIInfo = () => async (dispatch) => {
  dispatch(apiActions.fetchApiLoading(true));
  try {
    const response = await (() =>
      new Promise((res) =>
        setTimeout(
          () =>
            res([
              {
                name: 'Processes',
                entries: [
                  {
                    id: 1,
                    name: 'Get all processes',
                    method: 'GET',
                    endpoint: '/processes',
                    headers: [
                      {
                        name: 'Intellact-API-Key',
                        type: 'string',
                        required: true,
                        description: 'Your API Key',
                      },
                    ],
                    required: [
                      {
                        name: 'airport_id',
                        type: 'string',
                        description: 'IATA code of the airport',
                      },
                      {
                        name: 'filter_by',
                        type: 'string',
                        description:
                          'Filter that is applied while quering processes',
                      },
                    ],
                    optional: [
                      {
                        name: 'limit',
                        type: 'string',
                        description:
                          'Amount of processes to be fetched per once',
                        default: '50',
                      },
                      {
                        name: 'paginate',
                        type: 'boolean',
                        description: 'Enables pagination for processes',
                        default: 'true',
                      },
                    ],
                    body: {},
                  },
                  {
                    id: 2,
                    name: 'Update process',
                    method: 'PUT',
                    endpoint: '/processes/{id}',
                  },
                  {
                    id: 3,
                    name: 'Create process',
                    method: 'POST',
                    endpoint: '/processes/{id}',
                  },
                  {
                    id: 4,
                    name: 'Delete process',
                    method: 'DELETE',
                    endpoint: '/processes/{id}',
                  },
                ],
              },
              {
                name: 'Cameras',
                entries: [
                  {
                    id: 5,
                    name: 'Get all cameras',
                    method: 'GET',
                    codeExample: '',
                  },
                  {
                    id: 6,
                    name: 'Update camera',
                    method: 'PUT',
                    codeExample: '',
                  },
                  {
                    id: 7,
                    name: 'Create camera',
                    method: 'POST',
                    codeExample: '',
                  },
                  {
                    id: 8,
                    name: 'Delete camera',
                    method: 'DELETE',
                    codeExample: '',
                  },
                ],
              },
            ]),
          1000,
        ),
      ))();
    dispatch(apiActions.fetchApiSuccess(response));
    dispatch(apiActions.selectRequest(response[0]?.entries[0]?.id));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
  dispatch(apiActions.fetchApiLoading(false));
};
