import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'components/common/Modal/Modal';
import Button from 'components/ui/Button/Button';
import { Preloader } from 'components/common/Preloader/Preloader';

import ServiceEvents from '../ServiceEvents/ServiceEvents';

const ServicesModal = ({ allowedEvents, isOpen, onClose, onSave }) => {
  const [userEvents, setUserEvents] = useState(allowedEvents);

  const { eventClasses, isLoading: areServicesLoading } = useSelector((state) => ({
    ...state.services,
    eventClasses: state.services.eventClasses,
  }));

  const onEventChange = useCallback((eventId, toAdd) => {
    setUserEvents((prevState) => (toAdd ? prevState.concat(eventId) : prevState.filter((id) => id !== eventId)));
  }, []);

  const onEventsSave = useCallback(() => {
    onSave(userEvents);
  }, [onSave, userEvents]);

  useEffect(() => {
    setUserEvents(allowedEvents);
  }, [allowedEvents]);

  return (
    <Modal isOpen={isOpen} onModalClose={onClose}>
      {areServicesLoading ? (
        <Preloader />
      ) : (
        <div className="my-popup__content">
          <h2 className="my-popup__title">Turnaround services</h2>
          <div className="my-popup__box">
            <div className="my-popup__col">
              <ServiceEvents
                events={eventClasses.slice(0, eventClasses.length / 2)}
                allowedEvents={userEvents}
                onChange={onEventChange}
              />
            </div>
            <div className="my-popup__col">
              <ServiceEvents
                events={eventClasses.slice(eventClasses.length / 2)}
                allowedEvents={userEvents}
                onChange={onEventChange}
              />
            </div>
          </div>
          <div className="my-popup__row my-popup__row_end save-line">
            <Button onClick={onEventsSave}>Save</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ServicesModal;
