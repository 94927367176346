import { useState, useEffect, useCallback } from 'react';
import requestInterval from './requestInterval';

export default () => {
  const [now, setNow] = useState(new Date().getTime());


  const start = useCallback(() => {
    return requestInterval(() => setNow(new Date().getTime()), 1000);
  }, []);

  useEffect(() => {
    const { value: interval } = start();
    return () => {
      clearInterval(interval);
    };
  }, [start]);

  return now;
};
