import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import Button from 'components/ui/Button/Button';

import { fetchGatesByAirport } from 'store/controllers/gate.controllers';
import { subscribeToCameraImage } from 'store/controllers/camera.controllers';

import { reject, without } from 'lodash';
import MyCheckbox from 'components/ui/MyCheckbox/MyCheckbox';
import Editor from '../CameraEditor/CameraEditor';

import s from './CameraGatesForm.module.scss';
import { availableCones } from '../Data';
// import { createCamera } from 'services/api/camera.api.js'

const CameraGatesForm = ({ camera, onKeypointsSave }) => {
  console.log(camera, '---------CAMERA-----CameraGatesForm component line 15-')

  const [isLoading, setLoading] = useState(false);
  // const [areas, setAreas] = useState(camera.gates);
  const [areas, setAreas] = useState(camera);
  const [availableGates, setAvailableGates] = useState([]);
  const [cameraImage, setCameraImage] = useState('');
  const containerRef = useRef();

  const { selectedAirport } = useSelector(({ airport }) => airport, shallowEqual);

  console.log(areas, '---------AREAS-----CameraGatesForm component line 20-')

  useEffect(() => {
    setLoading(true);
    fetchGatesByAirport(selectedAirport.iata).then((gates) => {
      setAvailableGates(gates.map((gate) => ({ ...gate, visible_cones: [], clearance_points: [] })));
    });

    subscribeToCameraImage(
      {airportIATA: selectedAirport.iata, cameraId: camera['cctv_id'],},
      (response) => {
        if (response?.data) {
          setCameraImage(response.data);
        }
      },
    );
    setLoading(false); 
    /*
    * setLoading(false) was part of the else clause of the above conditional 
    * It is likely what blocked the content from being displayed
    * This func is possibly sending to /airport/{iata}/gates, wheel of death is probably the page waiting for a response
    ! Running it regardless of the conditional may or may not have other cosequences
    */
  }, [camera.id, selectedAirport.iata]);

  const toggleConeVisibility = useCallback((gateId, coneName) => {
    setAreas((prevState) =>
      prevState.map((area) =>
        area.gate.id === gateId
          ? {
              ...area,
              visible_cones: area.visible_cones.includes(coneName)
                ? without(area.visible_cones, coneName)
                : area.visible_cones.concat(coneName),
            }
          : area,
      ),
    );
  }, []);

  const addCameraGate = useCallback((gateArea) => {
    setAreas((prevState) => prevState.concat(gateArea));
  }, []);

  const deleteCameraGate = useCallback((gateId) => {
    setAreas((prevState) => reject(prevState, ['gate.id', gateId]));
  }, []);

  const onSaveClick = useCallback(() => {
    onKeypointsSave(areas);
  }, [areas, onKeypointsSave]);

  return (
    <div className={s.container}>
      {isLoading || !cameraImage ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className={s.inner} ref={containerRef}>
            <Editor
              imageBase64={cameraImage}
              container={containerRef}
              areas={areas}
              gates={availableGates}
              onGateAreaCreate={addCameraGate}
              onGateDelete={deleteCameraGate}
            />
          </div>

          <div className={s.gateConesList}>
            {areas.map(({ gate, visible_cones }) => (
              <div key={gate.id} className={s.conesContainer}>
                <div className={s.gate}>Gate {gate.name}</div>

                {availableCones.map((cone) => (
                  <div key={cone.name}>
                    <MyCheckbox
                      label={cone.name}
                      id={`${gate.id}_${cone.name}`}
                      checked={visible_cones.includes(cone.name)}
                      onChange={() => toggleConeVisibility(gate.id, cone.name)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/*  <div className={s.gateConesList}>*/}
          {/*    {areas.map(filed => (*/}
          {/*        <div key={filed['cctv_id']} className={s.conesContainer}>*/}
          {/*          <div className={s.gate}>Gate {gate.name}</div>*/}

          {/*          {availableCones.map((cone) => (*/}
          {/*              <div key={cone.name}>*/}
          {/*                <MyCheckbox*/}
          {/*                    label={cone.name}*/}
          {/*                    id={`${filed['cctv_id']}_${cone.name}`}*/}
          {/*                    checked={filed.visible_cones.includes(cone.name)}*/}
          {/*                    onChange={() => toggleConeVisibility(filed['cctv_id'], cone.name)}*/}
          {/*                />*/}
          {/*              </div>*/}
          {/*          ))}*/}
          {/*        </div>*/}
          {/*    ))}*/}
          {/*</div>*/}

          <Button onClick={onSaveClick} className={s.saveButton}>
            Save
          </Button>
        </>
      )}
    </div>
  );
};

CameraGatesForm.propTypes = {
  camera: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    gates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        gate: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
        visible_cones: PropTypes.arrayOf(PropTypes.string),
        clearance_points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      }),
    ),
  }).isRequired,
  onKeypointsSave: PropTypes.func.isRequired,
};

export default CameraGatesForm;
