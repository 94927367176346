import React from 'react';
import classNames from 'classnames';

import './MyInput.scss';

const Input = ({ label, id, className, ...inputProps }) => {
  return (
    <div className="my-input">
      <label className="my-input-inner" htmlFor={`input_${id}`}>
        <span className="my-input-inner__label">{label}</span>
        <input
          {...inputProps}
          className={classNames('my-input-inner__input', className)}
          id={`input_${id}`}
        />
      </label>
    </div>
  );
};

export default Input;
