import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import {routes} from 'store/constants/ui.constants';
import Layout from './Layout/Layout';

const PrivateRoute = ({component: Component, render, path, exact}) => {
    const user = useSelector((state) => state.user);

    if (!user.isAuthorized) return <Redirect to={routes.SIGNIN}/>;

    if (!Component) {
        return <Route render={render} path={path} exact={exact}/>;
    }

    return (
        <Route render={(props) => (<Layout> <Component {...props} /> </Layout>)} path={path} exact={exact}/>
    );
};

export default PrivateRoute;
