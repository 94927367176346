import { toast } from 'react-toastify';
import { accountAPI } from 'services/api';
import accountsActions from 'store/actions/accounts.actions';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

const transformFilter = (filter) => {
  const transformedFilter = {};
  if (filter.name[0]) {
    transformedFilter.q = filter.name[0].value.name;
  }
  if (filter.userType[0]) {
    transformedFilter.isSuperuser = filter.userType[0].value.value;
  }
  if (filter.clientType[0]) {
    transformedFilter.accountType = filter.clientType[0].value.value;
  }

  return transformedFilter;
};

export const fetchAccounts = (page, perPage, filter) => async (dispatch) => {
  dispatch(accountsActions.toggleIsFetching(true));
  dispatch(accountsActions.setCurrentPage(page));
  try {
    const accountsFilter = transformFilter(filter);
    const response = await accountAPI.getAccounts({
      page,
      perPage,
      ...accountsFilter,
    });
    // dispatch(accountsActions.setAccountsList(response.data.items));
    dispatch(accountsActions.setAccountsList(response.data));
    // dispatch(accountsActions.setTotalAccountsCount(response.data.total));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading account: ${errorMessage}`);
  }
  dispatch(accountsActions.toggleIsFetching(false));
};

export const fetchAccountById = async (accountId) => {
  try {
    const response = await accountAPI.getAccount(accountId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    return {
      ...response.data,
      permissions: response.data.permissions.map((permission) => ({
        airport: permission.airport,
        is_manager: permission.is_manager,
        event_classes: permission.processes.flatMap((process) =>
          process.event_classes.map(({ id }) => id),
        ),
      })),
    };
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading account: ${errorMessage}`);
  }
  return null;
};

export const createAccount = async (account) => {
  try {
    const response = await accountAPI.createAccount(account);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    toast.success('Account successfully created');
    return true;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while creating account: ${errorMessage}`);
  }
  return false;
};

export const updateAccount = async (accountId, account) => {
  try {
    const response = await accountAPI.updateAccount(accountId, account);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    toast.success('Account successfully updated');
    return true;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while updating account: ${errorMessage}`);
  }
  return false;
};
