import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const useOutsideListener = (ref, onClick) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
};

const ClickAwayListener = ({ onClick, children }) => {
  const wrapperRef = useRef(null);
  useOutsideListener(wrapperRef, onClick);

  return <div ref={wrapperRef}>{children}</div>;
};

ClickAwayListener.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ClickAwayListener.defaultProps = {
  onClick: () => {},
};

export default ClickAwayListener;
