/* eslint-disable no-unused-vars */
import {map} from 'lodash';
import alertsActions from 'store/actions/alerts.actions';
import processActions from 'store/actions/process.actions';
import turnaroundActions from 'store/actions/turnaround.actions';
import {getLocalDateFromUTC} from 'utils/helpers';
import {parseISO} from 'date-fns';

const UTC_TIMES = {
    SST: 'scheduled_start_time',
    AST: 'actual_start_time',
    SET: 'scheduled_end_time',
    AET: 'actual_end_time',
    ST: 'scheduled_ts',
    AT: 'actual_ts',
    STA: 'sta_utc',
    ATA: 'ata_utc',
    STD: 'std_utc',
    ATD: 'atd_utc'
}

export const withLocalDates = (flight) => {
    // if (!flight.processes) {
    //     console.error('withLocalDates getting incompatible input')
    //     return {}
    // }

    // if (!flight['schedule']) {
    //     console.error('withLocalDates getting incompatible input')
    //     return {}
    // }

    const end = flight['atd_utc'] && parseISO(flight[UTC_TIMES?.ATD]);

    return {
        ...flight,
        rst: flight['real_start_time'] && getLocalDateFromUTC(flight['real_start_time']),
        ret: flight['real_end_time'] && getLocalDateFromUTC(flight['real_end_time']),
        processes: flight['schedule'] ? Object.values(flight['schedule']).map(item => ({
            ...item,
            sft: item['scheduled_finish'] && getLocalDateFromUTC(item['scheduled_finish']),
            sst: item['scheduled_start'] && getLocalDateFromUTC(item['scheduled_start']),
            ast: (() => {
                if (item['actual_start'] || !end) {
                    return item['actual_start'] && getLocalDateFromUTC(item['actual_start'])
                } else {
                    return null
                }
            })(),
            aft: item['actual_finish'] && getLocalDateFromUTC(item['actual_finish']),
            event_classes: item['event_classes'] ? Object.values(item['event_classes']).map(item => ({
                        ...item,
                        scheduled: item['scheduled'] && getLocalDateFromUTC(item['scheduled']),
                        actual: item['actual'] && getLocalDateFromUTC(item['actual']),
                        events: item['events'] ? Object.values(item['events']).map(item => ({
                            ...item,
                            timestamp: item['timestamp'] && getLocalDateFromUTC(item['timestamp'])
                        })) : []
                })) : [] })) : [],
        sta: flight[UTC_TIMES.STA] && getLocalDateFromUTC(flight[UTC_TIMES.STA]),
        ata: flight[UTC_TIMES.ATA] && getLocalDateFromUTC(flight[UTC_TIMES.ATA]),
        std: flight[UTC_TIMES.STD] && getLocalDateFromUTC(flight[UTC_TIMES.STD]),
        atd: flight[UTC_TIMES.ATD] && getLocalDateFromUTC(flight[UTC_TIMES.ATD]),
    };
}

const responseResolver = (response, event) => {
    if (!event) {
        console.error('Bad connection:', response);
        return () => {};
    }

    switch (event.type) {
        case 'turnaround': {
            switch (event.meta) {
                case 'list':
                case 'table':
                    return processActions.fetchProcessesSuccess(map(Object.values(response), withLocalDates));
                case 'turn_table':
                    return processActions.fetchProcessesSuccess(response);
                case 'update': // Use to be entirely unreachable
                    return processActions.updateProcesses(withLocalDates(response));
                case 'single': // single turrnaround, not enevt
                    return turnaroundActions.fetchTurnaroundSuccess(withLocalDates(response));
                case 'criticalPath':
                    return turnaroundActions.getCriticalPaths(withLocalDates(response))
                default:
                    return () => {};
            }
        }
        case 'alerts': {
            return alertsActions.fetchAlertsSuccess(response.alerts);
        }
        default: {
            console.warn(`another event! ${event.type}`);
            return () => {
            };
        }
    }
};

export const updateListen = async (socket, dispatch, getState, settings) => { // the new function
    await socket.update((response) => {
        if (response) {
            const updateFile = JSON.parse(response);
            dispatch(responseResolver(updateFile, settings));
            socket.clearListen('update');
            console.log(updateFile, 'update__RESPONSE__update');
        }
    })
}

export const watchTurnaround = async (socket, dispatch, getState, settings) => {
    await socket.send(settings['emit']);
    await socket.listen((response) => {
        const data = JSON.parse(response)
        dispatch(responseResolver(data, settings['event']));
        console.log(data, 'populate__RESPONSE__populate')
        socket.clearListen('populate')
    })
};