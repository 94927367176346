export const AIRPORT_SELECT = 'AIRPORT_SELECT';
export const AIRPORT_UPDATE = 'AIRPORT_UPDATE';
export const FETCH_AIRPORT_SUCCESS = 'FETCH_AIRPORT_SUCESS';
export const FETCH_AIRPORT_LOADING = 'FETCH_AIRPORT_LOADING';
export const CREATE_TERMINAL = 'CREATE_TERMINAL';
export const UPDATE_TERMINAL = 'UPDATE_TERMINAL';
export const DELETE_TERMINAL = 'DELETE_TERMINAL';
export const CREATE_GATE = 'CREATE_GATE';
export const UPDATE_GATE = 'UPDATE_GATE';
export const DELETE_GATE = 'DELETE_GATE';
