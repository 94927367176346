import React, {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';

import {defaultFormat} from 'utils/helpers';
import {Preloader} from 'components/common/Preloader/Preloader';
import {fetchCameraFrame} from 'store/controllers/camera.controllers';
import brokeImage from '../../../assets/img/broken-image-300x300.png'

import './Frame.scss';

const EventFrame = ({airportIATA, event, onClick}, ref) => {
    const [image, setImage] = useState('');
    const [isVisible, setVisible] = useState(false);

    const [frameLoading, setFrameLoading] = useState(false);
    const [frameError, setFrameError] = useState(null);

    useEffect(() => {
        if (isVisible && !image) {
            const params = {
                frame_id: `/${event['frame_id']}`,
                airport: airportIATA,
                bbox: event['bbox'],
                min_size: [500, 365],
            }

            fetchCameraFrame(params, setFrameLoading, setFrameError).then(({data}) => {
                if (data) setImage(data);
            })
        }
    }, [isVisible, image, event, airportIATA]);

    return (
        <VisibilitySensor onChange={setVisible} partialVisibility containment={ref.current}>
            <div className="cameras-box-item">
                <span className="cameras-box-item-title" title={event['display']}>{event['display']}</span>
                <div className="video-item-box" onClick={() => onClick(event, frameError)}>
                    {frameLoading && <Preloader size="small"/>}
                    {image && <img src={image} alt={event['display']} className="video-item"/>}
                    {frameError && <img src={brokeImage} alt="Failed load" style={{objectFit: 'contain', width: '100%', height: '100%'}}/>}
                    <span className="video-item-duration">{defaultFormat(event['timestamp'])}</span>
                </div>
            </div>
        </VisibilitySensor>
    );
};

export default React.memo(React.forwardRef(EventFrame), (prevProps, nextProps) =>
    isEqual(prevProps.event, nextProps.event),
);
