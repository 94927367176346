import { toast } from 'react-toastify';

import { serviceAPI } from 'services/api';
import servicesActions from 'store/actions/services.actions';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

export const fetchProcesses = () => async (dispatch) => {
  dispatch(servicesActions.fetchServicesLoading(true));

  try {
    const response = await serviceAPI.getProcesses();
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.fetchProcessesSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading processes: ${errorMessage}`);
  }

  dispatch(servicesActions.fetchServicesLoading(false));
};

export const createProcess = (processToCreate) => async (dispatch) => {
  try {
    const response = await serviceAPI.createProcess(processToCreate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(
      servicesActions.createProcess({
        ...processToCreate,
        id: response.data.id,
      }),
    );
    toast.success('Process successfully created');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while creating process: ${errorMessage}`);
  }
};

export const updateProcess = (processToUpdate) => async (dispatch) => {
  try {
    const response = await serviceAPI.updateProcess(processToUpdate.id, processToUpdate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Process successfully updated');
    dispatch(servicesActions.updateProcess(processToUpdate));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while updating process: ${errorMessage}`);
  }
};

export const deleteProcess = (processId) => async (dispatch) => {
  try {
    const response = await serviceAPI.deleteProcess(processId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.deleteProcess(processId));
    toast.success('Process successfully deleted');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while deleting process: ${errorMessage}`);
  }
};

export const fetchEventClasses = () => async (dispatch) => {
  dispatch(servicesActions.fetchServicesLoading(true));

  try {
    const response = await serviceAPI.getEventClasses();
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.fetchEventClassesSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading event classes: ${errorMessage}`);
  }

  dispatch(servicesActions.fetchServicesLoading(false));
};

export const createEventClass = (eventClassToCreate) => async (dispatch) => {
  try {
    const response = await serviceAPI.createEventClass(eventClassToCreate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(
      servicesActions.createEventClass({
        ...eventClassToCreate,
        id: response.data.id,
      }),
    );
    toast.success('Event class successfully created');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while creating event class: ${errorMessage}`);
  }
};

export const updateEventClass = (eventClassToUpdate) => async (dispatch) => {
  try {
    const response = await serviceAPI.updateEventClass(eventClassToUpdate.id, eventClassToUpdate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Event class successfully updated');
    dispatch(servicesActions.updateEventClass(eventClassToUpdate));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while updating event class: ${errorMessage}`);
  }
};

export const deleteEventClass = (eventClassId) => async (dispatch) => {
  try {
    const response = await serviceAPI.deleteEventClass(eventClassId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.deleteEventClass(eventClassId));
    toast.success('Event class successfully deleted');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while deleting event class: ${errorMessage}`);
  }
};

export const fetchEvents = () => async (dispatch) => {
  dispatch(servicesActions.fetchServicesLoading(true));

  try {
    const response = await serviceAPI.getEvents();
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.fetchEventsSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading events: ${errorMessage}`);
  }

  dispatch(servicesActions.fetchServicesLoading(false));
};

export const createEvent = (eventToCreate) => async (dispatch) => {
  try {
    const response = await serviceAPI.createEvent({
      name: eventToCreate.name,
    });
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(
      servicesActions.createEvent({
        ...eventToCreate,
        id: response.data.id,
      }),
    );
    toast.success('Event successfully created');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while creating event: ${errorMessage}`);
  }
};

export const updateEvent = (eventToUpdate) => async (dispatch) => {
  try {
    const response = await serviceAPI.updateEvent(eventToUpdate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Event successfully updated');
    dispatch(servicesActions.updateEvent(eventToUpdate));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while updating event: ${errorMessage}`);
  }
};

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    const response = await serviceAPI.deleteEvent(eventId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.deleteEvent(eventId));
    toast.success('Event successfully deleted');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while deleting event: ${errorMessage}`);
  }
};

export const fetchEventRelations = () => async (dispatch) => {
  dispatch(servicesActions.fetchServicesLoading(true));

  try {
    const response = await serviceAPI.getEventRelations();
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.fetchEventRelationsSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading events: ${errorMessage}`);
  }

  dispatch(servicesActions.fetchServicesLoading(false));
};

export const createEventRelation = (eventRelation) => async (dispatch) => {
  try {
    const response = await serviceAPI.createEventRelation(eventRelation);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(
      servicesActions.createEventRelation({
        ...eventRelation,
        id: response.data.id,
      }),
    );
    toast.success('Event successfully created');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while creating event: ${errorMessage}`);
  }
};

export const updateEventRelation = (eventToUpdate) => async (dispatch) => {
  try {
    const response = await serviceAPI.updateEventRelation(eventToUpdate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Event successfully updated');
    dispatch(servicesActions.updateEventRelation(eventToUpdate));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while updating event: ${errorMessage}`);
  }
};

export const deleteEventRelation = (eventId) => async (dispatch) => {
  try {
    const response = await serviceAPI.deleteEventRelation(eventId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(servicesActions.deleteEventRelation(eventId));
    toast.success('Event successfully deleted');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while deleting event: ${errorMessage}`);
  }
};
