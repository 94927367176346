import { withResponseHandler } from 'store/helpers';
import { airportAPI } from 'services/api';

// export const createGetAirportsHandler = (onSuccess) =>
//   withResponseHandler((q, limit) => {
//     return airportAPI.getAirports({ q, limit });
//   }, onSuccess);
//
// export const createGetAirportHandler = (onSuccess) =>
//   withResponseHandler((iata) => {
//     return airportAPI.getAirport(iata);
//   }, onSuccess);
//
// export const createImportAirportCSVDataHandler = (onSuccess) =>
//   withResponseHandler((airportCSV, airportIATA) => {
//     return airportAPI.importAirportCSV(airportCSV, airportIATA);
//   }, onSuccess);



export const createGetAirportsHandler = (onSuccess) =>
    withResponseHandler((limit) => {
        return airportAPI.getAirports({limit });
    }, onSuccess);

export const createGetAirportHandler = (onSuccess, token) =>
    withResponseHandler((iata) => {
        return airportAPI.getAirport(iata, token);
    }, onSuccess);

export const createImportAirportCSVDataHandler = (onSuccess) =>
    withResponseHandler((airportCSV, airportIATA) => {
        return airportAPI.importAirportCSV(airportCSV, airportIATA);
    }, onSuccess);
