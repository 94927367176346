import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';

import accountsReducer from 'store/reducers/account.reducer';
import airportReducer from 'store/reducers/airport.reducer';
import uiReducer from 'store/reducers/ui.reducer';
import processReducer from 'store/reducers/process.reducer';
import alertsReducer from 'store/reducers/alerts.reducer';
import turnaroundReducer from 'store/reducers/turnaround.reducer';
import cameraReducer from 'store/reducers/camera.reducer';
import servicesReducer from 'store/reducers/services.reducer';
import safetyAlertReducer from 'store/reducers/safetyAlert.reducer';
import logsReducer from 'store/reducers/logs.reducer';
import apiReducer from 'store/reducers/api.reducer';
import authReducer from 'store/reducers/user.reducer';
import appReducer from 'store/app';
import { socketMiddleware } from './helpers';
import SocketAPINew from "../services/testSocket";
import {toast} from "react-toastify";

const rootReducer = combineReducers({
  accounts: accountsReducer,
  user: authReducer,
  form: formReducer,
  app: appReducer,
  airport: airportReducer,
  ui: uiReducer,
  processes: processReducer,
  turnaround: turnaroundReducer,
  alerts: alertsReducer,
  cameras: cameraReducer,
  services: servicesReducer,
  safetyAlerts: safetyAlertReducer,
  logs: logsReducer,
  api: apiReducer,
});


const logoutReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
      rootReducer(undefined, action)
  }
  return rootReducer(state, action);
}


const socketClient = new SocketAPINew()

export default createStore(logoutReducer, composeWithDevTools(applyMiddleware(thunkMiddleware, socketMiddleware(socketClient))),);
