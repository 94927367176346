import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Input from 'components/ui/MyInput/MyInput';
import Button from 'components/ui/Button/Button';
import s from './ProfileGeneral.module.scss';

const ProfileGeneral = ({ defaultAirport, airports, onInfoSave }) => {
  const [airport, setAirport] = useState({
    value: defaultAirport,
    label: defaultAirport,
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handlePasswordConfirmationChange = useCallback((e) => {
    setPasswordConfirmation(e.target.value);
  }, []);

  const handleNewPasswordChange = useCallback((e) => {
    setNewPassword(e.target.value);
  }, []);

  const onAirportChange = useCallback((selectedAirport) => {
    setAirport(selectedAirport);
  }, []);

  const handleSaveClick = useCallback(() => {
    onInfoSave({ passwordConfirmation, newPassword, airport: airport.value });
  }, [airport.value, newPassword, onInfoSave, passwordConfirmation]);

  return (
    <div className={s.container}>
      <div className={s.block}>
        <div className={s.blockHeading}>Default Airport</div>
        <Select
          options={airports.map((item) => ({
            value: item,
            label: item,
          }))}
          value={airport}
          onChange={onAirportChange}
          isSearchable={false}
          styles={{
            control: (provided) => ({
              ...provided,
              background: 'transparent',
              boxShadow: 'none',
              cursor: 'pointer',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          noOptionsMessage={() => 'No airports'}
        />
      </div>

      <div className={s.block}>
        <div className={s.blockHeading}>Change Password</div>
        <Input
          id="new-password"
          type="password"
          value={newPassword}
          onChange={handleNewPasswordChange}
          placeholder="New password"
          className={s.input}
        />
        <Input
          id="password-confirmation"
          type="password"
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
          placeholder="Confirm password"
          className={s.input}
        />
        <Button onClick={handleSaveClick}>Save</Button>
      </div>
    </div>
  );
};

ProfileGeneral.propTypes = {
  airports: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultAirport: PropTypes.string.isRequired,
  onInfoSave: PropTypes.func.isRequired,
};

export default ProfileGeneral;
