import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import MyInput from 'components/ui/MyInput/MyInput';
import MySelect from 'components/ui/MySelect/MySelect';
import { AlertCircle, AttentionClose } from 'assets/img/all-icons';
import Button from 'components/ui/Button/Button';

import { positions, views } from '../Data';

import s from './CameraInfoForm.module.scss';


const InfoForm = ({ camera, iata, onSubmit }) => {
  const [cameraInfo, setCameraInfo] = useState(camera);

  console.log(cameraInfo, '-------cameraInfo in ---InfoForm component - >>>> line 17-----');

  const onInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setCameraInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  }, []);

  const onNextClick = useCallback(() => {
    /**
     * This is what takes you to the next part of updating or adding a new camera
     * As such, onSubmit is called every time you try to do either of those
     */

    if (cameraInfo.url){
      onSubmit(cameraInfo);
    }
    else {
      toast.warn('Please, enter camera URL');
    }
  }, [cameraInfo, onSubmit]);

  return (
    <>
      <div style={{ flexBasis: '70%' }}>
        <MyInput
          id={cameraInfo.id}
          value={cameraInfo.url}
          onChange={onInputChange}
          label="Camera URL"
          name="url"
          placeholder="rtsp:"
        />
        <div className="attention">
          <span>
            <AlertCircle width="18px" className="attention__icon" />
          </span>
          <p className="attention__text">
            Attention! For safe connection please use RTSP
          </p>
          <span>
            <AttentionClose width="8px" className="attention__icon_close" />
          </span>
        </div>
      </div>
      <div style={{ flexBasis: '25%' }}>
        <MySelect
          id="camera-location-select"
          value={cameraInfo.position}
          onChange={onInputChange}
          label="Position"
          name="position"
          options={positions}
        />
        <MySelect
          id="camera-position-select"
          value={cameraInfo.view}
          onChange={onInputChange}
          label="View"
          name="view"
          options={views[cameraInfo.position] || []}
        />
        <Button onClick={onNextClick} className={s.nextButton}>
          Next: Set up gates
        </Button>
      </div>
    </>
  );
};

InfoForm.propTypes = {
  camera: PropTypes.shape({ id: PropTypes.number, url: PropTypes.string })
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InfoForm;
