import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Circle, Image, Layer, Line, Stage } from 'react-konva';

import { flatten } from 'lodash';
import Button from 'components/ui/Button/Button';
import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import s from './CameraEditor.module.scss';
import GateSelect from '../GateSelect/GateSelect';

const img = new window.Image();

const CLEARANCE_ZONE_SELECTION_STEP = 'cones';
const GATE_SELECTION_STEP = 'gate';
const EDITING_STEP = 'edit-gate';

const Editor = ({ imageBase64, container, areas, gates, onGateAreaCreate, onGateDelete }) => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedGate, setSelectedGate] = useState(gates[0] || {});
  const [imageData, setImageData] = useState(null);
  const [currentStep, setCurrentStep] = useState(GATE_SELECTION_STEP);
  const editorRef = useRef();
  const imageRef = useRef();

  const handleGateChange = useCallback(
    (e) => {
      setSelectedGate(gates.find((gate) => gate.name === e.target.value));
    },
    [gates],
  );

  const reset = useCallback(() => {
    setCurrentStep(GATE_SELECTION_STEP);
    setSelectedPoint(null);
    setSelectedGate(null);
  }, []);

  const handleGateSubmit = useCallback(() => {
    setCurrentStep(CLEARANCE_ZONE_SELECTION_STEP);
  }, []);

  const handleClearanceZoneFinish = useCallback(() => {
    const transform = imageRef.current.getAbsoluteTransform().copy();
    transform.invert();

    const pos = transform.point(selectedPoint);
    const relativePos = { x: pos.x / imageRef.current.width(), y: pos.y / imageRef.current.height() };

    const clearanceZone = selectedGate.clearance_points.map(([x, y]) => {
      const relativePoint = transform.point({ x, y });
      return [relativePoint.x / imageRef.current.width(), relativePoint.y / imageRef.current.height()];
    });

    onGateAreaCreate({ gate: { ...selectedGate }, clearance_points: clearanceZone, visible_cones: [], ...relativePos });

    reset();
  }, [selectedGate, onGateAreaCreate, selectedPoint, reset]);

  const cancelSelection = useCallback(() => {
    setSelectedPoint(null);
    setSelectedGate(null);
    setCurrentStep(GATE_SELECTION_STEP);
  }, []);

  const handleGateDelete = useCallback(() => {
    if (currentStep === EDITING_STEP) {
      onGateDelete(selectedGate.id);
    }

    cancelSelection();
  }, [currentStep, selectedGate, onGateDelete, cancelSelection]);

  const handleGateClick = useCallback((clickedGate) => {
    setCurrentStep(EDITING_STEP);
    setSelectedGate(clickedGate);
    setSelectedPoint({ x: clickedGate.x, y: clickedGate.y });
  }, []);

  const fitStage = useCallback(() => {
    const stage = editorRef.current;
    const konvaImage = imageRef.current;

    if (container.current && konvaImage) {
      const width = container.current?.offsetWidth || 0;
      const height = container.current.offsetHeight || 0;

      stage.width(width);
      stage.height(height);

      const imageWidth = img.width;
      const imageHeight = img.height;

      const widthFit = width / imageWidth;
      const heightFit = height / imageHeight;

      const scale = widthFit > heightFit ? heightFit : widthFit;

      konvaImage.width(imageWidth * scale);
      konvaImage.height(imageHeight * scale);
      konvaImage.x((stage.width() - konvaImage.width()) / 2);
      konvaImage.y((stage.height() - konvaImage.height()) / 2);

      konvaImage.draw();
    }
  }, [container]);

  const handleImageLoad = useCallback(() => {
    fitStage();
    setImageData(img);
    img.removeEventListener('load', handleImageLoad);
  }, [fitStage]);

  const handleImageError = useCallback(
    (e) => {
      console.log(e);

      img.removeEventListener('load', handleImageLoad);
      img.removeEventListener('error', handleImageError);
    },
    [handleImageLoad],
  );

  const handleImageClick = useCallback(() => {
    const pos = editorRef.current.getPointerPosition();

    if (currentStep === GATE_SELECTION_STEP) {
      setSelectedPoint(pos);
    } else {
      setSelectedGate((prevState) => ({
        ...prevState,
        clearance_points: prevState.clearance_points.concat([[pos.x, pos.y]]),
      }));
    }
  }, [currentStep]);

  useEffect(() => {
    if (!selectedGate) {
      setSelectedGate(gates[0]);
    }
  }, [selectedGate, gates]);

  useEffect(() => {
    if (imageBase64) {
      img.src = `${imageBase64}`;
      img.addEventListener('load', handleImageLoad);
      img.addEventListener('error', handleImageError);
    }
  }, [handleImageLoad, imageBase64, handleImageError]);

  const guideText = useMemo(() => {
    if (currentStep === GATE_SELECTION_STEP) {
      return <h6>Please, select the gate or save camera configuration in case everything is set up.</h6>;
    }

    if (currentStep === CLEARANCE_ZONE_SELECTION_STEP) {
      return <h6>Please, draw clearance zone for gate {selectedGate.name}.</h6>;
    }

    return '';
  }, [currentStep, selectedGate]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        {guideText}
        {currentStep === CLEARANCE_ZONE_SELECTION_STEP && (
          <Button type="secondary" className={s.finishButton} onClick={handleClearanceZoneFinish}>
            Finish
          </Button>
        )}
      </div>

      <Stage ref={editorRef} onClick={handleImageClick}>
        <Layer>
          <Image image={imageData} ref={imageRef} listening={false} />
          {areas.map((area) => {
            if (!imageRef.current) return null;

            const transform = imageRef.current.getAbsoluteTransform().copy();

            const absoluteGatePoint = transform.point({
              x: area.x * imageRef.current.width(),
              y: area.y * imageRef.current.height(),
            });

            const absoluteClearanceZonePoints = area.clearance_points.map(([x, y]) => {
              const absPoint = transform.point({ x: x * imageRef.current.width(), y: y * imageRef.current.height() });
              return [absPoint.x, absPoint.y];
            });

            return (
              <>
                <Circle
                  key={area.id}
                  x={absoluteGatePoint.x}
                  y={absoluteGatePoint.y}
                  radius={5}
                  stroke="#fff"
                  fill="#1f80b4"
                  strokeWidth={3}
                  onClick={(e) => {
                    e.cancelBubble = true;
                    handleGateClick({ ...area.gate, x: absoluteGatePoint.x, y: absoluteGatePoint.y });
                  }}
                />
                <Line points={flatten(absoluteClearanceZonePoints)} stroke="#fff" strokeWidth={2} />
              </>
            );
          })}
          {selectedPoint && currentStep !== EDITING_STEP && (
            <Circle x={selectedPoint.x} y={selectedPoint.y} radius={5} stroke="#fff" strokeWidth={3} fill="#1f80b4" />
          )}

          {selectedGate && currentStep !== EDITING_STEP && (
            <Line points={flatten(selectedGate.clearance_points)} stroke="#fff" strokeWidth={2} />
          )}
        </Layer>
      </Stage>

      {selectedPoint && (currentStep === GATE_SELECTION_STEP || currentStep === EDITING_STEP) && (
        <ClickAwayListener onClick={cancelSelection}>
          <div className={s.gateSelect} style={{ left: `${selectedPoint.x + 10}px`, top: `${selectedPoint.y}px` }}>
            <GateSelect
              onDelete={handleGateDelete}
              mode={currentStep}
              onGateChange={handleGateChange}
              selectedGate={selectedGate}
              gates={gates}
              onSubmit={handleGateSubmit}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

Editor.propTypes = {
  imageBase64: PropTypes.string.isRequired,
  onGateAreaCreate: PropTypes.func.isRequired,
};

export default Editor;
