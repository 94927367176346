export const FETCH_ALL_SERVICES_SUCCESS = 'FETCH_ALL_SERVICES_SUCCESS';
export const FETCH_ALL_SERVICES_LOADING = 'FETCH_ALL_SERVICES_LOADING';

export const FETCH_SERVICE_PROCESSES_SUCCESS = 'FETCH_SERVICE_PROCESSES_SUCCESS';
export const CREATE_PROCESS = 'CREATE_PROCESS';
export const UPDATE_PROCESS = 'UPDATE_PROCESS';
export const DELETE_PROCESS = 'DELETE_PROCESS';

export const FETCH_EVENT_CLASSES_SUCCESS = 'FETCH_EVENT_CLASSES_SUCCESS';
export const CREATE_EVENT_CLASS = 'CREATE_EVENT_CLASS';
export const UPDATE_EVENT_CLASS = 'UPDATE_EVENT_CLASS';
export const DELETE_EVENT_CLASS = 'DELETE_EVENT_CLASS';

export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const FETCH_EVENT_RELATIONS_SUCCESS = 'FETCH_EVENT_RELATIONS_SUCCESS';
export const CREATE_EVENT_RELATION = 'CREATE_EVENT_RELATION';
export const UPDATE_EVENT_RELATION = 'UPDATE_EVENT_RELATION';
export const DELETE_EVENT_RELATION = 'DELETE_EVENT_RELATION';
