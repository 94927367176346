import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { find, isEmpty } from 'lodash';

import { Preloader } from 'components/common/Preloader/Preloader';
import Confirmation from 'components/common/Confirmation/Confirmation';
import Input from 'components/ui/MyInput/MyInput';

import { fetchEventClasses, fetchProcesses } from 'store/controllers/services.controllers';
import { deleteStandard, updateStandard, createStandard } from 'store/controllers/standards.controllers';

import Button from 'components/ui/Button/Button';
import StandardCard from './StandardCard';
import s from './StandardsList.module.scss';

const turnTypeOptions = [
  {
    value: 'quick_international',
    label: 'Quick International',
  },
  {
    value: 'normal_international',
    label: 'Normal International',
  },
  {
    value: 'quick_domestic',
    label: 'Quick Domestic',
  },
  {
    value: 'normal_domestic',
    label: 'Normal Domestic',
  },
];

const StandardsList = ({ isLoading, standards, airlineICAO, onStandardCreated }) => {
  const dispatch = useDispatch();

  const [standardToDelete, setStandardToDelete] = useState(null);
  const [newStandard, setNewStandard] = useState({});
  const { eventClasses, processes } = useSelector((state) => state.services);

  const handleStandardUpdate = useCallback(
    (standard) => {
      const eventClassId = find(eventClasses, { name: standard.event_class })?.id ?? -1;
      const processId = find(processes, { name: standard.process })?.id ?? -1;

      if (eventClassId === -1) {
        toast.warn('Nonexisting event class!');
        return;
      }

      updateStandard(airlineICAO, { ...standard, event_class: eventClassId, process: processId });
    },
    [airlineICAO, eventClasses, processes],
  );

  const handleStandardDelete = useCallback(() => {
    deleteStandard(airlineICAO, standardToDelete.id);
    setStandardToDelete(null);
  }, [standardToDelete, airlineICAO]);

  const onStandardDeleteClick = useCallback((standard) => {
    setStandardToDelete(standard);
  }, []);

  const onDeletePopupClose = useCallback(() => {
    setStandardToDelete(null);
  }, []);

  const handleTurnTypeChange = useCallback((turn_type) => {
    setNewStandard((prevState) => ({ ...prevState, turn_type }));
  }, []);

  const handleEventClassChange = useCallback((event_class) => {
    setNewStandard((prevState) => ({ ...prevState, event_class }));
  }, []);

  const handleProcessChange = useCallback((process) => {
    setNewStandard((prevState) => ({ ...prevState, process }));
  }, []);

  const handleAircraftTypeChange = useCallback((e) => {
    const { value } = e.target;
    setNewStandard((prevState) => ({ ...prevState, aircraft_type: value }));
  }, []);

  const handleTimeStandardChange = useCallback((e) => {
    const { value } = e.target;
    setNewStandard((prevState) => ({ ...prevState, start: value }));
  }, []);

  const createNewStandard = useCallback(() => {
    const standard = {
      ...newStandard,
      process: newStandard.process.value,
      event_class: newStandard.event_class.value,
      turn_type: newStandard.turn_type.value,
    };

    createStandard(airlineICAO, [standard]).then(() => {
      onStandardCreated(newStandard);
    });
  }, [airlineICAO, onStandardCreated, newStandard]);

  const flatStandards = useMemo(() => {
    if (isEmpty(eventClasses)) return [];

    return standards.flatMap((standard) =>
      Object.entries(standard.aircraft_type).flatMap(([aircraft_type, values]) =>
        values.map((value) => ({
          ...value,
          event_class: find(eventClasses, { id: value.event_class })?.name || '',
          process: find(processes, { id: value.process })?.name || '',
          aircraft_type,
          turn_type: standard.turn_type,
        })),
      ),
    );
  }, [standards, eventClasses, processes]);

  const processOptions = useMemo(() => {
    return processes.map((process) => ({
      value: process.id,
      label: process.name,
    }));
  }, [processes]);

  const eventClassOptions = useMemo(() => {
    return eventClasses.map((eventClass) => ({
      value: eventClass.id,
      label: eventClass.name,
    }));
  }, [eventClasses]);

  useEffect(() => {
    dispatch(fetchEventClasses());
    dispatch(fetchProcesses());
  }, [dispatch]);

  if (isLoading) return <Preloader />;

  return (
    <>
      <div className={s.newStandardContainer}>
        <div className={s.input}>
          <Input
            id="aircraftType"
            value={newStandard.aircraft_type ?? ''}
            onChange={handleAircraftTypeChange}
            label="Aircraft type"
          />
        </div>
        <div className={s.input}>
          <span className={s.label}>Turn type</span>
          <Select
            options={turnTypeOptions}
            value={newStandard.turn_type}
            onChange={handleTurnTypeChange}
            isSearchable={false}
            styles={{
              control: (provided) => ({
                ...provided,
                background: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
            }}
          />
        </div>
        <div className={s.input}>
          <span className={s.label}>Process</span>
          <Select
            options={processOptions}
            value={newStandard.process}
            onChange={handleProcessChange}
            isSearchable={false}
            styles={{
              control: (provided) => ({
                ...provided,
                background: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
            }}
          />
        </div>
        <div className={s.input}>
          <span className={s.label}>Event class</span>
          <Select
            options={eventClassOptions}
            value={newStandard.event_class}
            onChange={handleEventClassChange}
            isSearchable={false}
            styles={{
              control: (provided) => ({
                ...provided,
                background: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
            }}
          />
        </div>
        <div className={s.input}>
          <Input
            id="timeStandard"
            value={newStandard.start ?? ''}
            onChange={handleTimeStandardChange}
            label="Time standard"
          />
        </div>

        <Button className={s.createButton} onClick={createNewStandard}>
          Create
        </Button>
      </div>

      <hr />

      <div className={s.container}>
        {flatStandards.map((standard) => (
          <StandardCard
            key={standard.id}
            standard={standard}
            onStandardSave={handleStandardUpdate}
            onStandardDelete={onStandardDeleteClick}
          />
        ))}
      </div>

      <Confirmation
        isOpen={!!standardToDelete}
        question="Do you want to delete this standard?"
        onConfirm={handleStandardDelete}
        onCancel={onDeletePopupClose}
      />
    </>
  );
};

StandardsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  airlineICAO: PropTypes.string.isRequired,
  standards: PropTypes.arrayOf(
    PropTypes.shape({
      turn_type: PropTypes.string.isRequired,
      aircraft_type: PropTypes.objectOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            event_class: PropTypes.number,
            id: PropTypes.number,
            start: PropTypes.string,
          }),
        ),
      ),
    }),
  ).isRequired,
  onStandardCreated: PropTypes.func.isRequired,
};

export default StandardsList;
