import React, {useEffect, useRef, useState} from 'react';
import Modal from "../../../components/common/Modal/Modal";
import {fetchCameraFrame} from "../../../store/controllers/camera.controllers";
import s from './EventFrameModal.module.scss';
import {defaultFormat} from "../../../utils/helpers";

export const WIDTH = 800;
export const HEIGHT = 500;
export const DPI_WIDTH = WIDTH * 2
export const DPI_HEIGHT = HEIGHT * 2

function DrawRectangle(ctx, x, y, w, h, lineWidth, color, text="", fill=false) {
    ctx.beginPath();
    ctx.rect(x, y, w, h);
    ctx.fillStyle = color;
    if (fill) {
        ctx.fill();
    }
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.font = "15px serif";

    ctx.stroke();
    ctx.fillText(text, x+2, y+h-2);
    ctx.closePath();
}

const NewEventModal = ({isOpen, handleModalClose, airportIATA, event}) => {
    const [frameLoading, setFrameLoading] = useState(false);
    const [frameError, setFrameError] = useState(null);
    const [img, setImage] = useState(null);
    const [size, setSize] = useState(null)
    const bboxRef = useRef(null);

    const canvas = bboxRef?.current
    const context = canvas?.getContext("2d");

    const AlignX = (x) => {
        let newX = (x / size[0] * DPI_WIDTH)
        return newX
    }

    const AlignY = (y) => {
        let newY = (y / size[1] * DPI_HEIGHT);
        return newY
    }

   const DrawBbox = (bbox) => {
       context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        DrawRectangle(
            context, AlignX(bbox[0]),
            AlignY(bbox[1]), AlignX(bbox[2] - bbox[0]),
            AlignY(bbox[3] - bbox[1]), 2, 'rgb(255, 128, 0)', event['display'], false
        );
    }


    useEffect(() => {
        if (canvas) {
            canvas.style.width = WIDTH + 'px'
            canvas.style.height = HEIGHT + 'px'
            canvas.width = DPI_WIDTH
            canvas.height = DPI_HEIGHT
        }
    }, [canvas])

    useEffect(() => {
        const params = {frame_id: `/${event?.frame_id}`, airport: airportIATA,}
        if (isOpen) {
            fetchCameraFrame(params, setFrameLoading, setFrameError).then(({data, size}) => {
                setSize(size)
                setImage(data)
            });
        }

    }, [event, airportIATA, isOpen]);


    useEffect(() => {
        if (img && size && isOpen) {
            DrawBbox(event['bbox'])
        }
    }, [img, isOpen, size])

    return (
        <Modal isOpen={isOpen} onModalClose={handleModalClose} className={s.modal}>
            <button style={{position: 'absolute', right: '10px', top: '10px', background: 'transparent', border: 'none', fontWeight: '600', fontSize: '1.2rem'}} onClick={handleModalClose}>X</button>
            <div style={{textAlign: 'center'}}>
                <h3 style={{fontSize: '1rem', color: 'black', fontWeight: '600'}}>{event?.display}</h3>
                <h3 style={{fontSize: '1rem', color: 'black', fontWeight: '600'}}>{defaultFormat(event?.timestamp)}</h3>
            </div>
            <div style={{width: WIDTH, height: HEIGHT, position: 'relative', margin: '0 auto'}}>
                <canvas ref={bboxRef} style={{position: 'absolute', top: '0', left: 0, zIndex: '2'}}/>
                <img src={img} alt={img} style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: '1'}}/>
            </div>

        </Modal>
    );
};

export default NewEventModal;