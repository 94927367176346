import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'components/ui/Button/Button';
import { TrashActive } from 'assets/img/all-icons';
import Input from 'components/ui/MyInput/MyInput';
import s from './StandardCard.module.scss';

const StandardCard = ({ standard: _standard, onStandardSave, onStandardDelete }) => {
  const [standard, setStandard] = useState(_standard);

  const handleTimeStandardChange = useCallback((e) => {
    const { value } = e.target;

    setStandard((prevState) => ({ ...prevState, start: value }));
  }, []);

  const onSaveClick = useCallback(() => {
    onStandardSave(standard);
  }, [standard, onStandardSave]);

  useEffect(() => {
    setStandard(_standard);
  }, [_standard]);

  return (
    <div className={s.container}>
      <div>
        <div className={s.parameter}>
          <span>Turn type:</span>
          <div className={s.value}>{standard.turn_type}</div>
        </div>
        <div className={s.parameter}>
          <span>Aircraft type:</span>
          <div className={s.value}>{standard.aircraft_type}</div>
        </div>
        <div className={s.parameter}>
          <span>Process:</span>
          <div className={s.value}>{standard.process}</div>
        </div>
        <div className={s.parameter}>
          <span>Event class:</span>
          <div className={s.value}>{standard.event_class}</div>
        </div>
        <div className={s.parameter}>
          <span>Time standard:</span>
          <Input
            id={`time-standard-${standard.id}`}
            value={standard.start}
            onChange={handleTimeStandardChange}
            className={classnames(s.value, s.timeInput)}
          />
        </div>
      </div>

      <hr />

      <div className={s.buttons}>
        <Button onClick={onSaveClick}>Save</Button>
        <Button
          onClick={() => onStandardDelete(standard)}
          icon={<TrashActive width="30px" height="30px" />}
          className={s.deleteButton}
          type="secondary"
        />
      </div>
    </div>
  );
};

StandardCard.propTypes = {
  standard: PropTypes.shape({
    turn_type: PropTypes.string,
    aircraft_type: PropTypes.string,
    event_class: PropTypes.string,
    process: PropTypes.string,
    id: PropTypes.number,
    start: PropTypes.string,
  }).isRequired,
  onStandardSave: PropTypes.func.isRequired,
  onStandardDelete: PropTypes.func.isRequired,
};

export default StandardCard;
