import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import MyInput from 'components/ui/MyInput/MyInput';
import Header from 'components/common/Header/Header';
import MyRadio from 'components/ui/MyRadio/MyRadio';
import Button from 'components/ui/Button/Button';

import {
    CLIENT_MANAGER_ROLE,
    SUPERUSER_ROLE,
} from 'store/constants/user.constants';

const AddAccountUserInfo = ({account, scope, setScope, availableScopes, onChange, onNextClick, onSubmit}) => {
    console.log(availableScopes, '---------availableScopes-------in ---AddAccountUserInfo component on 16 line--')
    const {id: accountId} = useParams();

    const onScopeChange = useCallback((e) => {
            setScope(availableScopes[e.target.name]);
        },
        [availableScopes, setScope],
    );

    const onInputChange = useCallback(({target: {name, value}}) => {onChange({name, value})}, [onChange]);

    return (
        <div className="add-new-account">
            <Header>
                <h2 style={{color: '#383838', flex: 1}}>Account Management</h2>
            </Header>
            <div>
                <div className="add-new-account__container">
                    <h2 className="add-new-account__title">Choose Account profile</h2>
                    <h2 className="add-new-account__label">Scope</h2>
                    <div className="add-new-account__radio-group">
                        {Object.values(availableScopes).map(({value, label}) => {
                            console.log(value, 'VALUE', label, 'LABEL')
                            return (
                            <MyRadio
                                key={value}
                                id={value}
                                name={value}
                                onChange={onScopeChange}
                                checked={scope.value === value}
                            >
                                {label}
                            </MyRadio>
                        )})}
                    </div>
                </div>

                {scope.value === CLIENT_MANAGER_ROLE && (
                    <div className="client-user-type-choice client-input">
                        <span className="title">User type</span>
                        <MyRadio
                            key="Airline"
                            id="airline"
                            name="type"
                            value="airline"
                            onChange={onInputChange}
                            checked={account.type === 'airline'}
                        >
                            Airline
                        </MyRadio>
                        <MyRadio
                            key="Airport"
                            id="airport"
                            name="type"
                            value="airport"
                            onChange={onInputChange}
                            checked={account.type === 'airport'}
                        >
                            Airport
                        </MyRadio>
                    </div>
                )}
                <div className="client-input">
                    <MyInput
                        id="login"
                        value={account.login}
                        name="login"
                        placeholder="Login"
                        onChange={onInputChange}
                    />
                </div>
                <div className="client-input">
                    <MyInput
                        id="username"
                        value={account.name}
                        name="name"
                        placeholder="Username"
                        onChange={onInputChange}
                    />
                </div>
                {scope.value === CLIENT_MANAGER_ROLE && (
                    <div className="client-input">
                        <MyInput
                            id="type_code"
                            value={account.type_code}
                            name="type_code"
                            placeholder={account.type === 'airport' ? 'IATA' : 'ICAO'}
                            onChange={onInputChange}
                        />
                    </div>
                )}
                {!accountId && (
                    <div className="client-input">
                        <MyInput
                            id="password"
                            value={account.password}
                            name="password"
                            placeholder="Password"
                            onChange={onInputChange}
                        />
                    </div>
                )}
                <div
                    className="add-new-account__footer"
                    style={{justifyContent: 'flex-end'}}
                >
                    {scope.value === CLIENT_MANAGER_ROLE ? (
                        <Button onClick={onNextClick}>Next</Button>
                    ) : (
                        <Button onClick={onSubmit}>Save</Button>
                    )}
                </div>
            </div>
        </div>
    );
};

AddAccountUserInfo.propTypes = {
    account: PropTypes.shape({
        name: PropTypes.string,
        login: PropTypes.string,
        type_code: PropTypes.string,
        password: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    scope: PropTypes.shape({
        value: PropTypes.oneOf([CLIENT_MANAGER_ROLE, SUPERUSER_ROLE]),
        label: PropTypes.string,
    }).isRequired,
    setScope: PropTypes.func.isRequired,
    availableScopes: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AddAccountUserInfo;
