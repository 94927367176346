import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import processActions from "../store/actions/process.actions";
import {updateListen, watchTurnaround} from "../store/controllers/process.controllers";
import uiActions from "../store/actions/ui.actions";
import {processViews, routes} from "../store/constants/ui.constants";
import {Preloader} from "./common/Preloader/Preloader";
import Monitoring from "./processes/monitoring/Monitoring";
import Administration from "./processes/administration/Administration";
import {toast} from "react-toastify";
import {format} from "date-fns";


const TestSocketComponent = () => {
    const dispatch = useDispatch();
    const default_airport = useSelector(state => state.user['default_airport']);
    const { isLoading, isConnected, error, filter:{date}, processes } = useSelector(({ processes }) => processes);
    const view = useSelector(({ ui }) => ui.processView);


    useEffect(() => {
        console.log('открыл события ')
        dispatch(uiActions.changePage(routes.TURNAROUND));
        dispatch(processActions.establishConnection(async (socket) => socket.connect('timeline')));

        return () => {
            dispatch(processActions.closeConnection(async (socket) => socket.closeConnection()));
            console.log('ЗАКРЫЛ СОБЫТИЯ')
            dispatch(uiActions.changePage(''));
        }
    }, [])



    useEffect(() => {
        dispatch(processActions.initListener(
            (socket, dispatch, getState) => {
                return watchTurnaround (
                    socket, dispatch, getState,
                    {
                        emit: {date: format(date, 'yyyy-MM-dd'), airport: `${default_airport}`},
                        event: {type: 'turnaround', meta: 'table'}
                    })
            }));
    },[date, default_airport]);


    useEffect(() => {
        if (isConnected) {
            console.log('UPDATE EFFECT')
            dispatch(processActions.sendMessage(
                (socket, dispatch, getState) => updateListen(socket, dispatch, getState, {type: 'turnaround', meta: 'update'})
            ))
        }
    }, [isConnected, processes])

    if (error) toast.error(error);

    if (isLoading) return <Preloader />;

    switch (view) {
        case processViews.MONITORING:
            return <Monitoring />
        case processViews.ADMIN:
            return <Administration />;
        default:
            return null;
    }
};

export default TestSocketComponent;