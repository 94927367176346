import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Search } from 'assets/img/all-icons';

import s from './SearchInput.module.scss';

const SearchInput = ({ value, onChange }) => {
  const handleSearchChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={s.container}>
      <Search width="18px" className={s.icon} />
      <input
        placeholder="Search"
        value={value}
        onChange={handleSearchChange}
        className={s.input}
      />
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchInput;
