import {authUser} from 'store/controllers/user.controllers';
const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS';

const initialState = {
  initialized: false,
  globalError: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZED_SUCCESS:
      return {...state, initialized: true};
    default:
      return state;
  }
};

export const initializeApp = () => (dispatch) => {
  let tokenStorage = null, dataStorage = null
  if (localStorage['token']) {
    tokenStorage = localStorage['token'];
    dataStorage = JSON.parse(localStorage.getItem('user_information'));
  }
  dispatch(authUser({token: tokenStorage, ...dataStorage}))
};

export default appReducer;
