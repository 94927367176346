import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Measure from 'react-measure';

import { ArrowRight } from 'assets/img/all-icons';

import s from './DropdownLeft.module.scss';

const DropdownLeft = ({ title, isOpen, onToggle, styles, children }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(isOpen);
  const [contentHeight, setContentHeight] = useState(0);
  const [containerStyles, setContainerStyles] = useState({
    height: '0',
    overflow: 'hidden',
    transition: 'all 0.2s ease',
  });

  useEffect(() => {
    setContainerStyles((prevState) => ({
      ...prevState,
      height: isDropdownOpen ? `${contentHeight}px` : '0',
    }));
  }, [contentHeight, isDropdownOpen]);

  useEffect(() => {
    setDropdownOpen(isOpen);
  }, [isOpen]);

  const toggleList = useCallback(() => {
    setDropdownOpen((prevState) => !prevState);
    onToggle(!isDropdownOpen);
  }, [isDropdownOpen, onToggle]);

  return (
    <div>
      <div
        id="dropdown"
        className={classnames(s.header, styles.header)}
        onClick={toggleList}
        onKeyPress={toggleList}
        role="button"
        tabIndex={0}
      >
        <ArrowRight
          width="32px"
          className={classnames(s.arrow, { [s.arrow__open]: isDropdownOpen })}
        />
        {title}
      </div>
      <div style={containerStyles} className={s.content}>
        <Measure
          onResize={({ entry: { height } = {} }) =>
            setContentHeight(height || 0)
          }
        >
          {({ measureRef }) => <div ref={measureRef}>{children}</div>}
        </Measure>
      </div>
    </div>
  );
};

DropdownLeft.propTypes = {
  title: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  styles: PropTypes.shape({ header: PropTypes.string }),
  children: PropTypes.node.isRequired,
};

DropdownLeft.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  styles: {},
};

export default DropdownLeft;
