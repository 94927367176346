import {
  LOGS_RESET_RECORDING,
  FETCH_LOGS_SUCCESS,
  LOGS_DECREASE_TIMER,
  LOGS_START_RECORDING,
  LOGS_STOP_RECORDING,
} from 'store/constants/logs.constants';

const initialState = {
  isRecording: true,
  secondsToRefetch: 10,
  logs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGS_START_RECORDING:
      return { ...state, isRecording: true };
    case LOGS_STOP_RECORDING:
      return { ...state, isRecording: false };
    case LOGS_DECREASE_TIMER:
      return { ...state, secondsToRefetch: state.secondsToRefetch - 1 };
    case LOGS_RESET_RECORDING:
      return { ...state, secondsToRefetch: 10 };
    case FETCH_LOGS_SUCCESS:
      return { ...state, logs: state.logs + action.payload };
    default:
      return state;
  }
};
