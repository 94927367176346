import React, { useState } from 'react';

import ProcessList from 'components/turnaroundSettings/processList/ProcessList';
import Header from 'components/common/Header/Header';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import EventList from 'components/turnaroundSettings/eventList/EventList';
import EventClassList from 'components/turnaroundSettings/eventClassList/EventClassList';
import EventRelationList from 'components/turnaroundSettings/eventRelationList/EventRelationList';
import SafetyAlertsList from 'components/turnaroundSettings/safetyAlertsList/SafetyAlertsList';
import s from './TurnaroundSettings.module.scss';

const TurnaroundSettings = () => {
  const [selectedPane, setSelectedPane] = useState(0);

  return (
    <div className={s.container}>
      <Header>
        <h2 style={{ color: '#383838', flex: 1 }}>Turnaround Settings</h2>
      </Header>

      <div className={s.processList}>
        <Tabs selectedIndex={selectedPane} onSelect={(index) => setSelectedPane(index)}>
          <TabList className={s.tabList}>
            <Tab key="processes" className={s.tab} selectedClassName={s.selectedTab}>
              Processes
            </Tab>
            <Tab key="eventClassNames" className={s.tab} selectedClassName={s.selectedTab}>
              Event class names
            </Tab>
            <Tab key="eventNames" className={s.tab} selectedClassName={s.selectedTab}>
              Event names
            </Tab>
            <Tab key="eventRelations" className={s.tab} selectedClassName={s.selectedTab}>
              Events
            </Tab>
            <Tab key="safetyAlerts" className={s.tab} selectedClassName={s.selectedTab}>
              Safety Alerts
            </Tab>
          </TabList>

          <TabPanel key="processes">
            <ProcessList />
          </TabPanel>
          <TabPanel key="eventClassNames">
            <EventClassList />
          </TabPanel>
          <TabPanel key="eventNames">
            <EventList />
          </TabPanel>
          <TabPanel key="eventRelations">
            <EventRelationList />
          </TabPanel>
          <TabPanel key="safetyAlerts">
            <SafetyAlertsList />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default TurnaroundSettings;
