import React from 'react';
import classnames from 'classnames';
import { SmallArrowRight } from 'assets/img/all-icons';
import { format } from 'date-fns';

import s from './TurnaroundSwitch.module.scss';

const TurnaroundSwitch = ({ arrival, departure }) => {
  return (
    <div className={s.turnaroundSwitch}>
      <button type="button" className={classnames(s.switch, s.switchLeft)}>
        <SmallArrowRight width="6px" height="10px" />
      </button>
      <div className={s.currentTurnaround}>
        <div className={s.timestamp}>
          <div className={s.label}>Arrival</div>
          <div className={s.value}>{format(arrival, 'dd.MM.yyyy HH:mm')}</div>
        </div>
        <div className={s.timestamp}>
          <div className={s.label}>Departure</div>
          <div className={s.value}>{format(departure, 'dd.MM.yyyy HH:mm')}</div>
        </div>
      </div>
      <button type="button" className={classnames(s.switch)}>
        <SmallArrowRight width="6px" height="10px" />
      </button>
    </div>
  );
};

export default TurnaroundSwitch;
