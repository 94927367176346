import React from 'react';
import { Apply as ApplyIcon, CloseRed as DeleteIcon } from 'assets/img/all-icons';
import MySelect from 'components/ui/MySelect/MySelect';

import s from './GateSelect.module.scss';

const GateSelect = ({ selectedGate, gates, mode, onGateChange, onDelete, onSubmit }) => {
  return (
    <div className={s.container}>
      {mode !== 'edit-gate' ? (
        <>
          <MySelect
            name="gate"
            options={gates.map((gate) => gate.name)}
            value={selectedGate?.name}
            onChange={onGateChange}
            className={s.gateSelect}
          />

          <button type="button" className={s.applyButton} onClick={onSubmit}>
            <ApplyIcon width="14px" />
          </button>
        </>
      ) : (
        <div>{selectedGate?.name}</div>
      )}

      <button type="button" onClick={onDelete} className={s.deleteButton}>
        <DeleteIcon width="28px" />
      </button>
    </div>
  );
};

export default GateSelect;
