import io from "socket.io-client";
import config from 'config/config.json';

export default class SocketAPINew {
    connect(path) {
        this.socket = io(`${config['HUB_SOCKETS_URL']}/${path}`, {
            forceNew: true,
            reconnection: true,
            extraHeaders: { authorization: localStorage.getItem('token')}
        })
        return new Promise((resolve, reject) => {
            this.socket.on('populate', resolve)
            this.socket.on('connect_error', reject)
        })
    }

    error(event, cb) {
        this.socket.on(event, cb)
    }

    closeConnection() {
        this.socket.close()
    }

    clearListen(event) {
        this.socket.off(event)
    }

    update(cb) {
        this.socket.on('update', cb)
    }

    listen (cb) {
        this.socket.on('populate', cb)
    }

    send(data) {
        this.socket.emit('request', data)
    }
}