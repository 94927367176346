import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {createTerminal, deleteTerminal, updateTerminal} from "../../store/controllers/airport.controllers";
import SmoothDropdown from "../../components/common/SmoothDropdown/SmoothDropdown";
import classnames from "classnames";
import Can from "../../services/Can";
import {EDIT} from "../../services/ability";
import CloseIcon from "../../assets/img/close-red.svg";
import AccordeonInnerItem from "./AccordeonInnerItem";
import {Plus} from "../../assets/img/all-icons";

const AccordionItem = ({addNewGate, shouldAutoFocusGate, shouldAutoFocusTerminal, setShouldAutoFocusTerminal, setShouldAutoFocusGate, terminal, iata, allowedToManage}) => {
    const innerRef = useRef();
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(false);
    const [stateTerminal, setStateTerminal] = useState(terminal);

    console.log(stateTerminal, '----------stateTerminal in -----------AccordionItem component on 16 line------')

    const handleNameChange = useCallback(({target: {value}}) => {
        setStateTerminal((prevState) => ({...prevState, name: value}));
    }, []);


    const unfreezeFieldInput = useCallback((e) => {
        e.stopPropagation();
        e.target.focus();
    }, []);

    const handleDropdownClick = useCallback(() => {
        setActive((prevState) => !prevState);
    }, []);

    const handleTerminalDelete = useCallback(
        // (e) => {
        //     e.stopPropagation();
        //     dispatch(deleteTerminal(stateTerminal.id, iata));
        // },
        // [dispatch, iata, stateTerminal.id],
        (e) => {
            e.stopPropagation();
            dispatch(deleteTerminal(stateTerminal.name, iata));
        },
        [dispatch, iata, stateTerminal.name],
    );

    const onBlurHandler = useCallback(() => {
        if (stateTerminal.new) {
            dispatch(createTerminal({name: stateTerminal.name}, iata));
        } else {
            dispatch(
                updateTerminal(stateTerminal.name, {name: stateTerminal.name}, iata),
            );
        }
        setShouldAutoFocusTerminal(false);
    }, [stateTerminal.new, stateTerminal.name, stateTerminal.id, setShouldAutoFocusTerminal, dispatch, iata]);

    const onKeyDownHandler = useCallback((e) => {
        if (e.keyCode === 13) e.target.blur();
    }, []);

    useEffect(() => {
        setStateTerminal(terminal);
    }, [terminal]);

    return (
        <SmoothDropdown
            title={
                <div>
          <span
              className={classnames({'accordion-item-title_active': isActive})}
          >
            Terminal
          </span>
                    <input readOnly={!allowedToManage} disabled={!allowedToManage} size={stateTerminal.name.length || 1}
                           onBlur={onBlurHandler} value={stateTerminal.name} onChange={handleNameChange}
                           onKeyDown={onKeyDownHandler} className={classnames('accordion-item-edit-field', {
                        'accordion-item-edit-field__active': isActive,
                    })} onClick={unfreezeFieldInput} autoFocus={shouldAutoFocusTerminal}
                    />
                </div>
            }
            rightContent={
                <Can I={EDIT} this={{type: 'airports', iata}}>
                    <div className="accordion-item-close-container">
                        <button type="button" onClick={handleTerminalDelete}>
                            <img src={CloseIcon} alt="Close"/>
                        </button>
                    </div>
                </Can>
            }
            isOpen={isActive}
            onToggleDropdown={handleDropdownClick}
            styles={{
                dropdown: 'accordion-item',
                active: 'accordion-item_active',
                title: 'accordion-item__title',
                content: 'accordion-inner-item-wrap',
            }}
            // numberOfChildren={stateTerminal.gates.length}
            numberOfChildren={stateTerminal.length}
            innerRef={innerRef}
        >
            <div>
                {/*{stateTerminal.gates.length > 0 ? (*/}
                {/*  stateTerminal.gates*/}
                {stateTerminal.length > 0 ? (
                    stateTerminal.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}))
                        .map((child) => (
                            <AccordeonInnerItem
                                key={child.id}
                                ref={innerRef}
                                iata={iata}
                                shouldAutoFocusGate={shouldAutoFocusGate}
                                setShouldAutoFocusGate={setShouldAutoFocusGate}
                                terminalId={terminal.id}
                                gate={child}
                                allowedToManage={allowedToManage}
                            />
                        ))
                ) : (
                    <h6 style={{margin: '20px', marginLeft: 0}}>
                        This terminal currently has no gates.
                    </h6>
                )}

                <Can I={EDIT} this={{type: 'airports', iata}}>
                    <div className="accordion-add">
                        <button
                            type="button"
                            className="accordion-add-button"
                            onClick={() => addNewGate(terminal.name)}
                        >
                            <Plus width="36px"/>
                            <span className="accordion-add__title">Add gate</span>
                        </button>
                    </div>
                </Can>
            </div>
        </SmoothDropdown>
    );
};

export default AccordionItem;