import {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IntellActAPI} from "../services/api/api";
import userActions from "../store/actions/user.actions";

const AxiosInterceptor = ({children}) => {
    let isFetchingToken = false
    const dispatch = useDispatch();

    const isAuthorized = useSelector(state => state.user).isAuthorized

    useLayoutEffect(() => {
        const requestInterceptor = IntellActAPI.interceptors.request.use(config => {
                if (!config.headers['Authorization']) {
                    config.headers.Authorization = `${localStorage.getItem('token')}`
                }
                return config
            }, (error) => Promise.reject(error))

        const responseInterceptor = IntellActAPI.interceptors.response.use(
            response => response,
            async (error) => {
                const {response: {status}} = error
                if (status === 401 || status === 403) {
                   if (!isFetchingToken) {
                       isFetchingToken = true
                       dispatch(userActions.checkError({status, statusText: 'Unauthorized'}))
                       return Promise.reject(error)
                   }
                }
                return Promise.reject(error)
            }
        )
        return () => {
            IntellActAPI.interceptors.response.eject(responseInterceptor);
            IntellActAPI.interceptors.request.eject(requestInterceptor);
        }
    }, [isAuthorized])

    return children
};

export default AxiosInterceptor;