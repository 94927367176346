import {
  AIRPORT_SELECT,
  FETCH_AIRPORT_SUCCESS,
  FETCH_AIRPORT_LOADING,
  CREATE_TERMINAL,
  UPDATE_TERMINAL,
  DELETE_TERMINAL,
  CREATE_GATE,
  UPDATE_GATE,
  DELETE_GATE,
  AIRPORT_UPDATE,
} from 'store/constants/airport.constants';

const initialState = {
  selectedAirport: {},
  airportData: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AIRPORT_SELECT:
      return { ...state, selectedAirport: action.payload };
    case AIRPORT_UPDATE:
      return {
        ...state,
        selectedAirport: { ...state.selectedAirport, ...action.payload },
      };
    case FETCH_AIRPORT_LOADING:
      return { ...state, isLoading: action.payload };
    case FETCH_AIRPORT_SUCCESS:
      return { ...state, airportData: action.payload };
    case CREATE_TERMINAL:
      return {
        ...state,
        airportData: {
          ...state.airportData,
          terminals: state.airportData.terminals.concat(action.payload),
        },
      };
    case UPDATE_TERMINAL:
      return {
        ...state,
        airportData: {
          ...state.airportData,
          terminals: state.airportData.terminals.map((terminal) =>
            terminal.id === action.payload.id ? action.payload : terminal,
          ),
        },
      };
    case DELETE_TERMINAL:
      return {
        ...state,
        airportData: {
          ...state.airportData,
          terminals: state.airportData.terminals.filter(
            ({ id }) => id !== action.payload,
          ),
        },
      };
    case CREATE_GATE:
      return {
        ...state,
        airportData: {
          ...state.airportData,
          terminals: state.airportData.terminals.map((terminal) =>
            terminal.id !== action.payload.terminal
              ? terminal
              : { ...terminal, gates: terminal.gates.concat(action.payload) },
          ),
        },
      };
    case UPDATE_GATE:
      return {
        ...state,
        airportData: {
          ...state.airportData,
          terminals: state.airportData.terminals.map((terminal) =>
            terminal.id !== action.payload.terminal
              ? terminal
              : {
                  ...terminal,
                  gates: terminal.gates.map((gate) =>
                    gate.id !== action.payload.id ? gate : action.payload,
                  ),
                },
          ),
        },
      };
    case DELETE_GATE:
      return {
        ...state,
        airportData: {
          ...state.airportData,
          terminals: state.airportData.terminals.map((terminal) =>
            terminal.id !== action.payload.terminal
              ? terminal
              : {
                  ...terminal,
                  gates: terminal.gates.filter(
                    ({ id }) => id !== action.payload.id,
                  ),
                },
          ),
        },
      };
    default:
      return state;
  }
};
