import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import useNow from 'utils/useNow';
import s from './CriticalPath.module.scss';
import {transToMilliseconds} from "../../../../utils/helpers";

const COLORS = {DEFAULT: 'default', YELLOW: 'yellow', GREEN: 'green', RED: 'red'}

const CriticalPath = ({processes}) => {
    const now = useNow();
    const [categoryWidth, setCategoryWidth] = useState(0);
    const [statuses, setStatuses] = useState({});

    useEffect(() => {
        if (processes.length) {
            setCategoryWidth(100 / processes.length);
        }
    }, [processes]);


    const checkWhichColorUse = useCallback((eventObj)  => {
        const {aft, sst, sft, ast} = eventObj;

        if (!sst && !sft && !aft) return COLORS.DEFAULT

        if (now < sft && ast > sst) return COLORS.YELLOW

        if ((ast < sst && now < sft) || (ast < sst && aft < sft) || (ast > sst && aft < sft)) return COLORS.GREEN

        if (aft > sft || now > sst) return COLORS.RED
    }, [])

    const calcPercent = useMemo(() => {
        return (process) => {
            let progress = 0
            const DefaultProgress = 1;
            const {ast, sft, aft} = process;

            if (aft) {
                progress = DefaultProgress
            }

            if (ast && !aft) {
                progress = (now - transToMilliseconds(ast)) / (transToMilliseconds(sft) - transToMilliseconds(ast))
            }

            return Math.min(progress, DefaultProgress)
        }

    }, [now])

    const printPercentAndColor = useCallback(() => {
        return processes?.reduce((acc, process) => {
            acc[process['display']] = {
                progress: calcPercent(process),
                color: checkWhichColorUse(process)
            }
            return acc
        }, {})
    }, [calcPercent])

    useEffect(() => {
        setStatuses(printPercentAndColor)
    }, [printPercentAndColor, checkWhichColorUse])

    useEffect(() => {
        return () => {
            console.log('return from CRITICAL_PATH_COMPONENT')
            setCategoryWidth(0)
            setStatuses({})
        }
    }, [])

    return (
        <div className={s.container}>
            <div className={s.defaultLine}/>
            {processes?.map((process, i) => (
                <div key={process['display']}>
                    {statuses[process['display']] && (
                        <div style={{
                            width: `calc((${categoryWidth}% - 10px) * ${statuses[process['display']].progress}`,
                            left: `calc(${i * categoryWidth}%)`
                        }} className={classnames(s.progress, s[statuses[process['display']].color], {
                            [s.delayLine]: statuses[process['display']]?.delay?.reason,
                        })}/>)}
                    <div key={process.id}
                         className={classnames(s.categoryCircle, s[statuses[process['display']]?.color], {
                             [s.done]: statuses[process['display']]?.progress === 1,
                             [s.delayCircle]: statuses[process['display']]?.delay?.reason,
                         })}
                         style={{left: `calc(${(i + 1) * categoryWidth}% - 20px)`}}
                    >
                    </div>
                    <div className={s.categoryName}
                         style={{left: `calc(${(i + 1) * categoryWidth - categoryWidth / 2}% - 10px)`}}>
                        {process['display']}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CriticalPath;
