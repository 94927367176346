import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Critical } from 'assets/img/all-icons';

import { find, noop } from 'lodash';
import s from './ProcessPermission.module.scss';

const ProcessPermission = ({ eventClass, criticalEventClasses, onCriticalEventClassChange }) => {
  const isCritical = find(criticalEventClasses, { id: eventClass.id });

  return (
    <div>
      <div className={classNames(s.container)}>
        <div className={s.event}>{eventClass.name}</div>
        <div
          tabIndex={0}
          role="button"
          className={classNames(s.critical, {
            [s.checked]: isCritical,
          })}
          onClick={() => onCriticalEventClassChange(eventClass, !isCritical)}
          onKeyPress={noop}
        >
          {isCritical && <Critical />}
        </div>
      </div>
    </div>
  );
};

ProcessPermission.propTypes = {
  eventClass: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  criticalEventClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCriticalEventClassChange: PropTypes.func.isRequired,
};

export default ProcessPermission;
