import { IntellActAPI } from './api';

export default {
  getCountry(alpha2) {
    return IntellActAPI.get(`/country/${alpha2}/`);
  },
  async getCountryByIata(type, iata) {
    const dataCode = await IntellActAPI.get(`/${type}/${iata}/`);
    const alpha2 = dataCode.data.country;
    const dataName = await IntellActAPI.get(`/country/${alpha2}/`);
    const { name } = dataName.data;
    return { code: alpha2, name };
  },
};
