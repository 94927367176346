import { FETCH_ALERTS_SUCCESS } from 'store/constants/alerts.constants';

const initialState = {
  alerts: [],
  isLoading: true,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALERTS_SUCCESS:
      return { ...state, alerts: action.payload };
    default:
      return state;
  }
};
