import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import airportActions from 'store/actions/airport.actions';
import {createCamera, importCameraCSVData, updateCamera} from 'store/controllers/camera.controllers';
import Can from 'services/Can';
import { EDIT, FILTER } from 'services/ability';

import AirportLabels from 'components/ui/AirportLabels/AirportLabels';
import AirportFilter from 'components/ui/AirportFilter/AirportFilter';
import { Import } from 'assets/img/all-icons';
import Header from 'components/common/Header/Header';
import Button from 'components/ui/Button/Button';

import CameraCard from './CameraCard/CameraCard';
import CameraModal from './CameraModal/CameraModal';
import { positions, views } from './Data';
import { v4 as uuidv4 } from 'uuid';

import './Cameras.scss';

const Cameras = () => {
  const dispatch = useDispatch();

  const [isAddModalOpen, setAddModalOpen] = useState(false); // for the open camera modal
  const [newCamera, setNewCamera] = useState({position: positions[0], view: views[positions[0]][0], gates: []});
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');

  const cameras = useSelector(({ cameras: state }) => state.cameras, shallowEqual);

  const { selectedAirport } = useSelector(({ airport }) => airport, shallowEqual);

  const handleAddCameraClick = useCallback(() => {
    setAddModalOpen(true);
  }, []);

  // const handleCameraSave = useCallback(
  //   (camera) => {
  //     dispatch(
  //       createCamera(
  //         {
  //           url: camera.url,
  //           position: camera.position,
  //           view: camera.view,
  //           mode: 'active',
  //           gates: camera.gates.map(({ gate, visible_cones, clearance_points, x, y }) => ({gate, visible_cones, clearance_points, x, y,})),
  //         },
  //           selectedAirport.iata
  //       ),
  //     );
  //
  //     setAddModalOpen(false);
  //     setNewCamera({
  //       position: positions[0],
  //       view: views[positions[0]][0],
  //       gates: [],
  //     });
  //   },
  //   [dispatch, selectedAirport.iata]
  // );


    // url: camera.url,
    //     position: camera.position,
    //     view: camera.view,
    //     mode: 'active',
    //     gates: camera.gates.map(({ gate, visible_cones, clearance_points, x, y }) => ({gate, visible_cones, clearance_points, x, y,})),
    const handleCameraSave = useCallback(
        (camera) => {
            dispatch(updateCamera(camera['cctv_id'], camera, selectedAirport.iata));

            setAddModalOpen(false);
            // setNewCamera({
            //     position: positions[0],
            //     view: views[positions[0]][0],
            //     gates: [],
            // });
        },
        [dispatch, selectedAirport.iata]
    );

  const onFilterClick = useCallback(() => {
    setFilterOpen((prevState) => !prevState);
  }, []);

  const handleModalClose = useCallback(() => {
    setAddModalOpen(false);
    setNewCamera({});
  }, []);

  const closeFilter = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const onAirportClick = useCallback((data) => dispatch(airportActions.selectAirport(data)), [dispatch],);

  const onQueryInputChange = useCallback((e) => setFilterQuery(e.target.value), []);

  const handleCameraCSVUpload = useCallback(
    (e) => {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('airport', selectedAirport.iata);

      dispatch(importCameraCSVData(formData));
    },
    [dispatch, selectedAirport.iata],
  );

  const onImportClick = useCallback(() => document.getElementById('importInput').click(), []);

  return (
    <>
      <nav className="page-header page-header_border_bottom">
        <Header>
          <div className="header-info">
            <AirportLabels data={selectedAirport} />

            <div className="header-info-buttons">
              <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
                  <Button icon={<Import width="17px" />} type="secondary" onClick={onImportClick}>
                  Import CSV
                </Button>
                <input id="importInput" type="file" accept=".csv" multiple={false} onChange={handleCameraCSVUpload} hidden/>
              </Can>
              <Can I={FILTER} a="airports">
                <div className="filter">
                  <AirportFilter isOpen={isFilterOpen} onClick={onFilterClick} query={filterQuery}
                    onQueryChange={onQueryInputChange} closeFilter={closeFilter}
                    selectedItem={selectedAirport} onAirportClick={onAirportClick}/>
                </div>
              </Can>
            </div>
          </div>
        </Header>
      </nav>
      <div className="camera-row camera-row_first">
            <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
          <button type="button" onClick={handleAddCameraClick} className="camera-add-card">
            <p className="text">+ add new camera</p>
          {/*This button open modal form for create some camera*/}
          </button>
        </Can>

        {cameras.map((camera) => <CameraCard key={uuidv4()} camera={camera} />)}
      </div>

        <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
        <CameraModal
          isOpen={isAddModalOpen}
          handleModalClose={handleModalClose}
          handleCameraSave={handleCameraSave}
          camera={newCamera}
        />
      </Can>
    </>
  );
};

export default Cameras;
