import {
  FETCH_CAMERAS_ERROR,
  FETCH_CAMERAS_LOADING,
  FETCH_CAMERAS_SUCCESS,
  CHANGE_PAGE,
  UPDATE_CAMERA,
  CREATE_CAMERA,
  DELETE_CAMERA,
} from 'store/constants/camera.constants';

const initialState = {
  cameras: [],
  isLoading: true,
  error: '',
  page: 1,
  totalPages: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMERAS_LOADING:
      return { ...state, isLoading: action.payload };
    case FETCH_CAMERAS_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case FETCH_CAMERAS_SUCCESS:
      return {...state, ...action.payload, isLoading: false, error: '',};
    case UPDATE_CAMERA:
      return {
        ...state,
        cameras: state.cameras.map((camera) =>
          camera['cctv_id'] === action.payload.id ? { ...camera, ...action.payload } : camera,
        ),
      };
    case CREATE_CAMERA:
      return { ...state, cameras: state.cameras.concat(action.payload) };
    case DELETE_CAMERA:
      return {
        ...state,
        cameras: state.cameras.filter(({cctv_id}) => cctv_id !== action.payload),
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};
