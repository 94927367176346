import React, {useMemo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Filter from 'components/ui/Filter/Filter';
import accountsActions from 'store/actions/accounts.actions';

const AccountsFilter = () => {
    const dispatch = useDispatch();
    const {filter} = useSelector((state) => state.accounts);

    const updateFilter = useCallback(
        (newFilter) => {

            dispatch(accountsActions.updateFilters(newFilter));
        },
        [dispatch],
    );

    const removeFilter = useCallback((filterType, filterId) => {
            dispatch(accountsActions.removeFilter(filterType, filterId));
        },
        [dispatch],
    );

    const optionEntities = useMemo(
        () => [
            {
                id: 1,
                name: 'User type',
                icon: 'UserType',
                alias: 'userType',
                type: 'select',
                choices: [{label: 'Super user', value: true}, {label: 'Client manager', value: false}],
                async resolver() {
                    return [];
                },
            },
            {
                id: 2,
                name: 'Client type',
                icon: 'ClientType',
                alias: 'clientType',
                type: 'select',
                choices: [
                    {label: 'Airline', value: 'airline'},
                    {label: 'Airport', value: 'airport'},
                ],
                async resolver() {
                    return [];
                },
            },
            {
                id: 3,
                name: 'Name',
                icon: 'UserName',
                alias: 'name',
                type: 'input',
                async resolver() {
                    return [];
                },
            },
        ],
        [],
    );

    const flatFilters = useMemo(() => {
        return Object.values(filter).flat();
    }, [filter]);

    return (
        <div style={{width: '100%'}}>
            <Filter
                onNewFilter={updateFilter}
                onFilterRemove={removeFilter}
                options={optionEntities}
                filters={flatFilters}
            />
        </div>
    );
};

export default AccountsFilter;
