import {
    GET_COOKIE_DATA,
    SET_AUTH_USER_DATA,
    ADD_TOKEN_TO_STORE,
    GET_INITIAL_STATE, LOGOUT, ERROR_FETCH, CHANGE_DEF_AIRPORT
} from 'store/constants/user.constants';
import {toast} from "react-toastify";

export default {
    setAuthUserData(data, isAuth) {
        return {
            type: SET_AUTH_USER_DATA,
            payload: {isLoading: false, isAuthorized: isAuth, ...data},
        };
    },

    getCookieData(cookie) {
        return {
            type: GET_COOKIE_DATA,
            payload: cookie
        }
    },

    addTokenToStore(token) {
        return {
            type: ADD_TOKEN_TO_STORE,
            payload: token
        }
    },

    getInitialState() {
        console.log('TUT -----------getInitialState-----------')
        return {
            type: GET_INITIAL_STATE,
        }
    },

    logout() {
        // toast.error(`CHECK___, please re-login`);
        return {type: LOGOUT}
    },

    checkError(error) {
        return {
            type: ERROR_FETCH,
            payload: error
        }
    },

    changeDefAirport(airport){
        return {
            type: CHANGE_DEF_AIRPORT,
            payload: airport
        }
    }
}
;
