import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Cameras from 'components/cameras/Cameras';
import Paginator from 'components/common/Paginator/Paginator';
import { Preloader } from 'components/common/Preloader/Preloader';

import uiActions from 'store/actions/ui.actions';
import { routes } from 'store/constants/ui.constants';
import { fetchCamerasByAirport } from 'store/controllers/camera.controllers';
import cameraActions from 'store/actions/camera.actions';

import s from './Cameras.module.scss';

const CamerasPage = () => {
  const dispatch = useDispatch();
  const { isLoading, page, totalPages } = useSelector(({ cameras }) => cameras, shallowEqual);
  const { iata: airportIATA } = useSelector((state) => state.airport.selectedAirport, shallowEqual);

  const changePage = useCallback(
    (p) => {
      dispatch(cameraActions.changePage(p));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(uiActions.changePage(routes.CAMERAS));
    return () => dispatch(uiActions.changePage(''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCamerasByAirport(airportIATA, page));
  }, [page, dispatch, airportIATA]);

  if (isLoading) return <Preloader />;

  return (
    <div className={s.container}>
      <Cameras/>
      <div className={s.paginator}>
        <Paginator totalPages={totalPages} currentPage={page} onPageChanged={changePage} />
      </div>
    </div>
  );
};

export default CamerasPage;
