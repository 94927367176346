import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { SmallArrowRight } from 'assets/img/all-icons';

import Button from 'components/ui/Button/Button';
import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import { find, noop } from 'lodash';
import s from './GateSwitch.module.scss';

const GateSwitch = ({ selectedGate, gateList, onFilterSelect }) => {
  const [isFilterOpen, setFilterOpen] = useState(false);

  const handleTriggerClick = useCallback(() => {
    setFilterOpen((prevState) => !prevState);
  }, []);

  const handleGateClick = useCallback(
    (e) => {
      const { gate } = e.currentTarget.dataset;
      onFilterSelect(find(gateList, { name: gate }));
      setFilterOpen(false);
    },
    [gateList, onFilterSelect],
  );

  const closeFilterContainer = useCallback(() => {
    setFilterOpen(false);
  }, []);

  return (
    <div className={s.gateSwitch}>
      <button type="button" className={classnames(s.switch, s.switchLeft)}>
        <SmallArrowRight width="6px" height="10px" />
      </button>
      <ClickAwayListener onClick={closeFilterContainer}>
        <div className={s.currentGate}>
          <Button type="secondary" onClick={handleTriggerClick}>
            Gate: {selectedGate?.name}
          </Button>
        </div>

        {isFilterOpen && (
          <div className={s.filterContainer}>
            {gateList.map((gate) => (
              <div
                key={gate.name}
                role="button"
                tabIndex={0}
                onKeyPress={noop}
                className={classnames(s.filter, { [s.bold]: gate.name === selectedGate.name })}
                onClick={handleGateClick}
                data-gate={gate.name}
              >
                {gate.name}
              </div>
            ))}
          </div>
        )}
      </ClickAwayListener>
      <button type="button" className={classnames(s.switch)}>
        <SmallArrowRight width="6px" height="10px" />
      </button>
    </div>
  );
};

export default GateSwitch;
