import { IntellActAPI } from './api';

export default {
  getAirlines({limit}) {
    return IntellActAPI.get('/airline', { params: {limit }} );
  },
  getAirline(iata) {
    return IntellActAPI.get(`/airline/${iata}/`);
  },
};
