import { isFunction } from 'lodash';
import { toast } from 'react-toastify';
import userActions from "./actions/user.actions";

export const defaultError = 'Something wrong happened. Please, try again';

export const checkSuccessfulStatus = (status) => status === 200 || status === 201;

/**
 * Handles errors from the response
 * @param   {Function} func         Request function to be called
 * @param   {Function} [onSuccess]  Callback that is called with response data
 * @returns {any}                   Response data or `null` in case of error
 */
export const withResponseHandler = (func, onSuccess) => {
  if (!isFunction(func)) return func;

  return async function handler(...args) {
    try {
      const { data, status } = await func.apply(this, args);
      if (!checkSuccessfulStatus(status)) {
        throw new Error(defaultError);
      }

      if (isFunction(onSuccess)) {
        onSuccess(data);
      }

      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.err || error.message;
      toast.error(errorMessage);
    }

    return null;
  };
};

/**
 * Usage:
 * promise: (socket) => any
 * type: 'socket'
 * types: [REQUEST, SUCCESS, FAILURE]
 */
export const socketMiddleware = (socket) => ({ dispatch, getState }) => (next) => (action) => {
  if (typeof action === 'function') {
      return action(dispatch, getState);
  }

  const { promise, type, types, ...rest } = action;
  if (type !== 'socket' || !promise) {
    return next(action);
  }

  const [REQUEST, SUCCESS, FAILURE] = types;
  if (REQUEST) next({ ...rest, type: REQUEST });

  return promise(socket, dispatch, getState)
    .then((result) => SUCCESS && next({ ...rest, payload: result, type: SUCCESS }))
    .catch((err) => {
      console.error(err['message'], 'ERROR') || (FAILURE && next({...rest, payload: err, type: FAILURE,}))
      if (err['message'] === 'Unauthorized') {
          dispatch(userActions.checkError({status: 401, statusText: err['message']}))
      }
    });
};
