/* eslint-disable import/no-anonymous-default-export */
import {
    ESTABLISH_CONNECTION_ERROR,
    ESTABLISH_CONNECTION_SUCCESS,
    CLOSE_CONNECTION_SUCCESS,
    FETCH_PROCESSES_REQUEST,
    FETCH_PROCESSES_ERROR,
    FETCH_PROCESSES_SUCCESS,
    CHANGE_DATES,
    UPDATE_TURNAROUND_FILTERS,
    REMOVE_TURNAROUND_FILTER,
    SET_PAGINATE,
    CHANGE_FLIGHTS_PAGE,
    UPDATE_PROCESSES,
    CLEAR_ERROR_STATE,
    CHECK_IS_CONNECTION,
    UPDATE_MAIN_DATA,
    UPDATE_TURN_DATA,
    CLOSE_TEST_CONNECTION,
    ESTABLISH_CONNECTION_PROCESSES,
} from 'store/constants/process.constants';

const changeIsConnection = connection => {
    return {
        type: CHECK_IS_CONNECTION,
        payload: connection
    }
}

const establishConnection = promise => {
    return {
        type: 'socket',
        types: ['', ESTABLISH_CONNECTION_SUCCESS, ESTABLISH_CONNECTION_PROCESSES],
        promise,
    };
}

const closeConnection = promise => {
    return {
        type: 'socket',
        types: ['', CLOSE_CONNECTION_SUCCESS, ''],
        promise,
    };
}

const initListener = promise => {
    return {
        type: 'socket',
        types: [FETCH_PROCESSES_REQUEST, '', FETCH_PROCESSES_ERROR],
        promise,
    };
}

const fetchProcessesRequest = () => {
    return {type: FETCH_PROCESSES_REQUEST}
}

const sendMessage = promise => {
    return {
        type: 'socket',
        types: ['', '', ''],
        promise,
    };
}

const fetchProcessesSuccess = flights => {
    return {type: FETCH_PROCESSES_SUCCESS, payload: flights};
}

const updateProcesses = flights => {
    return {type: UPDATE_PROCESSES, payload: flights};
}

const changeDates = dates => {
    const nowTime = new Date();
    return {type: CHANGE_DATES, payload: !dates ? nowTime : dates};
}

const updateFilters = filter => {
    return {type: UPDATE_TURNAROUND_FILTERS, payload: filter};
}

const removeFilter = (filterType, filterId) => {
    return {
        type: REMOVE_TURNAROUND_FILTER,
        payload: {type: filterType, id: filterId},
    };
}

const setPaginate = paginate => {
    return {
        type: SET_PAGINATE,
        payload: paginate,
    };
}

const changeFlightsPage = page => {
    return {
        type: CHANGE_FLIGHTS_PAGE,
        payload: page,
    };
}


const updateTurnData = promise => {
    return {
        type: 'socket',
        types: [''],
        promise,
    }
}

const updateSetData = flights => ({
    type: UPDATE_MAIN_DATA,
    payload: flights
})

const updateSetTurnData = flights => ({
    type: UPDATE_TURN_DATA,
    payload: flights
})

const closeTestConnection = (promise) => ({
    type: 'socket',
    types: ['', CLOSE_TEST_CONNECTION, ''],
    promise
})

export default {
    establishConnection, closeConnection, initListener, fetchProcessesRequest,
    sendMessage, fetchProcessesSuccess, updateProcesses, changeDates, updateFilters,
    removeFilter, setPaginate, changeFlightsPage,

    changeIsConnection,



    updateTurnData,
    updateSetData,
    updateSetTurnData,

    closeTestConnection
}