import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button/Button';
import { updatePermissions } from 'store/controllers/user.controllers';

import SmoothDropdown from 'components/common/SmoothDropdown/SmoothDropdown';
import { map, reject } from 'lodash';
import ProcessPermission from './processPermission/ProcessPermission';
import s from './ProfileTurnaround.module.scss';

const ProfileTurnaround = ({ permissions: existingPermissions }) => {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState(existingPermissions);

  const handleCriticalEventClassChange = useCallback((airport, event, isCritical) => {
    setPermissions((prevState) =>
      prevState.map((item) =>
        item.airport !== airport
          ? item
          : {
              ...item,
              criticalEventClasses: isCritical
                ? item.criticalEventClasses.concat(event)
                : reject(item.criticalEventClasses, { id: event.id }),
            },
      ),
    );
  }, []);

  const selectAllEventClasses = useCallback((airport) => {
    setPermissions((prevState) =>
      prevState.map((item) => (item.airport !== airport ? item : { ...item, criticalEventClasses: item.eventClasses })),
    );
  }, []);

  const updateCriticalEvents = useCallback(() => {
    const permissionsPayload = permissions.map((permission) => ({
      airport: permission.airport,
      event_class: map(permission.criticalEventClasses, 'id'),
    }));

    dispatch(updatePermissions(permissionsPayload));
  }, [dispatch, permissions]);

  useEffect(() => {
    setPermissions(existingPermissions);
  }, [existingPermissions]);

  return (
    <div className={s.container}>
      <div className={s.saveButtonContainer}>
        <Button type="secondary" onClick={updateCriticalEvents}>
          Save
        </Button>
      </div>
      {permissions.map((item) => (
        <SmoothDropdown
          key={item.airport}
          title={
            <div key={item.airport} className={s.airportPermissions}>
              <h4 className={s.airport}>{`${item.airport} Airport`}</h4>
            </div>
          }
          numberOfChildren={item.eventClasses.length}
        >
          <div className={s.header}>
            <div>Event classes</div>
            <div className={s.critical}>
              <Button type="secondary-light" onClick={() => selectAllEventClasses(item.airport)}>
                Select all
              </Button>
            </div>
          </div>

          {item.eventClasses.map((eventClass) => (
            <ProcessPermission
              key={eventClass.id}
              eventClass={eventClass}
              criticalEventClasses={item.criticalEventClasses}
              onCriticalEventClassChange={handleCriticalEventClassChange.bind(this, item.airport)}
            />
          ))}
        </SmoothDropdown>
      ))}
    </div>
  );
};

ProfileTurnaround.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      airport: PropTypes.string.isRequired,
      processes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          isCritical: PropTypes.bool,
          critical_event_classes: PropTypes.arrayOf(PropTypes.number),
          event_classes: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              isCritical: PropTypes.bool,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
};

export default ProfileTurnaround;
