import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';

import Button from 'components/ui/Button/Button';

import {
    fetchEvents,
    fetchEventRelations,
    fetchEventClasses,
    fetchProcesses,
    deleteEventRelation,
    createEventRelation,
} from 'store/controllers/services.controllers';

import {find} from 'lodash';
import Confirmation from 'components/common/Confirmation/Confirmation';
import s from './EventRelationList.module.scss';

const EventRelationList = () => {
    const dispatch = useDispatch();

    const [newEventRelation, setNewEventRelation] = useState({});
    const [eventRelationToDelete, setEventRelationToDelete] = useState(null);

    const {relations, events, eventClasses, processes} = useSelector((state) => state.services);

    useEffect(() => {
        dispatch(fetchEventRelations());
        dispatch(fetchEvents());
        dispatch(fetchProcesses());
        dispatch(fetchEventClasses());
    }, [dispatch]);

    const onDeleteClick = useCallback((eventRelationId) => {
        setEventRelationToDelete(eventRelationId);
    }, []);

    const handleEventRelationDelete = useCallback(() => {
        dispatch(deleteEventRelation(eventRelationToDelete));
        setEventRelationToDelete(null);
    }, [dispatch, eventRelationToDelete]);

    const onDeleteCancel = useCallback(() => {
        setEventRelationToDelete(false);
    }, []);

    const handleProcessChange = useCallback((value) => {
        setNewEventRelation((prevState) => ({...prevState, process: value}));
    }, []);

    const handleEventClassChange = useCallback((value) => {
        setNewEventRelation((prevState) => ({...prevState, eventClass: value}));
    }, []);

    const handleEventChange = useCallback((value) => {
        setNewEventRelation((prevState) => ({...prevState, event: value}));
    }, []);

    const handleEventCreate = useCallback(() => {
        dispatch(
            createEventRelation({
                process: newEventRelation.process?.value,
                event_class: newEventRelation.eventClass?.value,
                event: newEventRelation.event?.value,
            }),
        );
    }, [dispatch, newEventRelation]);

    const processOptions = useMemo(() => {
        return processes.map((process) => ({
            value: process.id,
            label: process.name,
        }));
    }, [processes]);

    const eventClassOptions = useMemo(() => {
        return eventClasses.map((eventClass) => ({
            value: eventClass.id,
            label: eventClass.name,
        }));
    }, [eventClasses]);

    const eventOptions = useMemo(() => {
        return events.map((event) => ({
            value: event.id,
            label: event.name,
        }));
    }, [events]);

    return (
        <div>
            <div className={s.newProcessContainer}>
                <h6>Create new event</h6>
                <div className={s.newProcessInner}>
                    <div className={s.newProcessInput}>
                        <Select
                            options={processOptions}
                            value={newEventRelation.process}
                            onChange={handleProcessChange}
                            isSearchable={false}
                            className={s.select}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                }),
                                indicatorSeparator: () => ({
                                    display: 'none',
                                }),
                            }}
                        />
                        <Select
                            options={eventClassOptions}
                            value={newEventRelation.eventClass}
                            onChange={handleEventClassChange}
                            isSearchable={false}
                            className={s.select}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                }),
                                indicatorSeparator: () => ({
                                    display: 'none',
                                }),
                            }}
                        />
                        <Select
                            options={eventOptions}
                            value={newEventRelation.event}
                            onChange={handleEventChange}
                            isSearchable={false}
                            className={s.select}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                }),
                                indicatorSeparator: () => ({
                                    display: 'none',
                                }),
                            }}
                        />
                    </div>
                    <Button onClick={handleEventCreate}>Create</Button>
                </div>
            </div>

            <div className={s.list}>
                {relations.map((event) => (
                    <div key={event.id} className={s.container}>
                        <div className={s.header}>
                            <div>
                                <div className={s.title}>Process: {find(processes, {id: event.process})?.name}</div>
                                <div className={s.title}>Event
                                    Class: {find(eventClasses, {id: event.event_class})?.name}</div>
                                <div className={s.title}>Event: {find(events, {id: event.event})?.name}</div>
                            </div>

                            <Button type="secondary-light" onClick={() => onDeleteClick(event.id)}>
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <Confirmation
                question="Do you want to delete an event relation?"
                isOpen={!!eventRelationToDelete}
                onConfirm={handleEventRelationDelete}
                onCancel={onDeleteCancel}
            />
        </div>
    );
};

export default EventRelationList;
