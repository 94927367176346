import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import { parseTurnType } from 'utils/helpers';
import {noop } from 'lodash';
import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import FlightStatus from './FlightStatus';

export const CELL_WIDTH = 118;

const FlightRow = ({ flight, onClickDetail, index }) => {
  const [isModeDetailsPopupOpen, setModeDetailsPopupOpen] = useState(false);

  const handleFlightClick = useCallback(() => {
    if (flight.arrival_flight_number) onClickDetail(flight);
  }, [flight, onClickDetail]);

  const openModeDetails = useCallback(
    (e) => {
      if (!flight.current_airport) return;
      e.stopPropagation();
      setModeDetailsPopupOpen(true);
    },
    [flight.current_airport],
  );

  const closeModeDetails = useCallback(() => {
    setModeDetailsPopupOpen(false);
  }, []);


  return (
    <div
      tabIndex={0}
      role="button"
      onKeyPress={noop}
      className="table-content-columns width-for-sticky-scroll"
      style={flight.arrival_flight_number ? { cursor: 'pointer' } : {}}
      onClick={handleFlightClick}
    >
      <div className="table-content-columns title" style={{ zIndex: index + 5 }}>
        <span
          role="button"
          tabIndex={0}
          onKeyPress={noop}
          className="table-content-columns__width turnaroundMode"
          onClick={openModeDetails}
        >
          <ClickAwayListener onClick={closeModeDetails}>
            {isModeDetailsPopupOpen && (
              <div className="modeDetailsPopup">
                <div className="mode">
                </div>
                <div className="item title">Gates:</div>
                {flight.incoming_gate && (
                  <div
                    className="item action"
                    data-type="incoming"
                  >
                    <div>{flight.incoming_gate}</div>
                  </div>
                )}
                {flight.outgoing_gate && (
                  <div
                    className="item action"
                    data-type="outgoing"
                  >
                    <div>{flight.outgoing_gate}</div>
                  </div>
                )}
                {flight.parking_gate && (
                  <div
                    className="item action"
                    data-type="parking"
                  >
                    <div>{flight.parking_gate}</div>
                  </div>
                )}
              </div>
            )}
          </ClickAwayListener>
        </span>
        <span className="table-content-columns__width">
          {flight.airline && `${flight.airline} ${flight.arrival_flight_number}`}
        </span>
        <span className="table-content-columns__width">
          {flight.arrival_airport && `${flight.current_airport} - ${flight.arrival_airport}`}
        </span>
        <span className="table-content-columns__width">{parseTurnType(flight.turn_type)}</span>
      </div>
      <div className="table-content-columns time">
        {flight.arrival_flight_number && (
          <FlightStatus
            expectedStart={flight.sta?.getTime()}
            expectedEnd={flight.std?.getTime()}
            realStart={flight.rst?.getTime()}
            realEnd={flight.ret?.getTime()}
            isDelayed={false}
          />
        )}
        {range(24).map((i) => (
          <span key={i} className="table-content-columns__width" style={{ width: CELL_WIDTH }} />
        ))}
      </div>
    </div>
  );
};

FlightRow.propTypes = {
  flight: PropTypes.shape({
    arrival_flight_number: PropTypes.number || PropTypes.string,
    airline: PropTypes.string,
    arrival_airport: PropTypes.string,
    departure_airport: PropTypes.string,
    current_airport: PropTypes.string,
    turn_type: PropTypes.string,
    std: PropTypes.instanceOf(Date),
    atd: PropTypes.instanceOf(Date),
    sta: PropTypes.instanceOf(Date),
    rst: PropTypes.instanceOf(Date),
    ret: PropTypes.instanceOf(Date),
    incoming_gate: PropTypes.string,
    outgoing_gate:  PropTypes.string,
    parking_gate:  PropTypes.string,
  }).isRequired,
  onClickDetail: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default FlightRow;
