import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Input from 'components/ui/MyInput/MyInput';
import Button from 'components/ui/Button/Button';
import Confirmation from 'components/common/Confirmation/Confirmation';

import {fetchSafetyAlerts, deleteSafetyAlert, createSafetyAlert} from 'store/controllers/safetyAlert.controllers';

import s from './SafetyAlertsList.module.scss';

const SafetyAlertsList = () => {
    const dispatch = useDispatch();
    const [newAlert, setNewAlert] = useState({name: ''});
    const [alertToDelete, setAlertToDelete] = useState(null);
    const {safetyAlerts} = useSelector((state) => state.safetyAlerts);

    useEffect(() => {
        dispatch(fetchSafetyAlerts());
    }, [dispatch]);

    const onDeleteClick = useCallback((alertId) => {
        setAlertToDelete(alertId);
    }, []);

    const handleAlertDelete = useCallback(() => {
        dispatch(deleteSafetyAlert(alertToDelete));
        setAlertToDelete(null);
    }, [dispatch, alertToDelete]);

    const onDeleteCancel = useCallback(() => {
        setAlertToDelete(null);
    }, []);

    const handleNewAlertNameChange = useCallback((e) => {
        setNewAlert({name: e.target.value});
    }, []);

    const handleAlertCreate = useCallback(() => {
        dispatch(createSafetyAlert(newAlert));
        setNewAlert({name: ''});
    }, [dispatch, newAlert]);

    return (
        <div>
            <div className={s.newAlertContainer}>
                <h6>Create new alert</h6>
                <div className={s.newAlertInner}>
                    <div className={s.newAlertInput}>
                        <Input id="new-alert-name" value={newAlert.name} onChange={handleNewAlertNameChange}/>
                    </div>
                    <Button onClick={handleAlertCreate}>Create</Button>
                </div>
            </div>

            <div className={s.list}>
                {safetyAlerts.map((alert) => (
                    <div key={alert.id} className={s.container}>
                        <div className={s.header}>
                            <div className={s.title} style={{fontSize: 18}}>
                                {alert.name}
                            </div>
                            <Button type="secondary-light" onClick={() => onDeleteClick(alert.id)}>
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <Confirmation
                question="Do you want to delete a safety alert?"
                isOpen={!!alertToDelete}
                onConfirm={handleAlertDelete}
                onCancel={onDeleteCancel}
            />
        </div>
    );
};

export default SafetyAlertsList;
