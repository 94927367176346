import {
  FETCH_ALL_SERVICES_LOADING,
  CREATE_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
  CREATE_EVENT_CLASS,
  UPDATE_EVENT_CLASS,
  DELETE_EVENT_CLASS,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  FETCH_SERVICE_PROCESSES_SUCCESS,
  FETCH_EVENT_CLASSES_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENT_RELATIONS_SUCCESS,
  CREATE_EVENT_RELATION,
  UPDATE_EVENT_RELATION,
  DELETE_EVENT_RELATION,
} from 'store/constants/services.constants';

const initialState = {
  services: [],
  processes: [],
  eventClasses: [],
  events: [],
  relations: [],
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERVICE_PROCESSES_SUCCESS:
      return { ...state, processes: action.payload };
    case FETCH_EVENT_CLASSES_SUCCESS:
      return { ...state, eventClasses: action.payload };
    case FETCH_EVENTS_SUCCESS:
      return { ...state, events: action.payload };
    case FETCH_EVENT_RELATIONS_SUCCESS:
      return { ...state, relations: action.payload };
    case FETCH_ALL_SERVICES_LOADING:
      return { ...state, isLoading: action.payload };
    case CREATE_PROCESS:
      return {
        ...state,
        processes: [action.payload].concat(state.processes),
      };
    case UPDATE_PROCESS:
      return {
        ...state,
        processes: state.processes.map((process) => (process.id === action.payload.id ? action.payload : process)),
      };
    case DELETE_PROCESS:
      return {
        ...state,
        processes: state.processes.filter((process) => process.id !== action.payload),
      };
    case CREATE_EVENT_CLASS:
      return {
        ...state,
        eventClasses: [action.payload].concat(state.eventClasses),
      };
    case UPDATE_EVENT_CLASS:
      return {
        ...state,
        eventClasses: state.eventClasses.map((eventClass) =>
          eventClass.id === action.payload.id ? action.payload : eventClass,
        ),
      };
    case DELETE_EVENT_CLASS:
      return {
        ...state,
        eventClasses: state.eventClasses.filter((eventClass) => eventClass.id !== action.payload),
      };
    case CREATE_EVENT:
      return {
        ...state,
        events: [action.payload].concat(state.events),
      };
    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((event) => (event.id === action.payload.id ? action.payload : event)),
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event.id !== action.payload),
      };
    case CREATE_EVENT_RELATION:
      return {
        ...state,
        relations: [action.payload].concat(state.relations),
      };
    case UPDATE_EVENT_RELATION:
      return {
        ...state,
        relations: state.relations.map((event) => (event.id === action.payload.id ? action.payload : event)),
      };
    case DELETE_EVENT_RELATION:
      return {
        ...state,
        relations: state.relations.filter((event) => event.id !== action.payload),
      };
    default:
      return state;
  }
};
