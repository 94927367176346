import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import AirportLabels from 'components/ui/AirportLabels/AirportLabels';
import { Import, Support as CheckIcon } from 'assets/img/all-icons';
import Button from 'components/ui/Button/Button';
import Header from 'components/common/Header/Header';
import AirportFilter from 'components/ui/AirportFilter/AirportFilter';
import { Preloader } from 'components/common/Preloader/Preloader';

import airportActions from 'store/actions/airport.actions';
import { fetchAirportByIata, updateAirport } from 'store/controllers/airport.controllers';
import { createImportAirportCSVDataHandler } from 'store/handlers';
import uiActions from 'store/actions/ui.actions';
import { routes } from 'store/constants/ui.constants';
import Can from 'services/Can';
import { FILTER, EDIT } from 'services/ability';

import { Accordion } from './Airport/Airport';

import './AirportSettings.scss';

const importAirportCSVData = createImportAirportCSVDataHandler(() =>
  toast.success('Airport CSV data file successfully imported'),
);

const AirportSettings = () => {
  const [filterQuery, setFilterQuery] = useState('');
  const [isFilterOpen, setFilterOpen] = useState(false);
  const { selectedAirport, isLoading, airportData } = useSelector(({ airport }) => airport, shallowEqual);
  const {token} = useSelector(({user}) => user)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uiActions.changePage(routes.AIRPORT_SETTINGS));
    return () => dispatch(uiActions.changePage(''));
  }, [dispatch]);

  const onFilterClick = useCallback(() => setFilterOpen((prevState) => !prevState), []);

  const closeFilter = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const onQueryInputChange = useCallback((e) => {
    setFilterQuery(e.target.value);
  }, []);

  const onActivateClick = useCallback(() => {
    dispatch(
      updateAirport(selectedAirport.iata, {
        is_activated: !selectedAirport.is_activated,
      }),
    );
  }, [selectedAirport, dispatch]);

  const onAirportClick = useCallback(
    (data) => {
      dispatch(airportActions.selectAirport(data));
    },
    [dispatch],
  );

  const handleAirportCSVUpload = useCallback(
    (e) => {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      dispatch(importAirportCSVData(formData, selectedAirport.iata));
    },
    [dispatch, selectedAirport.iata],
  );

  const onImportClick = useCallback(() => {
    document.getElementById('importInput').click();
  }, []);

  useEffect(() => {
    dispatch(fetchAirportByIata(selectedAirport.iata, token));
  }, [selectedAirport, dispatch]);

  return (
    <div className="airport">
      <Header>
        <div className="admin-label-group">
          <AirportLabels data={selectedAirport} />
          <div className="btn-action-group admin-label-group-buttons">
            <button type="button" className="admin-label-group-buttons-activate" onClick={onActivateClick}>
              <span className="text">{selectedAirport.is_activated ? 'Activated' : 'Not activated'}</span>
              <CheckIcon
                width="25px"
                className={classnames({
                  activated: selectedAirport.is_activated,
                })}
              />
            </button>
            <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
              <Button icon={<Import width="17px" />} type="secondary" className="import" onClick={onImportClick}>
                Import CSV
                <input
                  id="importInput"
                  type="file"
                  accept=".csv"
                  multiple={false}
                  onChange={handleAirportCSVUpload}
                  hidden
                />
              </Button>
            </Can>
            <Can I={FILTER} a="airports">
              <AirportFilter
                isOpen={isFilterOpen}
                onClick={onFilterClick}
                query={filterQuery}
                onQueryChange={onQueryInputChange}
                closeFilter={closeFilter}
                selectedItem={selectedAirport}
                onAirportClick={onAirportClick}
              />
            </Can>
          </div>
        </div>
      </Header>
      {isLoading || !airportData ? (
        <h2 style={{ marginTop: 100, textAlign: 'center' }}>
          <Preloader />
        </h2>
      ) : (
        <Accordion data={airportData.gates} iata={selectedAirport.iata} />
      )}
    </div>
  );
};

export default AirportSettings;
