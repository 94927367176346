import {
  FETCH_ALL_SERVICES_SUCCESS,
  FETCH_ALL_SERVICES_LOADING,
  CREATE_PROCESS,
  UPDATE_PROCESS,
  DELETE_PROCESS,
  DELETE_EVENT_CLASS,
  UPDATE_EVENT_CLASS,
  CREATE_EVENT_CLASS,
  UPDATE_EVENT,
  CREATE_EVENT,
  DELETE_EVENT,
  FETCH_EVENT_CLASSES_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_SERVICE_PROCESSES_SUCCESS,
  FETCH_EVENT_RELATIONS_SUCCESS,
  DELETE_EVENT_RELATION,
  UPDATE_EVENT_RELATION,
  CREATE_EVENT_RELATION,
} from 'store/constants/services.constants';

export default {
  fetchServicesSuccess(services) {
    return { type: FETCH_ALL_SERVICES_SUCCESS, payload: services };
  },
  fetchServicesLoading(isLoading) {
    return { type: FETCH_ALL_SERVICES_LOADING, payload: isLoading };
  },
  fetchProcessesSuccess(processes) {
    return { type: FETCH_SERVICE_PROCESSES_SUCCESS, payload: processes };
  },
  createProcess(process) {
    return { type: CREATE_PROCESS, payload: process };
  },
  updateProcess(process) {
    return { type: UPDATE_PROCESS, payload: process };
  },
  deleteProcess(processId) {
    return { type: DELETE_PROCESS, payload: processId };
  },
  fetchEventClassesSuccess(eventClasses) {
    return { type: FETCH_EVENT_CLASSES_SUCCESS, payload: eventClasses };
  },
  createEventClass(eventClass) {
    return { type: CREATE_EVENT_CLASS, payload: eventClass };
  },
  updateEventClass(eventClass) {
    return { type: UPDATE_EVENT_CLASS, payload: eventClass };
  },
  deleteEventClass(eventClassId) {
    return { type: DELETE_EVENT_CLASS, payload: eventClassId };
  },
  fetchEventsSuccess(events) {
    return { type: FETCH_EVENTS_SUCCESS, payload: events };
  },
  createEvent(event) {
    return { type: CREATE_EVENT, payload: event };
  },
  updateEvent(event) {
    return { type: UPDATE_EVENT, payload: event };
  },
  deleteEvent(eventId) {
    return { type: DELETE_EVENT, payload: eventId };
  },
  fetchEventRelationsSuccess(events) {
    return { type: FETCH_EVENT_RELATIONS_SUCCESS, payload: events };
  },
  createEventRelation(event) {
    return { type: CREATE_EVENT_RELATION, payload: event };
  },
  updateEventRelation(event) {
    return { type: UPDATE_EVENT_RELATION, payload: event };
  },
  deleteEventRelation(eventId) {
    return { type: DELETE_EVENT_RELATION, payload: eventId };
  },
};
