import React from 'react';
// import { Link } from 'react-router-dom';
import DatePicker from 'react-date-picker';

// import Button from 'components/ui/Button/Button';
// import { Cpu, Download } from 'assets/img/all-icons';

// import { routes } from 'store/constants/ui.constants';

import './TableHeader.scss';

const TableHeader = ({ dates, onChange }) => {
  return (
    <header className="table-header">
      <nav className="table-header-action">
        <div className="datepicker">
          <span className="datepicker__label">Date:</span>
          <DatePicker onChange={onChange} value={dates} />
        </div>

        <div className="button-group">
          {/* <Link to={routes.API}>
            <Button icon={<Cpu width="20px" />}>API</Button>
          </Link>
          <Button type="secondary" icon={<Download width="17px" />}>
            CSV
          </Button> */}
        </div>
      </nav>
    </header>
  );
};

export default TableHeader;
