import {
  CHANGE_API_QUERY,
  FETCH_API_SUCCESS,
  FETCH_API_LOADING,
  SELECT_API_REQUEST,
} from 'store/constants/api.constants';

export default {
  changeQuery(query) {
    return { type: CHANGE_API_QUERY, payload: query };
  },
  fetchApiSuccess(api) {
    return { type: FETCH_API_SUCCESS, payload: api };
  },
  fetchApiLoading(isLoading) {
    return { type: FETCH_API_LOADING, payload: isLoading };
  },
  selectRequest(requestId) {
    return { type: SELECT_API_REQUEST, payload: requestId };
  },
};
