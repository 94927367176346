/* eslint-disable react-hooks/exhaustive-deps */
import {useMemo, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import sortBy from 'lodash/fp/sortBy';
import {flow} from 'lodash';
import filter from 'lodash/fp/filter';
import TimeLine from 'components/processes/monitoring/TimeLine/TimeLine';
import {splitDateRangeIntoEqualParts, getFormattedTime, calculateDurationRatio} from 'utils/helpers';
import useNow from 'utils/useNow';

import DetailTableItem from './DetailTableItem/DetailTableItem';
import './DetailTable.scss';

const SEGMENTS_TOTAL = 7;

const DetailTable = () => {
    const now = useNow();
    const {turnaround: {processes, std, sta, rst, ret}} = useSelector((state) => state.turnaround);
    const [timelineOffset, setTimelineOffset] = useState(0);

    const startPoint = useMemo(() => rst && sta > rst ? rst : sta, [sta, rst]);

    const endPoint = useMemo(() => {
        if (now > std && !ret) return now
        if (ret && ret > std) return ret
        return std
    }, [std, ret, now]);


    const aircraftArrival = () => {
        const arrivalPrep = processes.find(field => field['display'] === "Catering");
        if (!arrivalPrep) return
        if (arrivalPrep['actual_start']) return arrivalPrep.ast
        if (arrivalPrep['scheduled_start']) return arrivalPrep.sst
    }

    const processRows = useMemo(() => {
        return flow([filter((process) => process.sst || process.ast)])(processes)
    }, [processes]);

    const gridLines = useMemo(() => {
        const splittedDateRange = splitDateRangeIntoEqualParts(endPoint, startPoint, SEGMENTS_TOTAL);

        let indexOfTimeToSubstituteForSTD = splittedDateRange.findIndex((elem) => std < elem);
        if (indexOfTimeToSubstituteForSTD !== splittedDateRange.length - 1) {
            if (
                std - splittedDateRange[indexOfTimeToSubstituteForSTD - 1] <
                splittedDateRange[indexOfTimeToSubstituteForSTD] - std
            ) {
                indexOfTimeToSubstituteForSTD -= 1;
            }
        }

        let indexOfTimeToSubstituteForSTA = splittedDateRange.findIndex((elem) => sta >= elem);
        if (indexOfTimeToSubstituteForSTA !== 0) {
            if (
                sta - splittedDateRange[indexOfTimeToSubstituteForSTA] >
                splittedDateRange[indexOfTimeToSubstituteForSTA + 1] - sta
            ) {
                indexOfTimeToSubstituteForSTA += 1;
            }
        }

        splittedDateRange[indexOfTimeToSubstituteForSTD] = std;
        splittedDateRange[indexOfTimeToSubstituteForSTA] = sta;

        // return  splittedDateRange

        return (
            <div className="gridlines">
                {aircraftArrival() && <span
                    style={{left: `${((aircraftArrival() - startPoint) / (endPoint - startPoint)) * 100}%`}}
                    className='line dashed-line'
                />}
                {splittedDateRange.map((time, i) => (
                    <span key={time} style={{left: `${((time - startPoint) / (endPoint - startPoint)) * 100}%`}}
                        className='line'
                    />
                ))}
            </div>
        );
    }, [std, sta, startPoint, endPoint]);

    const bottomTimings = useMemo(() => {
        const splittedDateRange = splitDateRangeIntoEqualParts(endPoint, startPoint, SEGMENTS_TOTAL);

        let indexOfTimeToSubstituteForSTD = splittedDateRange.findIndex((elem) => std < elem);
        if (indexOfTimeToSubstituteForSTD !== splittedDateRange.length - 1) {
            if (
                std - splittedDateRange[indexOfTimeToSubstituteForSTD - 1] <
                splittedDateRange[indexOfTimeToSubstituteForSTD] - std
            ) {
                indexOfTimeToSubstituteForSTD -= 1;
            }
        }

        let indexOfTimeToSubstituteForSTA = splittedDateRange.findIndex((elem) => sta >= elem);
        if (indexOfTimeToSubstituteForSTA !== 0) {
            if (
                sta - splittedDateRange[indexOfTimeToSubstituteForSTA] >
                splittedDateRange[indexOfTimeToSubstituteForSTA + 1] - sta
            ) {
                indexOfTimeToSubstituteForSTA += 1;
            }
        }

        splittedDateRange[indexOfTimeToSubstituteForSTD] = std;
        splittedDateRange[indexOfTimeToSubstituteForSTA] = sta;

        // return splittedDateRange

        return (
            <div className="airplane-detail-table-header-timeline line bottom">
                {splittedDateRange.map((time) => (
                    <span key={time} style={{left: `${((time - startPoint) / (endPoint - startPoint)) * 100}%`}} className="airplane-detail-table-header-time">
            <div style={{minWidth: 'max-content'}}>
              {getFormattedTime(Math.ceil((time - startPoint) / 1000) * 1000)}
            </div>
          </span>
                ))}
            </div>
        );
    }, [std, sta, endPoint, startPoint]);

    const duration = useMemo(() => {
            if (!!ret && !!rst) {
                return ret - rst
            } else if (!ret && !!rst) {
                return now - rst
            } else {
                return 0
            }
        }, [now, rst, ret]);
    useEffect(() => {
        if (now < startPoint || !rst) {
            setTimelineOffset(0);
        } else {
            const offset = calculateDurationRatio(startPoint, endPoint, ret && now > ret ? ret : now) * -1 * 100;
            setTimelineOffset(offset);
        }
    }, [rst, ret, startPoint, sta, now, endPoint]);

    return (
        <div className="airplane-detail-table table-max__width">
            <div className="airplane-detail-table-header">
                <div className="airplane-detail-table-header-title">
                    <span className="airplane-detail-table-header-item">Process</span>
                    <span className="airplane-detail-table-header-item">Duration</span>
                    <span className="airplane-detail-table-header-item">Start time</span>
                    <span className="airplane-detail-table-header-item">End time</span>
                </div>
            </div>
            <div style={{position: 'relative', height: 'calc(100% - 92px)'}}>
                <div className="timeline-container">
                    <TimeLine duration={duration} offset={`${timelineOffset}%`} withTime/>
                </div>
                <div className="airplane-detail-table-content scroll-bar__style">
                    {processRows.map(item => {
                        return <DetailTableItem key={item['display']} process={item} end={endPoint}
                                            ata={rst} start={startPoint} aircraftArrival={aircraftArrival}/>
                    })}
                </div>
                {gridLines}
            </div>
            <div className="airplane-detail-table-box-time">
                <div className="airplane-detail-table-header-title">
                    <span className="airplane-detail-table-header-item"> </span>
                    <span className="airplane-detail-table-header-item"> </span>
                    <span className="airplane-detail-table-header-item"> </span>
                    <span className="airplane-detail-table-header-item"> </span>
                    <span className="airplane-detail-table-header-item"> </span>
                </div>
                {bottomTimings}
            </div>
        </div>
    );
};

export default DetailTable;
