import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Button from 'components/ui/Button/Button';
import { CopyIcon } from 'assets/img/all-icons';

import s from './RequestPlayground.module.scss';

const copyNotify = () => toast.info('Code snippet is copied to the clipboard');

const langs = [
  { value: 'javascript', label: 'Javascript' },
  { value: 'python', label: 'Python' },
  { value: 'go', label: 'Go' },
  { value: 'java', label: 'Java' },
];

const snippets = {
  javascript:
    'var http = require("https");\n\nvar options = {\n\t"method": "GET",\n\t"hostname": "https://sky-dev.intell-act.com",\n\t"port": null,\n\t"path": "/api/processes",\n\t"headers": {\n\t\t"Intellact-API-Key": "Your Key",\n\t}\n};\n\nvar req = http.request(options, function (res) {\n\tvar chunks = [];\n\n\tres.on("data", function (chunk) {\n\t\tchunks.push(chunk);\n\t});\n\n\tres.on("end", function () {\n\t\tvar body = Buffer.concat(chunks);\n\t\tconsole.log(body.toString());\n\t});\n});\n\nreq.end();',
  go:
    'package main\n\nimport (\n\t"fmt"\n\t"net/http"\n\t"io/ioutil"\n)\n\nfunc main() {\n\n\turl := "https://sky-dev.intell-act.com/api/processes"\n\n\treq, _ := http.NewRequest("GET", url, nil)\n\n\treq.Header.Add("Intellact-API-Key", "Your Key")\n\n\tres, _ := http.DefaultClient.Do(req)\n\n\tdefer res.Body.Close()\n\tbody, _ := ioutil.ReadAll(res.Body)\n\n\tfmt.Println(res)\n\tfmt.Println(string(body))\n\n}',
  python:
    'import requests\n\nurl = "https://sky-dev.intell-act.com/api/processes"\n\nheaders = {\n   \'Intellact-API-Key\': "Your Key"\n}\n\nresponse = requests.request("GET", url, headers=headers)\n\nprint(response.text)',
  java:
    'OkHttpClient client = new OkHttpClient();\n\nRequest request = new Request.Builder()\n\t.url("https://sky-dev.intell-act.com/api/processes")\n\t.get()\n\t.addHeader("Intellact-API-Key", "Your Key")\n\t.build();\n\nResponse response = client.newCall(request).execute();',
};

const RequestPlayground = () => {
  const [selectedLang, setSelectedLang] = useState(langs[0]);

  const onLangChange = useCallback((lang) => {
    setSelectedLang(lang);
  }, []);

  return (
    <div>
      <div className={s.header}>
        <Select
          options={langs}
          value={selectedLang}
          onChange={onLangChange}
          className={s.codeSelect}
          isSearchable={false}
          styles={{
            control: (provided) => ({
              ...provided,
              background: 'transparent',
              boxShadow: 'none',
              cursor: 'pointer',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
        />
        <CopyToClipboard
          onCopy={copyNotify}
          text={snippets[selectedLang.value]}
        >
          <Button type="secondary" icon={<CopyIcon width="18px" />}>
            Copy Code
          </Button>
        </CopyToClipboard>
      </div>
      <div className={s.snippet}>
        <SyntaxHighlighter
          language={selectedLang.value}
          style={atomOneLight}
          customStyle={{ background: 'none', fontSize: 12, padding: '15px' }}
        >
          {snippets[selectedLang.value]}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default RequestPlayground;
