export const positions = ['ramp', 'bridge', 'gate'];

export const views = {
  ramp: ['left-view', 'right-view', 'center-view', 'under-the-belly'],
  bridge: ['business', 'economy', '3d'],
  gate: [],
};

export const availableCones = [
  { name: 'left-wing' },
  { name: 'right-wing' },
  { name: 'nose' },
  { name: 'tail' },
  { name: 'right-front-fuselage' },
  { name: 'right-back-fuselage' },
  { name: 'left-front-fuselage' },
  { name: 'left-back-fuselage' },
];
