import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import PrivateRoute from 'components/containers/PrivateRoute';
import Processes from 'pages/processes/Processes';
import FlightTracker from 'pages/flight/Flight';
import Accounts from 'pages/accounts/Accounts';
import UserProfile from 'pages/profile/Profile';
import AddAccount from 'pages/addAccount/AddAccount';
import EditAccount from 'pages/editAccount/EditAccount';
import Api from 'pages/api/Api';
import Standards from 'pages/standards/Standards';
import TurnaroundSettings from 'pages/turnaroundSettings/TurnaroundSettings';
import Cameras from 'pages/cameras/Cameras';
import Layout from 'components/containers/Layout/Layout';

import Can from 'services/Can';
import {VIEW, EDIT} from 'services/ability';
import {routes, processViews} from 'store/constants/ui.constants';

import Login from './auth/Login';
import AirportSettings from './airportSettings/AirportSettings';
import NotFoundPage from './404';
import TestSocketComponent from "../components/TestSocketComponent";

const AppRouter = () => {
    return (
            <Switch>
                <Route path={routes.SIGNIN} component={Login}/>
                <Redirect exact from={routes.HOME} to={routes.TOGGLE_PATH}/>
                <PrivateRoute
                    path={routes.TURNAROUND}
                    exact
                    render={(props) => (
                        <Layout>
                            <Can I={VIEW} an={processViews.ADMIN}>
                                <TestSocketComponent/>

                                {/*<Processes {...props} />*/}
                            </Can>
                        </Layout>
                    )}
                />
                <PrivateRoute
                    path={routes.STANDARDS}
                    exact
                    render={(props) => (
                        <Layout>
                            <Can I={VIEW} an={routes.STANDARDS}>
                                <Standards {...props} />
                            </Can>
                        </Layout>
                    )}
                />
                <PrivateRoute path={routes.USER_PROFILE} exact component={UserProfile}/>
                <PrivateRoute path={`${routes.TURNAROUND}/:id`} exact component={FlightTracker}/>
                <PrivateRoute path={routes.CAMERAS} exact component={Cameras}/>
                <PrivateRoute path={routes.AIRPORT_SETTINGS} component={AirportSettings}/>
                <PrivateRoute path={routes.ACCOUNTS} exact
                              render={(props) => (
                                  <Layout>
                                      <Can I={VIEW} an={routes.ACCOUNTS}>
                                          <Accounts {...props} />
                                      </Can>
                                  </Layout>
                              )}
                />
                <PrivateRoute
                    path={routes.ADD_ACCOUNT}
                    exact
                    render={(props) => (
                        <Layout>
                            <Can I={VIEW} an={routes.ACCOUNTS}>
                                <AddAccount {...props} />
                            </Can>
                        </Layout>
                    )}
                />
                <PrivateRoute
                    path={`${routes.EDIT_ACCOUNT}/:id`}
                    exact
                    render={(props) => (
                        <Layout>
                            <Can I={EDIT} an={routes.ACCOUNTS}>
                                <EditAccount {...props} />
                            </Can>
                        </Layout>
                    )}
                />
                <PrivateRoute path={routes.API} component={Api}/>
                <PrivateRoute
                    path={routes.TURNAROUND_SETTINGS} exact
                    render={(props) => (
                        <Layout><Can I={VIEW} a={routes.TURNAROUND_SETTINGS}><TurnaroundSettings {...props} /></Can>
                        </Layout>
                    )}
                />
                <Route component={NotFoundPage}/>
            </Switch>
    );
};

export default AppRouter;
