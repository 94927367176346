import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isEmpty, noop, sortBy} from 'lodash';

import {ArrowRight} from 'assets/img/all-icons';
import useNow from 'utils/useNow';
import {getFormattedDuration, defaultFormat} from 'utils/helpers';

import './DetailTableItem.scss';
import {addSeconds, differenceInMinutes, subSeconds} from 'date-fns';
import {useSelector} from "react-redux";

const ALLOWED_OFFSET = 60;

const DetailTableItem = ({process, start, end, ata, aircraftArrival}) => {
    const {turnaround} = useSelector(state => state['turnaround'])
    const now = useNow();
    const [areEventClassesOpen, setEventClassesOpen] = useState(false);
    const timelineBoxRef = useRef();

    const handleProcessClick = useCallback(() => {
        setEventClassesOpen((prevState) => !prevState);
    }, []);

    useEffect(() => {
        const timeWidth = document.querySelector('.airplane-detail-table-header-timeline.line.bottom');
        timeWidth.style.maxWidth = `${timelineBoxRef.current.clientWidth}px`;
    }, [timelineBoxRef.current?.clientWidth]);


    const eventClasses = useMemo(() => {
        if (!process.event_classes) return [];
        return sortBy(process.event_classes, ['scheduled', 'actual']);
    }, [process.event_classes]);


    const startTime = useMemo(() => {
        const duration = process.ast ? <span>{defaultFormat(process.ast)}</span> : '';

        let delay = '';
        const diff = differenceInMinutes(process.ast || now, process.sst);


        if (diff > 0) {
            delay = <span style={{color: 'red', marginLeft: 5}}>{`(+${diff})`}</span>;
        } else if (diff < 0) {
            delay = <span style={{color: 'green', marginLeft: 5}}>{`(${diff})`}</span>;
        } else {
            delay = <span style={{color: 'green', marginLeft: 5}}>(0)</span>;
        }

        return (
            <>
                {duration} {delay}
            </>
        );
    }, [process, now]);

    const endTime = useMemo(() => {
        const duration = process.aft ? <span>{defaultFormat(process.aft)}</span> : '';

        let delay = '';
        const diff = differenceInMinutes((process.aft) || now, process.sft);

        if (diff > 0) {
            delay = <span style={{color: 'red', marginLeft: 5}}>{`(+${diff})`}</span>;
        } else if (diff < 0) {
            delay = <span style={{color: 'green', marginLeft: 5}}>{`(${diff})`}</span>;
        } else {
            delay = <span style={{color: 'green', marginLeft: 5}}>(0)</span>;
        }

        return (<>{duration} {delay}</>);
    }, [process, now]);

    const processColor = useMemo(() => {
        let color = 'green';

        if (!process.ast || (process.ast && process.ast > process.sst)) {
            color = 'yellow';
        }

        if (now > process.sft && !process.aft) {
            color = 'red';
        }

        if (subSeconds(process.aft, ALLOWED_OFFSET) > process.sft) {
            color = 'red';
        }

        return color;
    }, [process, now]);

    const scheduledWidth = useMemo(() => {
        // let scheduledStart = process.sst;
        // let scheduledEnd = process.sft;
        let scheduledStart = new Date(process.sst).getTime();
        let scheduledEnd = new Date(process.sft).getTime();

        //
        // if (`${scheduledEnd}` === `${scheduledStart}`) {
        //     scheduledStart = subSeconds(scheduledStart, 30);
        //     scheduledEnd = addSeconds(scheduledEnd, 30);
        // }

        if (scheduledEnd === scheduledStart) {
            scheduledStart = new Date(subSeconds(scheduledStart, 30)).getTime();
            scheduledEnd = new Date(addSeconds(scheduledEnd, 30)).getTime();
        }

        return `${((scheduledEnd - scheduledStart) / (end - start)) * 100}%`;
    }, [process, end, start]);

    const unixFormat = date => {

        if (!!Date.parse(String(date))) {
            return Date.parse(String(date))

        } else if (isNaN(date) && !isNaN(Date.parse(date))) {
            return Date.parse(date)
        } else if (!isNaN(date) && isNaN(Date.parse(date))) {
            return date
        } else {
            try {
                return date.getUnixTime()
            } catch {
                return NaN
            }
        }

    }

// If I right - this func calc marg and offset for graph
    const scheduledLeftMargin = useMemo(() => {
        const arrival = aircraftArrival();

        const margin = (((unixFormat(process.sst) - unixFormat(start)) / (unixFormat(end) - unixFormat(start)))) * 100
        const offset = ((unixFormat(arrival) - unixFormat(start)) / (unixFormat(end) - unixFormat(start))) * 100

        const result = margin + offset

        return (-100 < result < 100) ? `${margin + offset}%` : `${margin}%`
    }, [process, end, start, aircraftArrival])
    // If I right - this func calc marg and offset for graph


    const actualWidth = useMemo(() => {
        if (!process.ast) return 0;

        // let actualStart = process.ast;
        // let actualEnd = process.aft || end


        let actualStart = process.ast;
        let actualEnd = (process.aft && process.aft) || end


        if (!process.aft) {
            actualEnd = null;
        }
        return `${(((actualEnd || now) - actualStart) / (end - start)) * 100}%`;
    }, [process, end, start, now]);

    return (
        <>
            <div className="airplane-detail-table-content-row">
                <div className="airplane-detail-table-header-title">
                    <div className="airplane-detail-table-header-item">
                        {!isEmpty(eventClasses) ? (
                            <div className="airplane-detail-table-header-item details-box">
                                <div role="button" tabIndex={0} onKeyPress={noop} className="box pointer"
                                     onClick={handleProcessClick}>
                                    {!areEventClassesOpen ? (
                                        <ArrowRight
                                            width="32px"
                                            style={{transformOrigin: 'center center',}}
                                            className="airplane-detail-content-icon_color"
                                        />
                                    ) : (
                                        <ArrowRight
                                            width="32px"
                                            style={{transform: 'rotate(90deg)', transformOrigin: 'center center',}}
                                            className="airplane-detail-content-icon_color"
                                        />
                                    )}
                                </div>
                                <span className="airplane-detail-table-header-item-title">{process['display']}</span>
                            </div>
                        ) : (
                            <span className="airplane-detail-table-header-item-title">{process['display']}</span>
                        )}
                    </div>
                    <span className="airplane-detail-table-header-item">
                        {process.ast && getFormattedDuration(((process.aft || turnaround['ret']) || now) - process.ast)}
                    </span>
                    <span className="airplane-detail-table-header-item">{process['actual_start'] && startTime}</span>
                    <span className="airplane-detail-table-header-item">{process['actual_finish'] && endTime}</span>
                </div>

                <div className="airplane-detail-table-header-timeline">
                    <div ref={timelineBoxRef} className="timeline-box">
                        <div className="timeline-planned" style={{marginLeft: scheduledLeftMargin,
                            width: scheduledWidth, background: process.sst && '#f1f1f1'
                        }}>
                            <div hidden={!process['actual_start'] || ((process.ast - start) / (end - start)) * 100 < 0}
                                 className={`timeline-planned-progress progress_line ${processColor}`}
                                 style={{marginLeft: `${((process.ast - start) / (end - start)) * 100}%`, width: actualWidth}}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {areEventClassesOpen && (
                <div className="detail-item">
                    {eventClasses.map((eventClass) => {
                        return <EventClassRecord key={eventClass['display']} eventClass={eventClass} end={end} ata={ata} start={start}/>
                    })}
                </div>
            )}
        </>
    );
};

const EventClassRecord = ({eventClass, end, ata, start}) => {
    const now = useNow();
    const [areEventsOpen, setEventsOpen] = useState(false);

    const handleEventClassClick = useCallback(() => {
        setEventsOpen((prevState) => !prevState);
    }, []);

    const events = useMemo(() => {
        if (eventClass.events.length === 1 && eventClass.events[0]['display'] === eventClass['display']) {
            return [];
        }

        return eventClass.events;
    }, [eventClass]);

    const eventClassColor = useMemo(() => {
        let color = 'blue';

        if (eventClass.actual && eventClass.actual > eventClass.scheduled) {
            color = 'red';
        }

        if (!eventClass.actual && now > eventClass.scheduled) {
            color = 'red';
        }

        if (eventClass.actual && eventClass.actual <= eventClass.scheduled) {
            color = 'green';
        }

        if (eventClass.actual && !eventClass.scheduled) {
            color = 'black';
        }



        return color;
    }, [eventClass, now]);

    return (
        <>
            <div className="airplane-detail-table-content-row">
                <div className="airplane-detail-table-header-title eventClass">
                    <div className="airplane-detail-table-header-item">
                        {!isEmpty(events) ? (
                            <div className="airplane-detail-table-header-item details-box">
                                <div role="button" tabIndex={0} onKeyPress={noop}
                                     className="box pointer" onClick={handleEventClassClick}>
                                    {!areEventsOpen ? (
                                            <ArrowRight width="32px" style={{transformOrigin: 'center center',}}
                                                        className="airplane-detail-content-icon_color"/>)
                                        :
                                        (<ArrowRight
                                            width="32px"
                                            style={{transform: 'rotate(90deg)', transformOrigin: 'center center'}}
                                            className="airplane-detail-content-icon_color"/>)
                                    }
                                </div>
                                <span className="airplane-detail-table-header-item-title">{eventClass['display']}</span>
                            </div>
                        ) : (
                            <span className="airplane-detail-table-header-item-title">{eventClass['display']}</span>
                        )}
                    </div>
                    <span className="airplane-detail-table-header-item"/>
                    <span className="airplane-detail-table-header-item"/>
                    <span className="airplane-detail-table-header-item"/>
                </div>

                <div className="airplane-detail-table-header-timeline">
                    <div className="inner">
                        {
                            eventClass.actual
                                ? (
                            <div className={classnames('time-circle', eventClassColor)}
                                style={{marginLeft: `calc(${((eventClass.actual - start) / (end - start)) * 100}% - 5px)`}}>
                                <div className="event-timestamp">
                                    <div
                                        className={eventClass.actual && !eventClass.scheduled
                                            ? 'black' : eventClass.actual > eventClass.scheduled
                                                ? 'red' : 'green'
                                        }
                                    >
                                        {defaultFormat(eventClass.actual)}
                                    </div>
                                    <div className="blue">{defaultFormat(eventClass.scheduled)}</div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={classnames('time-circle', eventClassColor)}
                                style={{marginLeft: `${((eventClass.scheduled - start) / (end - start)) * 100}%`}}
                            >
                                <div className="event-timestamp">
                                    <div className="blue">{defaultFormat(eventClass.scheduled)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {areEventsOpen && (
                <div className="detail-item">
                    {events.map((event) => (
                        <EventRecord key={event.id} event={event} end={end} start={start}/>
                    ))}
                </div>
            )}
        </>
    );
};

const EventRecord = ({event, end, start}) => {
    const timeOffsetPercent = useMemo(() => {
        return ((event['timestamp'] - start) / (end - start)) * 100;
    }, [event['timestamp'], end, start]);

    return (
        <>
            <div className="airplane-detail-table-content-row">
                <div className="airplane-detail-table-header-title event">
                    <div className="airplane-detail-table-header-item">
                        <span className="airplane-detail-table-header-item-title">{event['display']}</span>
                    </div>
                    <span className="airplane-detail-table-header-item"/>
                    <span className="airplane-detail-table-header-item"/>
                    <span className="airplane-detail-table-header-item"/>
                </div>

                <div className="airplane-detail-table-header-timeline">
                    <div className="inner">
                        <div className="time-circle black" style={{marginLeft: `calc(${timeOffsetPercent}% - 5px)`}}>
                            <span className="event-timestamp">{defaultFormat(event['timestamp'])}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

DetailTableItem.propTypes = {
    process: PropTypes.shape({
        name: PropTypes.string,
        ast: PropTypes.instanceOf(Date),
        sst: PropTypes.instanceOf(Date),
        aft: PropTypes.instanceOf(Date),
        sft: PropTypes.instanceOf(Date),
        classes: PropTypes.arrayOf(
            PropTypes.shape({name: PropTypes.string, events: PropTypes.arrayOf(PropTypes.shape({}))}),
        ),
    }).isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    ata: PropTypes.instanceOf(Date).isRequired,
};

export default DetailTableItem;
