import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/Modal/Modal';
import GatesForm from '../CameraGatesForm/CameraGatesForm';
import InfoForm from '../CameraInfoForm/CameraInfoForm';
import { cameraAPI } from 'services/api';
import { toast } from 'react-toastify';
import './CameraModal.scss';
import {shallowEqual, useSelector} from "react-redux";

const CameraModal = ({ isOpen, handleModalClose, handleCameraSave, camera: cameraToEdit }) => {
  const [camera, setCamera] = useState(cameraToEdit);
  const [formStep, setFormStep] = useState('info');

  console.log(formStep, '--------FORM STEP----->>>>>> CameraModal component on 13 lin -----')

  const {iata} = useSelector((state) => state.airport.selectedAirport, shallowEqual);

  const handleCameraInfoSubmit = useCallback((editedCamera) => {
    // This is the function that gets executed as soon as you click the submit button when entering the camera's URL and other metadata.
    // if (editedCamera['cctv_id'] !== null){
    //   cameraAPI.createCamera(editedCamera, iata).then(resp => {
    //     let newCamera = resp.data;
    //     newCamera.airport = resp.data.airport.iata;
    //     newCamera['gates'] = [];
    //     setCamera(newCamera);
    //   }).catch(error => {
    //     toast.error('failed to create camera')
    //   })
    // } else {
    //   setCamera(editedCamera);
    // }

    setFormStep('areas');
  }, []);

  const saveCamera = useCallback(
    /**Used after you assign the gates and outline them and click save*/

    (keypoints) => {
      handleCameraSave({ ...camera, gates: keypoints });
      setFormStep('info');
      handleModalClose()
    },
    [camera, handleCameraSave],
  );

  const onModalClose = useCallback(() => {
    /*** Triggered when you click the page/background to close ther modal*/
    setFormStep('info');
    handleModalClose()
  }, []);

  const renderStepContent = useCallback(
    // This determines which form to display on the modal, the camera info or the point map on the frames
    (step) => {
      if (step === 'info') {
        return <InfoForm camera={camera} onSubmit={handleCameraInfoSubmit} iata={iata}/>;
      }

      if (step === 'areas') {
        return <GatesForm camera={camera} onKeypointsSave={saveCamera} />;
      }

      return <h2>Error happened, please refresh the page.</h2>;
    },
    [camera, handleCameraInfoSubmit, saveCamera],
  );

  useEffect(() => {
    setCamera(cameraToEdit);
  }, [cameraToEdit]);

  return (
    <Modal isOpen={isOpen} onModalClose={onModalClose} className="camera-modal">
      {renderStepContent(formStep)}
    </Modal>
  );
};

CameraModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleCameraSave: PropTypes.func.isRequired,
  camera: PropTypes.shape({
    url: PropTypes.string,
    position: PropTypes.string,
    view: PropTypes.string,
    gates: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default CameraModal;
