import React from 'react';
import { Link } from 'react-router-dom';

import { Repeat, Settings, Camera, Users, Support } from 'assets/img/all-icons';

export default function SidebarItem({ icon, label, path, active}) {
  const style = active
    ? 'sidebar-list-item sidebar-list-item--active'
    : 'sidebar-list-item';

  return (
    <li className={style}>
      <Link to={path}>
        {icon === 'repeat' && (
          <Repeat width="17px" className="sidebar-list-item__img" />
        )}
        {icon === 'settings' && (
          <Settings width="20px" className="sidebar-list-item__img" />
        )}
        {icon === 'camera' && (
          <Camera width="20px" className="sidebar-list-item__img" />
        )}
        {icon === 'users' && (
          <Users width="20px" className="sidebar-list-item__img" />
        )}
        {icon === 'support' && (
          <Support width="20px" className="sidebar-list-item__img" />
        )}
        <p className="sidebar-list-item__label">{label}</p>
      </Link>
    </li>
  );
}
