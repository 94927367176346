import React from 'react';
import classnames from 'classnames';

import FlightRecord from './TurnaroundViewRecord';

import s from '../FlightsTable.module.scss';

export default function TurnaroundViewTable({ items }) {
  return (
    <div className={s.table}>
      <div className={s.head}>
        <div className={classnames(s.th)} style={{ flexBasis: '35px' }} />
        <div className={classnames(s.th, s.flex)}>Arrival Flight</div>
        <div className={classnames(s.th, s.flex)}>Departure Flight</div>
        <div className={classnames(s.th, s.flex)}>Route</div>
        <div className={classnames(s.th, s.flex)}>Aircraft type</div>
        <div className={classnames(s.th, s.flex)}>Tail number</div>
        <div className={classnames(s.th, s.flex)}>Turn type</div>
        <div className={classnames(s.th, s.flex)}>Ground time</div>
        <div className={classnames(s.th)} style={{ flexBasis: '15%' }}>
          Latest event
        </div>
        <div className={classnames(s.th, s.flex)}>EOBT</div>
        <div className={classnames(s.th)} style={{ flexBasis: '30px' }} />
      </div>
      <div className={s.body}>
        {items.map((item) => (
          <FlightRecord key={item['_id']} turnaround={item} />
        ))}
      </div>
    </div>
  );
}
