import { IntellActAPI } from './api';

export default {
  fetchServices() {
    return IntellActAPI.get('/event/tree/');
  },
  getProcesses() {
    return IntellActAPI.get('/process/', { params: { limit: 500 } });
  },
  createProcess(process) {
    return IntellActAPI.post('/process/', process);
  },
  updateProcess(processId, process) {
    return IntellActAPI.put(`/process/${processId}/`, process);
  },
  deleteProcess(processId) {
    return IntellActAPI.delete(`/process/${processId}/`);
  },
  getEventClasses() {
    return IntellActAPI.get('/class/', { params: { limit: 500 } });
  },
  createEventClass(eventClass) {
    return IntellActAPI.post('/class/', eventClass);
  },
  updateEventClass(eventClassId, eventClass) {
    return IntellActAPI.put(`/class/${eventClassId}/`, eventClass);
  },
  deleteEventClass(eventClassId) {
    return IntellActAPI.delete(`/class/${eventClassId}/`);
  },
  getEvents() {
    return IntellActAPI.get('/event/', { params: { limit: 500 } });
  },
  createEvent(event) {
    return IntellActAPI.post('/event/', event);
  },
  updateEvent(eventId, event) {
    return IntellActAPI.put(`/event/${eventId}/`, event);
  },
  deleteEvent(eventId) {
    return IntellActAPI.delete(`/event/${eventId}/`);
  },
  getEventRelations() {
    return IntellActAPI.get('/relationships/', { params: { limit: 500 } });
  },
  createEventRelation(event) {
    return IntellActAPI.post('/relationships/', event);
  },
  updateEventRelation(eventId, event) {
    return IntellActAPI.put(`/relationships/${eventId}/`, event);
  },
  deleteEventRelation(eventId) {
    return IntellActAPI.delete(`/relationships/${eventId}/`);
  },
};
