import { omit } from 'lodash';
import { toast } from 'react-toastify';
import { standardsAPI } from 'services/api';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

export const fetchStandards = async (airlineICAO) => {
  try {
    const response = await standardsAPI.fetchStandards(airlineICAO);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
  return null;
};

export const createStandard = async (airlineICAO, standard) => {
  try {
    const response = await standardsAPI.createStandard(airlineICAO, standard);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Standard created successfully');
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
  return null;
};

export const updateStandard = async (airlineICAO, standard) => {
  try {
    const response = await standardsAPI.updateStandard(airlineICAO, standard.id, omit(standard, 'id'));
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Standard updated successfully');
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
  return null;
};

export const deleteStandard = async (airlineICAO, standardId) => {
  try {
    const response = await standardsAPI.deleteStandard(airlineICAO, standardId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Standard deleted successfully');
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
  return null;
};
