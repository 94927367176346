import React, {useState, useCallback, useEffect, useMemo,} from 'react';
import {useAbility} from '@casl/react';

import {Plus} from 'assets/img/all-icons';

import Can, {AbilityContext} from 'services/Can';
import {EDIT} from 'services/ability';
import './Airport.scss';
import AccordionItem from "../AccordionItem";

export const Accordion = ({data, iata}) => {
    const [items, setItems] = useState(data);

    const [shouldAutoFocusTerminal, setShouldAutoFocusTerminal] = useState(false);
    const [shouldAutoFocusGate, setShouldAutoFocusGate] = useState(false);

    const ability = useAbility(AbilityContext);

    const allowedToManage = useMemo(
        () => ability.can(EDIT, {type: 'airports', iata}),
        [ability, iata],
    );

    const handleNewGate = useCallback(
        (terminalName) => {
            const gates = items.find(({name}) => name === terminalName);
            // const {gates} = items.find(({ id }) => id === terminalId);
            if (!gates[gates.length - 1]?.new) {
                setItems((prevState) =>
                    prevState.map((terminal) =>
                        terminal.name !== terminalName
                            ? terminal
                            : {
                                ...terminal,
                                gates: terminal.gates.concat({
                                    name: '',
                                    cameras: [],
                                }),
                            },
                    ),
                );
                setShouldAutoFocusGate(true);
            }
        },
        [items],
    );
    //
    // const handleNewGate = useCallback(
    //     (terminalName) => {
    //         const gates = items.find(({name}) => name === terminalName);
    //         // const {gates} = items.find(({ id }) => id === terminalId);
    //         if (!gates[gates.length - 1]?.new) {
    //             setItems((prevState) =>
    //                 prevState.map((terminal) =>
    //                     terminal.name !== terminalName
    //                         ? terminal
    //                         : {
    //                             ...terminal,
    //                             gates: terminal.gates.concat({
    //                                 id: -1,
    //                                 name: '',
    //                                 cameras: [],
    //                                 new: true,
    //                             }),
    //                         },
    //                 ),
    //             );
    //             setShouldAutoFocusGate(true);
    //         }
    //     },
    //     [items],
    // );

    const handleNewTerminal = useCallback(() => {
        if (!items[items.length - 1]?.new) {
            setItems((prevState) =>
                prevState.concat({gates: [], id: -1, name: '', new: true}),
            );
            setShouldAutoFocusTerminal(true);
        }
    }, [items]);

    // useEffect(() => {
    //   setItems(data);
    // }, [data]);

    return (
        <div className="accordion">
            {items?.length > 0 ? (
                items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => {
                        return (
                            <AccordionItem iata={iata} key={item.id} terminal={item} addNewGate={handleNewGate}
                                           shouldAutoFocusGate={shouldAutoFocusGate}
                                           shouldAutoFocusTerminal={shouldAutoFocusTerminal}
                                           setShouldAutoFocusTerminal={setShouldAutoFocusTerminal}
                                           setShouldAutoFocusGate={setShouldAutoFocusGate}
                                           allowedToManage={allowedToManage}
                            />
                        )
                    })
            ) : (
                <h2 style={{marginTop: 100, textAlign: 'center'}}>
                    This airport currently has no terminals.
                </h2>
            )}
            <Can I={EDIT} this={{type: 'airports', iata}}>
                <div className="accordion-add">
                    <button
                        type="button"
                        className="accordion-add-box"
                        onClick={handleNewTerminal}
                    >
                        <Plus width="36px" className="accordion-add__icon"/>
                        <span className="accordion-add__title">Add terminal</span>
                    </button>
                </div>
            </Can>
        </div>
    );
};