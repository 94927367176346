import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { differenceInMinutes } from 'date-fns';
import { concat, flow, isEmpty, last, map, uniq } from 'lodash';
import flatMap from 'lodash/fp/flatMap';
import sortBy from 'lodash/fp/sortBy';

import { Bell, Clock } from 'assets/img/all-icons';
import useNow from 'utils/useNow';
import { defaultFormat, getFormattedDuration, parseTurnType } from 'utils/helpers';
import { getIconByMode, getMode } from 'utils/modes';
import { routes } from 'store/constants/ui.constants';

import s from '../FlightsTable.module.scss';

const getStatusCircle = (status) => {
  switch (status) {
    case 'delay':
      return <div className={classnames(s.statusCircle, s.red)} />;
    case 'fixes':
      return <div className={classnames(s.statusCircle, s.orange)} />;
    default:
      return <div className={classnames(s.statusCircle, s.green)} />;
  }
};

const GateViewRecord = ({ record: { turnaround, gate }, alerts }) => {
  const now = useNow();

  const [isModeHovered, setModeHovered] = useState(false);

  const handleModeHover = useCallback(() => {
    setModeHovered(true);
  }, []);

  const handleModeUnhover = useCallback(() => {
    setModeHovered(false);
  }, []);

  const status = useMemo(() => {
    return '';
  }, []);

  const currentFlight = useMemo(() => {
    const { airline, arrival_flight_number: arrivalNumber, departure_flight_number: departureNumber } = turnaround;

    return `${airline} ${gate.type === 'incoming' ? arrivalNumber : departureNumber}`;
  }, [turnaround, gate.type]);

  const turnType = useMemo(() => parseTurnType(turnaround.turn_type, true), [turnaround.turn_type]);

  const turnaroundMode = useMemo(() => {
    const statuses = flow([concat, uniq])(
      map(turnaround.incoming_gate.cameras, 'mode'),
      map(turnaround.outgoing_gate?.cameras, 'mode'),
      map(turnaround.parking_gate?.cameras, 'mode'),
    );

    return getMode(statuses);
  }, [turnaround]);

  const route = useMemo(() => {
    const { current_airport: currentAirport, arrival_airport: arrivalAirport } = turnaround;

    return `${currentAirport} - ${arrivalAirport}`;
  }, [turnaround]);

  const groundTime = useMemo(() => {
    const start = turnaround.ata || now;
    const end = turnaround.atd && now > turnaround.atd ? turnaround.atd : now;

    return getFormattedDuration(end - start);
  }, [turnaround, now]);

  const latestEvent = useMemo(() => {
    const latest = flow([flatMap('classes'), sortBy('actual_ts'), last])(turnaround.processes);

    return latest;
  }, [turnaround.processes]);

  const eobt = useMemo(() => {
    const duration = turnaround.std ? <span className={s.blue}>{defaultFormat(turnaround.std)}</span> : '';

    let delay = '';
    const diff = differenceInMinutes(turnaround.atd || now, turnaround.std);

    if (diff > 0) {
      delay = <span style={{ color: 'red', marginLeft: 5 }}>{`(+${diff})`}</span>;
    } else if (!turnaround.atd) {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>(0)</span>;
    } else {
      delay = <span style={{ color: 'green', marginLeft: 5 }}>{`(${diff})`}</span>;
    }

    return (
      <>
        {duration} {delay}
      </>
    );
  }, [now, turnaround.atd, turnaround.std]);

  const eibt = useMemo(() => {
    const duration = turnaround.sta ? <span className={s.blue}>{defaultFormat(turnaround.sta)}</span> : '';

    let delay = '';
    if (turnaround.ata || now > turnaround.sta) {
      const diff = differenceInMinutes(turnaround.ata || now, turnaround.sta);

      if (diff > 0) {
        delay = <span style={{ color: 'red', marginLeft: 5 }}>{`(+${diff})`}</span>;
      } else {
        delay = <span style={{ color: 'green', marginLeft: 5 }}>{`(${diff})`}</span>;
      }
    }

    return (
      <>
        {duration} {delay}
      </>
    );
  }, [now, turnaround.ata, turnaround.sta]);

  const nextFlight = useMemo(() => {
    if (!turnaround.next_turnaround) return null;

    const { airline, flight_number: flightNumber } = turnaround.next_turnaround;

    return `${airline} ${flightNumber}`;
  }, [turnaround]);

  const modeDetailsCameras = useMemo(() => {
    return gate.cameras;
  }, [gate]);

  return (
    <div>
      <Link to={`${routes.TURNAROUND}/${turnaround.id}`}>
        <div className={s.row}>
          <div className={classnames(s.td)} style={{ flexBasis: '35px' }}>
            {getStatusCircle(status)}
          </div>
          <div className={classnames(s.td)} style={{ flexBasis: '8%' }}>
            {gate.name}
          </div>
          <div className={classnames(s.td, s.flex)}>{currentFlight}</div>
          <div className={classnames(s.td, s.flex)}>
            <div className={s.paddingRight}>
              <Clock width="18px" fill="#1f80b4" />
            </div>
            {groundTime}
          </div>
          <div className={classnames(s.td, s.bold, s.flex)}>{eobt}</div>
          <div className={classnames(s.td, s.flex)}>{route}</div>
          <div className={classnames(s.td, s.flex)}>{turnType}</div>
          <div className={classnames(s.td)} style={{ flexBasis: '15%' }}>
            {latestEvent ? (
              <>
                <div className={classnames(s.paddingRight)}>
                  <Clock width="18px" fill="#1f80b4" />
                </div>
                <div>{latestEvent.name}</div>
              </>
            ) : (
              <span className={s.gray}>No events</span>
            )}
          </div>
          <div className={classnames(s.td, s.flex)}>{nextFlight || <span className={s.gray}>No flight</span>}</div>
          <div className={classnames(s.td, s.flex, s.bold)}>{eibt}</div>
          <div className={classnames(s.td, s.capitalize, s.blue, s.turnaroundMode)} style={{ flexBasis: '50px' }}>
            <div className={s.paddingRight} onMouseEnter={handleModeHover} onMouseLeave={handleModeUnhover}>
              {getIconByMode(turnaroundMode)}
            </div>
            <div className={classnames(s.paddingRight, s.alerts)} style={{ marginLeft: 5 }}>
              <Bell width="16px" height="16px" fill={isEmpty(alerts) ? '#a8a8a8' : '#1f80b4'} />
              {!isEmpty(alerts) && <div className={s.numberOfAlerts}>{alerts.length}</div>}
            </div>
            {isModeHovered && (
              <div className={s.modePopup}>
                <div className={s.mode}>
                  <span className={s.modeLabel}>Mode:</span> {turnaroundMode}
                </div>
                <div className={classnames(s.item, s.title)}>Cameras:</div>
                {modeDetailsCameras.map((camera) => (
                  <div key={camera.id} className={s.item}>
                    <div>{camera.url}</div>
                    <div>{camera.status}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

GateViewRecord.propTypes = {
  record: PropTypes.shape({
    turnaround: PropTypes.shape({
      id: PropTypes.string.isRequired,
      arrival_flight_number: PropTypes.string,
      departure_flight_number: PropTypes.string,
      airline: PropTypes.string,
      arrival_airport: PropTypes.string,
      departure_airport: PropTypes.string,
      current_airport: PropTypes.string,
      turn_type: PropTypes.string,
      std: PropTypes.instanceOf(Date),
      atd: PropTypes.instanceOf(Date),
      sta: PropTypes.instanceOf(Date),
      ata: PropTypes.instanceOf(Date),
      incoming_gate: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        cameras: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string })),
      }),
      outgoing_gate: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        cameras: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string })),
      }),
      parking_gate: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        cameras: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string })),
      }),
      processes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
    }).isRequired,
    gate: PropTypes.shape({ type: PropTypes.string, id: PropTypes.number, name: PropTypes.string }),
  }).isRequired,
};

export default GateViewRecord;
