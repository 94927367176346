import React from 'react';
import PropTypes from 'prop-types';

import { SelectDownBlue, SelectUpBlue } from 'assets/img/all-icons';

import './TimeLine.scss';
import { getFormattedDuration } from 'utils/helpers';

const Timeline = ({ offset, duration, withTime }) => {
  return (
    <div className="timeline time-line" style={{left: offset}}>
      {withTime && <div className="current-time">{getFormattedDuration(duration)}</div>}
      <SelectDownBlue width="10px" className="timeline-arrow-top" />
      <SelectUpBlue width="10px" className="timeline-arrow-bottom" />
    </div>
  );
};

Timeline.propTypes = {
  offset:  PropTypes.string || PropTypes.number.isRequired,
  duration: PropTypes.number,
  withTime: PropTypes.bool,
};

Timeline.defaultProps = {
  withTime: false,
  duration: 0,
};

export default Timeline;
