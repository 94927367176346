import React from 'react';

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const NotFoundPage = () => {
  return (
    <div style={styles}>
      <span>Page Not Found</span>
    </div>
  );
};
export default NotFoundPage;
