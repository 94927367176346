import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';

import Confirmation from 'components/common/Confirmation/Confirmation';
import { Airlines, Airport, DeparturingFlight, CardClose } from 'assets/img/all-icons';

import { routes } from 'store/constants/ui.constants';
import { countryAPI } from 'services/api';
import { deleteUser } from 'store/controllers/user.controllers';

import './AccountsItem.scss';

// const AccountItem = ({ type, login, name, type_code, id, is_superuser,  }) => {
  const AccountItem = ({ type, login, name, type_code, id, is_superuser, email, is_active, company_name, company_type, default_airport }) => {
  const dispatch = useDispatch();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [country, setCountry] = useState({
    name: '',
    code: '',
  });

  const handleAccountDelete = useCallback(() => {
    dispatch(deleteUser(email));
    setConfirmationOpen(false);
  }, [dispatch, email]);

  const onDeleteClick = useCallback((e) => {
    e.stopPropagation();
    setConfirmationOpen(true);
  }, []);

  const onDeleteCancel = useCallback(() => {
    setConfirmationOpen(false);
  }, []);

  useEffect(() => {
    const getCountryData = async () => {
      const result = await countryAPI.getCountryByIata(type, type_code);
      setCountry({ ...result });
    };
    if (type) {
      getCountryData();
    }
  }, [type_code, type]);

  return (
    <div className="accounts-card-item">
      <div className="accounts-card-item__country">
        <div className="header">
          {!is_superuser && (
            <ReactCountryFlag
              countryCode={country.code}
              svg
              style={{ width: '16px', height: '16px' }}
              className="accounts-card-item__icon"
            />
          )}
          <p className="accounts-card-item__country-name">{is_superuser ? 'Superuser' : country.name}</p>
        </div>
        <button type="button" className="account-delete-button" onClick={onDeleteClick}>
          <CardClose width="36px" className="account-delete-button-icon" />
        </button>
      </div>
      <Link to={`${routes.EDIT_ACCOUNT}/${email}`}>
        <div className="accounts-card-item__label">
          {type === 'airline' && <Airlines width="15px" className="accounts-card-item__icon" />}
          {type === 'airport' && <Airport width="15px" className="accounts-card-item__icon" />}
          {type === 'departuringFlight' && <DeparturingFlight width="12px" className="accounts-card-item__icon" />}
          <p className="accounts-card-item__title">{name}</p>
        </div>
        <p className="accounts-card-item__name">{login}</p>
      </Link>

      <Confirmation
        question="Do you want to delete an account?"
        isOpen={isConfirmationOpen}
        onConfirm={handleAccountDelete}
        onCancel={onDeleteCancel}
      />
    </div>
  );
};

export default AccountItem;
