import {
  SET_ACCOUNTS_LIST,
  SET_CURRENT_PAGE,
  SET_TOTAL_ACCOUNTS_COUNT,
  TOGGLE_IS_FETCHING,
  UPDATE_ACCOUNT_FILTERS,
  REMOVE_ACCOUNT_FILTER,
  DELETE_ACCOUNT,
} from 'store/constants/account.constants';

const initialState = {
  accounts: [],
  perPage: 15,
  currentPage: 1,
  total: 0,
  isFetching: true,
  filter: {
    userType: [],
    clientType: [],
    name: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS_LIST:
      return {
        ...state,
        accounts: action.accounts,
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };

    case SET_TOTAL_ACCOUNTS_COUNT:
      return {
        ...state,
        total: action.total,
      };

    case TOGGLE_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case UPDATE_ACCOUNT_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.alias || 'unknown']: [action.payload],
        },
      };
    case REMOVE_ACCOUNT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.type]: [],
        },
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter(({ email }) => email !== action.payload),
      };
    default:
      return state;
  }
};
