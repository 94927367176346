import React from 'react';
import Button from "../../../ui/Button/Button";
import {CloseRed} from "../../../../assets/img/all-icons";

const ButtonsViewAndClose = ({onClose, handleShowTurn}) => {
    return (
        <div className="airplane-detail-header-button-group">
            <Button onClick={handleShowTurn} className="view-button">View</Button>
            <CloseRed width="36px" className="airplane-detail-close pointer " onClick={onClose}/>
        </div>
    );
};

export default ButtonsViewAndClose;