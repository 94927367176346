import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Input from 'components/ui/MyInput/MyInput';
import Button from 'components/ui/Button/Button';
import Confirmation from 'components/common/Confirmation/Confirmation';

import {deleteProcess, fetchProcesses, createProcess} from 'store/controllers/services.controllers';

import s from './ProcessList.module.scss';

const ProcessList = () => {
    const dispatch = useDispatch();
    const [newProcess, setNewProcess] = useState({name: ''});
    const [processToDelete, setProcessToDelete] = useState(null);
    const {processes} = useSelector((state) => state.services);

    useEffect(() => {
        dispatch(fetchProcesses());
    }, [dispatch]);

    const onDeleteClick = useCallback((processId) => {
        setProcessToDelete(processId);
    }, []);

    const handleProcessDelete = useCallback(() => {
        dispatch(deleteProcess(processToDelete));
        setProcessToDelete(null);
    }, [dispatch, processToDelete]);

    const onDeleteCancel = useCallback(() => {
        setProcessToDelete(false);
    }, []);

    const handleNewProcessNameChange = useCallback((e) => {
        setNewProcess({name: e.target.value});
    }, []);

    const handleProcessCreate = useCallback(() => {
        dispatch(createProcess(newProcess));
        setNewProcess({name: ''});
    }, [dispatch, newProcess]);

    return (
        <div>
            <div className={s.newProcessContainer}>
                <h6>Create new process</h6>
                <div className={s.newProcessInner}>
                    <div className={s.newProcessInput}>
                        <Input id="new-process-name" value={newProcess.name} onChange={handleNewProcessNameChange}/>
                    </div>
                    <Button onClick={handleProcessCreate}>Create</Button>
                </div>
            </div>

            <div className={s.list}>
                {processes.map((process) => (
                    <div key={process.id} className={s.container}>
                        <div className={s.header}>
                            <div className={s.title} style={{fontSize: 18}}>
                                {process.name}
                            </div>
                            <Button type="secondary-light" onClick={() => onDeleteClick(process.id)}>Delete</Button>
                        </div>
                    </div>
                ))}
            </div>

            <Confirmation
                question="Do you want to delete a process?"
                isOpen={!!processToDelete}
                onConfirm={handleProcessDelete}
                onCancel={onDeleteCancel}
            />
        </div>
    );
};

export default ProcessList;
