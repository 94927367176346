import React, {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker from 'react-date-picker';

import Header from 'components/common/Header/Header';

import actions from 'store/actions/ui.actions';
import {routes} from 'store/constants/ui.constants';
import processActions from 'store/actions/process.actions';

import FlightsTracker from './FlightsTracker/FlightsTracker';
import ProcessFilter from '../ProcessFilter';

import './Monitoring.scss';

import * as Icons from 'assets/img/all-icons';

const Monitoring = () => {
    const dispatch = useDispatch();
    const {filter} = useSelector(({processes}) => processes);
    const {Close} = Icons;

    function checkDateIconDatePicker() {
        const presentTime = new Date().toLocaleDateString();
        const timeInFilter = new Date(filter.date).toLocaleDateString();

        if (presentTime !== timeInFilter) return <Close width="18px" height="18" fill="#0078d7"/>

        return null
    }

    const onDateChange = useCallback(
        (newDate) => {
            dispatch(processActions.fetchProcessesSuccess({items: []}));
            dispatch(processActions.fetchProcessesRequest());
            dispatch(processActions.changeDates(newDate));
        },
        [dispatch],
    );


    useEffect(() => {
        dispatch(actions.changePage(routes.TURNAROUND));
        dispatch(processActions.setPaginate(false));
    }, [dispatch])


    return (
        <div className="flights-container">
            <Header>
                <nav className="action">
                    <div className="datepicker">
                        <span className="datepicker__label">Date:</span>
                        <DatePicker onChange={onDateChange} value={filter.date} clearIcon={checkDateIconDatePicker()}/>
                    </div>
                    <ProcessFilter/>
                </nav>
            </Header>

            <FlightsTracker/>
        </div>
    );
};

export default Monitoring;
