import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SearchInput from 'components/ui/SearchInput/SearchInput';
import apiActions from 'store/actions/api.actions';

const SearchApi = () => {
  const dispatch = useDispatch();
  const { query } = useSelector(({ api }) => api);

  const onQueryChange = useCallback(
    (value) => {
      dispatch(apiActions.changeQuery(value));
    },
    [dispatch],
  );

  return (
    <div>
      <SearchInput value={query} onChange={onQueryChange} />
    </div>
  );
};

export default SearchApi;
