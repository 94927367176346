import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import omit from 'lodash/omit';
import Filter from 'components/ui/Filter/Filter';
import { fetchTerminalsByAirport } from 'store/controllers/airport.controllers';
import { createGetAirportsHandler } from 'store/handlers';
import { fetchAirlines } from 'store/controllers/airline.controllers';
import processActions from 'store/actions/process.actions';
import { fetchGatesByAirport } from 'store/controllers/gate.controllers';
import userActions from "../../store/actions/user.actions";

const fetchAirports = createGetAirportsHandler();

const AdministrationFilter = () => {
  const dispatch = useDispatch();
  const {processes: { filter }, user: { permissions, default_airport},} = useSelector((state) => state);

  const updateFilter = useCallback(
    (newFilter) => {
        console.log(newFilter, "newFilter__newFilter__newFilter")
        dispatch(processActions.updateFilters(newFilter));
        dispatch(userActions.changeDefAirport(newFilter?.value.name))
    },
    [dispatch],
  );

  const removeFilter = useCallback(
    (filterType, filterId) => {
      dispatch(processActions.removeFilter(filterType, filterId));
    },
    [dispatch],
  );

  const getAggregatedTerminals = useCallback(async () => {
    // if airport filter is applied, filter by them
    // otherwise filter by airports from permissions list
    const airports =
      filter.airports.length > 0
        ? filter.airports.map(({ value }) => value.item_id)
        : permissions.map(({ airport }) => airport);

    const terminals = (await Promise.all(airports.map((iata) => fetchTerminalsByAirport(iata)))).flatMap((items, i) =>
      items.map((terminal) => ({
        ...terminal,
        airport: airports[i],
      })),
    );
    return terminals;
  }, [filter.airports, permissions]);

  const getAggregatedGates = useCallback(async () => {
    // if airport filter is applied, filter by them
    // otherwise filter by airports from permissions list
    const airports =
      filter.airports.length > 0
        ? filter.airports.map(({ value }) => value.item_id)
        : permissions.map(({ airport }) => airport);

    const gates = (await Promise.all(airports.map((iata) => fetchGatesByAirport(iata)))).flatMap((items, i) =>
      items.map((gate) => ({
        ...gate,
        airport: airports[i],
      })),
    );
    return gates;
  }, [filter.airports, permissions]);

  const optionEntities = useMemo(
    () => [
      {
        name: 'Airline',
        alias: 'airlines',
        icon: 'Airlines',
        type: 'input',
        async resolver(query) {
          const response = await fetchAirlines(50);
          return response.map((item) => ({
            id: item.icao,
            name: item.name,
            extraLabel: item.icao,
          }));
        },
      },
      {
        name: 'Airport',
        alias: 'airports',
        icon: 'Airport',
        type: 'input',
        async resolver(query) {
          const airports = await fetchAirports(50);
          return airports.map((item) => ({
            id: item.iata,
            name: item.name,
            extraLabel: item.iata,
          }));
        },
      },
      {
        id: 3,
        name: 'Flight',
        alias: 'flights',
        icon: 'Flight',
        type: 'input',
        async resolver() {
          return [];
        },
      },
      {
        id: 4,
        name: 'Terminal',
        alias: 'terminals',
        icon: 'Terminal',
        type: 'input',
        // async resolver(query) {
        //   const terminals = await getAggregatedTerminals();
        //   return terminals
        //     .filter(({ name }) => name.includes(query))
        //     .map(({ id, name, airport }) => ({
        //       id,
        //       name,
        //       extraLabel: airport,
        //     }));
        // },
      },
      {
        name: 'Gate',
        alias: 'gates',
        icon: 'Gate',
        type: 'input',
        async resolver(query) {
          const gates = await getAggregatedGates();
          return gates
            .filter(({ name }) => name.includes(query))
            .map(({ id, name, airport }) => ({
              id,
              name,
              extraLabel: airport,
            }));
        },
      },
      {
        name: 'Status',
        alias: 'status',
        icon: 'Status',
        type: 'select',
        choices: [
          { label: 'Realtime', value: 'realtime' },
          { label: 'Historical', value: 'historical' },
        ],
        async resolver() {
          return [];
        },
      },
    ],
    [getAggregatedGates, getAggregatedTerminals],
  );

    console.log(optionEntities, "optionEntities__optionEntities__optionEntities")

  const flatFilters = useMemo(() => {
    const filterWithoutDate = omit(filter, 'date');
    return Object.values(filterWithoutDate).flat();
  }, [filter]);

    console.log(flatFilters, "flatFilters__flatFilters__flatFilters")

  return (
    <div>
      <Filter onNewFilter={updateFilter} onFilterRemove={removeFilter} options={optionEntities} filters={flatFilters} airport={default_airport} />
    </div>
  );
};

export default AdministrationFilter;
