export const FETCH_CAMERAS_LOADING = 'FETCH_CAMERAS_LOADING';
export const FETCH_CAMERAS_ERROR = 'FETCH_CAMERAS_ERROR';
export const FETCH_CAMERAS_SUCCESS = 'FETCH_CAMERAS_SUCCESS';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const UPDATE_CAMERA = 'UPDATE_CAMERA';
export const CREATE_CAMERA = 'CREATE_CAMERA';
export const DELETE_CAMERA = 'DELETE_CAMERA';

export const CAMERA_MODES = {
  ACTIVE: 'active',
  ONBOARDING: 'onboarding',
  FIXES: 'fixes',
  ONBOARDING_FIXES: 'fixes/onboarding',
};

export const PAGE_SIZE = 15;
