import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Modal.scss';

const Modal = ({ isOpen, onModalClose, className, children }) => {
  const [formState, setFormState] = useState('initial');

  const onModalClick = useCallback(() => {
    /** also part of the modal closing sequence*/
    setFormState('opened');
    onModalClose()
  }, []);

  return (
    <div id="modal-container" className={classnames({'modal-open': isOpen, 'modal-open out': !isOpen && formState === 'opened',})}>
      <div role="button" tabIndex="0" className="modal-background" onClick={onModalClick}>
        <div className={classnames('modal-inner', className)} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
