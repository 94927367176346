import {
  PAGE_CHANGE,
  PROCESS_VIEW_CHANGE,
  processViews,
} from 'store/constants/ui.constants';

const initialState = {
  page: '',
  processView: localStorage.getItem('processView') || processViews.ADMIN,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_CHANGE:
      return { ...state, page: action.payload };
    case PROCESS_VIEW_CHANGE:
      return { ...state, processView: action.payload };
    default:
      return state;
  }
};
