import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { uniqBy, isEmpty } from 'lodash';
import 'react-tabs/style/react-tabs.css';

import Header from 'components/common/Header/Header';
import AirportFilter from 'components/ui/AirportFilter/AirportFilter';
import ProfileGeneral from 'components/profile/profileGeneral/ProfileGeneral';
import ProfileTurnaround from 'components/profile/profileTurnaround/ProfileTurnaround';
import { Preloader } from 'components/common/Preloader/Preloader';

import { updatePassword, updateUser } from 'store/controllers/user.controllers';
import { fetchEventClasses } from 'store/controllers/services.controllers';
import { createGetAirportsHandler } from 'store/handlers';

import s from './Profile.module.scss';

const fetchAirports = createGetAirportsHandler(50);

const Profile = () => {
  const dispatch = useDispatch();
  const { default_airport, permissions: userPermissions, is_superuser } = useSelector((state) => state.user);
  const { eventClasses, isLoading: areServicesLoading } = useSelector((state) => ({
    ...state.services,
    eventClasses: state.services.eventClasses,
  }));

  const [selectedPane, setSelectedPane] = useState(0);
  const [permissions, setPermissions] = useState(userPermissions);
  const [airports, setAirports] = useState([]);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [airportSearchQuery, setAirportSearchQuery] = useState('');
  const [selectedAirport, setSelectedAirport] = useState(null);

  const handleInfoSave = useCallback(
    ({ newPassword, passwordConfirmation, airport }) => {
      if (passwordConfirmation && newPassword) {
        updatePassword({ newPassword, passwordConfirmation });
      }
      if (airport !== default_airport) {
        dispatch(updateUser({ default_airport: airport }));
      }
    },
    [default_airport, dispatch],
  );

  const loadAirports = useCallback(() => {
    fetchAirports(selectedAirport?.iata, 20)
      .then((data) => {
        setAirports(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedAirport]);

  const onSearchAirportClick = useCallback(() => {
    setSearchOpen((prevState) => !prevState);
  }, []);

  const closeSearch = useCallback(() => {
    setSearchOpen(false);
  }, []);

  const handleAirportSelect = useCallback((airport) => {
    setSelectedAirport(airport);
  }, []);

  const onSearchQueryChange = useCallback((e) => {
    if (isEmpty(e.target.value)) setSelectedAirport(null);
    setAirportSearchQuery(e.target.value);
  }, []);

  useEffect(() => {
    dispatch(fetchEventClasses());
  }, [dispatch]);

  useEffect(() => {
    if (is_superuser) {
      loadAirports();
    }
  }, [is_superuser, loadAirports]);

  useEffect(() => {
    if (is_superuser) {
      const newPermissions = uniqBy(
        userPermissions
          .map((permission) => ({
            ...permission,
            eventClasses,
            // criticalEventClasses: []
            // processes: eventClasses.map((eventClass) => {
            //   const existingEventClass = permission.eventClasses.find(({ name }) => name === eventClass.name);

            //   if (existingEventClass) {
            //     return {
            //       ...existingEventClass,
            //       critical_event_classes: uniqBy(existingProcess.critical_event_classes.concat(process.classes), 'id'),
            //     };
            //   }

            //   return { ...process, critical_event_classes: process.classes };
            // }),
          }))
          .concat(
            airports.map(({ iata }) => ({ airport: iata, is_manager: true, eventClasses, criticalEventClasses: [] })),
          ),
        'airport',
      );

      setPermissions(newPermissions);
    }
  }, [airports, is_superuser, eventClasses, userPermissions]);

  if (areServicesLoading) return <Preloader />;

  return (
    <div>
      <Header>
        <h2 className={s.header}>User Profile</h2>
        {selectedPane === 1 && (
          <AirportFilter
            title="Search airport"
            isOpen={isSearchOpen}
            onClick={onSearchAirportClick}
            closeFilter={closeSearch}
            query={airportSearchQuery}
            onQueryChange={onSearchQueryChange}
            onAirportClick={handleAirportSelect}
          />
        )}
      </Header>
      <div className={s.container}>
        <Tabs selectedIndex={selectedPane} onSelect={(index) => setSelectedPane(index)}>
          <TabList className={s.tabList}>
            <Tab key="general" className={s.tab} selectedClassName={s.selectedTab}>
              General
            </Tab>
            <Tab key="permissions" className={s.tab} selectedClassName={s.selectedTab}>
              Turnaround
            </Tab>
          </TabList>

          <TabPanel key="general">
            <div className={s.content}>
              <ProfileGeneral
                defaultAirport={default_airport}
                airports={permissions.map(({ airport }) => airport)}
                onInfoSave={handleInfoSave}
              />
            </div>
          </TabPanel>
          <TabPanel key="permissions">
            <div className={s.content}>
              <ProfileTurnaround permissions={permissions} />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Profile;
