export const FETCH_TURNAROUND_REQUEST = 'FETCH_TURNAROUND_REQUEST';
export const FETCH_TURNAROUND_ERROR = 'FETCH_TURNAROUND_ERROR';
export const FETCH_TURNAROUND_SUCCESS = 'FETCH_TURNAROUND_SUCCESS';

export const PAGE_SIZE = 10;
export const CHANGE_PAGE = 'TURNAROUND.CHANGE_PAGE';


export const CLOSE_TURNAROUND_CONNECTION = 'CLOSE_TURNAROUND_CONNECTION'
export const IS_CRITICAL_PATH = 'IS_CRITICAL_PATH'

export const CRITICAL_LISTENER = 'CRITICAL_LISTENER'
export const CRITICAL_ERRORS = 'CRITICAL_ERRORS'
export const GET_CRITICAL_PATHS = 'GET_CRITICAL_PATHS'
export const GET_UPDATE_CRITICAL_PATH = 'GET_UPDATE_CRITICAL_PATH'

