import { IntellActAPI } from './api';

export default {
  createAccount(account) {
    return IntellActAPI.post('/account/', account);
  },
  getAccounts(args) {
    return IntellActAPI.get('/account',
        // {params: { paginate: 1, ...args }}
    );
  }, // without /
  getAccount(id) {
    return IntellActAPI.get(`/account/${id}`);
  }, //without /
  updateAccount(id, account) {
    return IntellActAPI.put(`/account/${id}/`, account);
  },
};
