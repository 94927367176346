import React from 'react';
import classnames from 'classnames';

import s from './Button.module.scss';

const Button = ({type, disabled, onClick, icon, className, children, ...rest}) => {
    return (
        <button
            type="button"
            className={classnames(s.button, s[type], className)}
            onClick={onClick} disabled={disabled} {...rest}>
            {icon && <div className={classnames(s.icon, {[s.marginRight]: children})}>{icon}</div>}
            {children}
        </button>
    );
};

export default Button;
