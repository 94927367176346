import {toast} from 'react-toastify';
import {stopSubmit, SubmissionError} from 'redux-form';
import userActions from 'store/actions/user.actions';
import {initialState} from 'store/reducers/user.reducer';
import {authAPI, airportAPI} from 'services/api';
import {SUPERUSER_ROLE, CLIENT_MANAGER_ROLE} from 'store/constants/user.constants';
import airportActions from 'store/actions/airport.actions';
import {checkSuccessfulStatus, defaultError} from 'store/helpers';
import accountsActions from 'store/actions/accounts.actions';

const defineRole = (user) => {
    const u = {...user};
    if (u.is_superuser) u.role = SUPERUSER_ROLE;
    else u.role = CLIENT_MANAGER_ROLE;
    return u
}

const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS';

export const initializedSuccess = () => ({type: INITIALIZED_SUCCESS});

export const addToTokenAndUserInfo = (response) => async (dispatch) => {
    const {default_airport, email, is_active, is_superuser, token} = response
    dispatch(userActions.addTokenToStore(token)); // add to store
    localStorage.setItem('token', token); // add to localStorage
    localStorage.setItem('user_information', JSON.stringify({default_airport, email, is_active, is_superuser})); //  add to localStorage
}


export const authUser = (data = null) => async (dispatch) => {
    try {
        if (data.token) {
            const {token, default_airport} = data, userWithRole = defineRole(data);
            dispatch(userActions.addTokenToStore(token));
            dispatch(userActions.setAuthUserData(userWithRole, true));
            // const {data: defaultAirportInfo, status} = await airportAPI.getAirport(default_airport, token);
            // dispatch(airportActions.selectAirport(defaultAirportInfo));

        } else {
            dispatch(userActions.setAuthUserData({permissions: []}, false));
        }
    } catch (error) {
        dispatch(userActions.setAuthUserData({permissions: []}, false));
        localStorage.removeItem('token')
        localStorage.removeItem('user_information');
    }
    dispatch(initializedSuccess());
}


export const login = (username, password) => async (dispatch) => {
    try {
        const response = await authAPI.login(username, password);
        const data = response.data
        dispatch(addToTokenAndUserInfo(data)) // for add token and cookie
        dispatch(authUser(data))

        dispatch(stopSubmit('loginForm', {_error: data.errors}));
        return Promise.reject(new SubmissionError({_error: data.errors}));
    } catch (error) {
        console.log(error);
        dispatch(userActions.setAuthUserData({permissions: []}, false))
    }
    dispatch(initializedSuccess());
};

export const logout = () => async (dispatch) => {
    dispatch(userActions.setAuthUserData({...initialState, isLoading: false}, false));
    localStorage.removeItem('token');
    localStorage.removeItem('user_information');
};

export const updateUser = (data) => async (dispatch) => {
    try {
        const response = await authAPI.updateUser(data);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }

        // dispatch(getAuthUserData());
        toast.success('Profile successfully updated');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(`Error while updating profile: ${errorMessage}`);
    }
};

export const deleteUser = (userId) => async (dispatch) => {
    try {
        const response = await authAPI.deleteUser(userId);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }

        dispatch(accountsActions.deleteUser(userId));
        toast.success('Account successfully deleted');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(`Error while deleting account: ${errorMessage}`);
    }
};

export const updatePassword = async ({newPassword, passwordConfirmation}) => {
    try {
        const response = await authAPI.updatePassword({
            password1: newPassword,
            password2: passwordConfirmation,
        });
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        toast.success('Password successfully updated');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(`Error while updating password: ${errorMessage}`);
    }
};

export const updatePermissions = (permissions) => async (dispatch) => {
    try {
        const response = await authAPI.updatePermissions(permissions);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        toast.success('Critical events updated successfully');
        // dispatch(getAuthUserData());
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(`Error while updating critical events: ${errorMessage}`);
    }
};
