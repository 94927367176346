import React from 'react';
import './Preloader.scss';

export const Preloader = ({ size }) => {

  return (
    <div className={`lds-ring ${size}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
