import { toast } from 'react-toastify';
import { airlineAPI } from 'services/api';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

export const fetchAirlines = async (limit) => {
  try {
    const response = await airlineAPI.getAirlines({limit });
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
  return null;
};
