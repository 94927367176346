import React from 'react';
import './MyCheckbox.scss';

const MyCheckbox = ({ id, label, name, onChange, checked = false }) => {
  return (
    <div className="check-wl">
      <input
        className="check-wl__input"
        name={name}
        onChange={onChange}
        type="checkbox"
        id={`check_${id}`}
        checked={checked}
      />
      <label className="check-wl__label" htmlFor={`check_${id}`}>
        {label}
      </label>
    </div>
  );
};

export default MyCheckbox;
