import { find } from 'lodash';
import { FETCH_ALERTS_SUCCESS } from 'store/constants/alerts.constants';
import {
  CHECK_IS_CONNECTION,
  CLOSE_CONNECTION_SUCCESS,
  ESTABLISH_CONNECTION_ERROR,
  ESTABLISH_CONNECTION_SUCCESS,
  UPDATE_PROCESSES, UPDATE_TURN_DATA,
} from 'store/constants/process.constants';
import {
  CHANGE_PAGE, CLOSE_TURNAROUND_CONNECTION,
  FETCH_TURNAROUND_REQUEST,
  FETCH_TURNAROUND_SUCCESS, GET_CRITICAL_PATHS, GET_UPDATE_CRITICAL_PATH, IS_CRITICAL_PATH,
  PAGE_SIZE,
} from 'store/constants/turnaround.constants';

const initialState = {
  turnaround: {},
  isLoading: false,
  isConnected: false,
  error: '',
  perPage: PAGE_SIZE,
  page: 1,

  isCriticalPath: false,
  isCriticalLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_IS_CONNECTION:
      return {...state, isConnected: false};

    case CLOSE_TURNAROUND_CONNECTION:
      return {...state, turnaround: {}, isConnected: false};

    case IS_CRITICAL_PATH:
      return {...state, isCriticalPath: action.payload, isCriticalLoading: true};

    case GET_CRITICAL_PATHS:
      return {...state, turnaround: action.payload, isCriticalLoading: false}




    case ESTABLISH_CONNECTION_SUCCESS:
      return { ...state, isConnected: true };
    case ESTABLISH_CONNECTION_ERROR:
      return {
        ...state,
        isLoading: false,
        isConnected: false,
        error: action.payload,
      };
    case CLOSE_CONNECTION_SUCCESS:
      return { ...state, isConnected: false };
    case FETCH_TURNAROUND_SUCCESS:
      return {...state, turnaround: action.payload, isLoading: false, error: ''};
    case FETCH_TURNAROUND_REQUEST:
      return { ...state, isLoading: true, isConnected: false};
    case UPDATE_PROCESSES:
      return {
        ...state,
        turnaround: { ...state.turnaround, ...(find(action.payload.items, { id: state.turnaround.id }) || {}) },
        isLoading: false,
        error: '',
      };
    case FETCH_ALERTS_SUCCESS:
      return { ...state, alerts: action.payload };
    case CHANGE_PAGE:
      return { ...state, page: action.payload };

    case UPDATE_TURN_DATA:
      console.log(action.payload, 'TEST ACTION PAYLOAD FOR TURNAROUND')
      return {...state, turnaround: action.payload}
    default:
      return state;
  }
};
