import { toast } from 'react-toastify';

import config from 'config/config.json';
import { cameraAPI } from 'services/api';
import cameraActions from 'store/actions/camera.actions';
import { PAGE_SIZE } from 'store/constants/camera.constants';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

import { Buffer } from 'buffer'

export const fetchCamerasByAirport = (airportIATA, page) => async (dispatch) => {
  dispatch(cameraActions.fetchCamerasLoading(true));
  try {
    const response = await cameraAPI.getCamerasByAirport(airportIATA, page, PAGE_SIZE);

    console.log(response, '--------response in -fetchCamerasByAirport---for getCamerasByAirport request------');
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    dispatch(
      cameraActions.fetchCamerasSuccess({
        cameras: response.data,
        // totalPages: response.data.total,
      }),
    );
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Failed to load cameras: ${errorMessage}`);
  }
};

export const updateCamera = (cameraId, camera, airportIATA) => async (dispatch) => {
  console.log(cameraId, '-------------ID->>>>>>>>updateCamera line 32')
  // TODO: check if this gets invoked or even needs to be upon setting a new url 
  try {
    const response = await cameraAPI.updateCamera(cameraId, camera, airportIATA);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(cameraActions.updateCamera({ id: cameraId, ...camera }));
    toast.success('Camera updated successfully');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
};

export const createCamera = (camera, airportIATA) => async (dispatch) => {
  // console.log(airportIATA, '----------airportIATA line 49 in ----createCamera func--')
  try {
    const response = await cameraAPI.createCamera(
      {
        ...camera,
        gates: camera.gates.map(({ gate, visible_cones, clearance_points, x, y }) => ({
          gate: gate.name,
          // gate: gate.id,
          visible_cones,
          clearance_points,
          x,
          y,
        })),
      },
      airportIATA,
    );
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    dispatch(
      cameraActions.createCamera({
        ...camera,
        id: response.data.id,
      }),
    );
    toast.success('Camera created successfully');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
};

export const deleteCamera = (cameraId, airportIATA) => async (dispatch) => {
  try {
    const response = await cameraAPI.deleteCamera(cameraId, airportIATA);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    dispatch(cameraActions.deleteCamera(cameraId));
    toast.success('Camera deleted successfully');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
};

export const subscribeToCameraImage = async ({ cameraId, airportIATA }, cb) => {
  /**
  * This function is what establishes the connection with the hub-cdn server. 
  * It is known to be invoked by a useEffect in /components/cameras/CameraGatesForm/CameraGatesForm.js
  * ? Presumably, that server reaches out to the air-unit, collects the most recent frame, and passes it back here
  * @param cameraId and @param airportIATA are primitive datatypes used to identify the specific camera
  * @param cb is the function passed here to handle incoming frames
  */

  // const ws = new WebSocket(`http://0.0.0.0:5000/api/airport/${airportIATA}/live?camera_id=${cameraId}`);

  const ws = new WebSocket(`${config.HUB_SOCKETS_URL}/api/airport/${airportIATA}/live?camera_id=${cameraId}`);

  ws.onmessage = (response) => {
    cb({ data: URL.createObjectURL(response.data) });
  };

  ws.onerror = (error) => {
    cb({ error });

    toast.error('Failed to establish connection through web sockets');
    console.error(error);
  };
};

export const fetchCameraFrame = async (params, setLoading, setError) => {
  try {
    setLoading(true)
    const response = await cameraAPI.getCameraFrame(params);
    return  {
      data: `data:${response.headers['content-type']};base64,${Buffer.from(response.data).toString('base64')}`,
      size: [response['headers']['width'], response['headers']['height']]
    }
  } catch (error) {
    setError(error['message'])
  } finally {
    setLoading(false)
  }
};

export const importCameraCSVData = (cameraCSV) => async (dispatch, getState) => {
  try {
    const response = await cameraAPI.importCameraCSV(cameraCSV);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }
    toast.success('File uploaded successfully! Updating cameras...');

    // refetch cameras
    const {airport: { selectedAirport }, cameras} = getState();
    dispatch(fetchCamerasByAirport(selectedAirport.iata, cameras.page));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(errorMessage);
  }
};
