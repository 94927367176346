import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import apiActions from 'store/actions/api.actions';

import Requests from './requests/Requests';
// import s from './ApiList.module.scss';

const ApiList = () => {
  const dispatch = useDispatch();
  const { api: apiList, query } = useSelector(({ api }) => api);

  const filteredApiList = useMemo(() => {
    return apiList
      .map((category) => {
        const filteredEntries = category.entries.filter((entry) =>
          entry.name.includes(query),
        );
        return (
          filteredEntries.length !== 0 && {
            ...category,
            entries: filteredEntries,
          }
        );
      })
      .filter(Boolean);
  }, [apiList, query]);

  const handleRequestSelect = useCallback(
    (requestId) => {
      dispatch(apiActions.selectRequest(requestId));
    },
    [dispatch],
  );

  return (
    <div>
      {filteredApiList?.map((category) => (
        <Requests
          key={category.name}
          entries={category.entries}
          title={category.name}
          isOpen={!!query}
          onRequestClick={handleRequestSelect}
        />
      ))}
    </div>
  );
};

export default ApiList;
