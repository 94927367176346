import {
    ADD_TOKEN_TO_STORE, CHANGE_DEF_AIRPORT, ERROR_FETCH,
    GET_COOKIE_DATA,
    GET_INITIAL_STATE, LOGOUT,
    SET_AUTH_USER_DATA
} from 'store/constants/user.constants';

const localStorageItems = ['token', 'user_information']

export const initialState = {
    isLoading: false,

    type: null,
    type_code: null,
    comment: null,
    isAuthorized: false,
    id: null,
    name: '',
    default_airport: '',
    login: '',
    token: null,
    permissions: [],
    cookie: '',

    isError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_DEF_AIRPORT:
            return {...state, default_airport: action.payload}

        case SET_AUTH_USER_DATA:
            return {...state, ...action.payload};
        case GET_COOKIE_DATA:
            return {...state, cookie: action.payload}
        case ADD_TOKEN_TO_STORE:
            return {...state, token: action.payload}
        case GET_INITIAL_STATE:
            localStorageItems.forEach(item => localStorage.removeItem(item))
            return initialState

        case LOGOUT:
            localStorageItems.forEach(item => localStorage.removeItem(item))
            return initialState

        case ERROR_FETCH:
            return {...state ,isError: action.payload};
        default:
            return state;
    }
};
