import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { find, noop } from 'lodash';

import { Slider } from 'assets/img/all-icons';
import Button from 'components/ui/Button/Button';

import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import s from './TurnaroundFilter.module.scss';

const TurnaroundFilter = ({ selectedFilter, onFilterSelect, filters }) => {
  const [isFilterOpen, setFilterOpen] = useState(false);

  const handleTriggerClick = useCallback(() => setFilterOpen((prevState) => !prevState), []);

  const handleFilterClick = useCallback(
    (e) => {
      const { filter } = e.currentTarget.dataset;
      onFilterSelect(find(filters, { value: filter }));
      setFilterOpen(false);
    },
    [filters, onFilterSelect],
  );

  const closeFilterContainer = useCallback(() => {
    setFilterOpen(false);
  }, []);

  return (
    <ClickAwayListener onClick={closeFilterContainer}>
      <div className={s.container}>
        <Button icon={<Slider width="17px" height="13px" />} type="secondary" onClick={handleTriggerClick}>
          {selectedFilter.label}
        </Button>

        {isFilterOpen && (
          <div className={s.filterContainer}>
            {filters.map((filter) => (
              <div
                role="button"
                tabIndex={0}
                onKeyPress={noop}
                className={classnames(s.filter, { [s.bold]: selectedFilter.value === filter.value })}
                key={filter.value}
                onClick={handleFilterClick}
                data-filter={filter.value}
              >
                <div className={s.icon}>{filter.icon}</div>
                <div className={s.label}>{filter.label}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TurnaroundFilter;
