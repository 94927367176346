import React from 'react';
import s from './MyRadio.module.scss';

const MyRadio = ({ id, name, children, ...rest }) => {
  return (
    <div className={s.container}>
      <input
        className={s.input}
        name={name}
        type="radio"
        id={`radio_${id}`}
        {...rest}
      />
      <label className={s.label} htmlFor={`radio_${id}`}>
        {children}
      </label>
    </div>
  );
};

export default MyRadio;
