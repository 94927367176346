import { IntellActAPI } from './api';

export default {
  fetchStandards(airlineICAO) {
    return IntellActAPI.get(`/airline/${airlineICAO}/standards/`);
  },
  createStandard(airlineICAO, standard) {
    return IntellActAPI.post(`/airline/${airlineICAO}/standards/`, standard);
  },
  deleteStandard(airlineICAO, standardId) {
    return IntellActAPI.delete(`/airline/${airlineICAO}/standards/${standardId}/`);
  },
  updateStandard(airlineICAO, standardId, standard) {
    return IntellActAPI.put(`/airline/${airlineICAO}/standards/${standardId}/`, standard);
  },
};
