/* eslint-disable import/no-anonymous-default-export */
import { IntellActAPI } from './api';

export default {
  // getCamerasByAirport(airportIATA, page, perPage) {
  //   const payload = IntellActAPI.get(`/airport/${airportIATA}/cameras/`, {
  //     params: { paginate: 1, perPage, page },
  //   });
  //   return payload
  // },

  getCamerasByAirport(airportIATA, page, perPage) {
    const payload = IntellActAPI.get(`/airport/${airportIATA}/camera`, {
      // params: { paginate: 1, perPage, page },
    });
    return payload
  },
  updateCamera(cameraId, updatedCamera, airportIATA) {
    console.log(cameraId)
    const payload = IntellActAPI.put(`/airport/${airportIATA}/camera/${cameraId}`, updatedCamera);
    return payload
  },
  createCamera(newCamera, airportIATA) {
    console.log(newCamera, '-------newCamera--arr in createCamera func line 32---------')
    const test = () => {
      const obj = {}
      for (let key in newCamera) {
        if (key !== 'cctv_id' && key !== 'airport') {
          obj[key] = newCamera[key]
        }
      }
      return obj
    }

    const payload = IntellActAPI.post(`/airport/${newCamera['airport']}/camera/${newCamera['cctv_id']}`, test());
    return payload
  },

  deleteCamera(cameraId, airportIATA) {
    return IntellActAPI.delete(`/airport/${airportIATA}/camera/${cameraId}`);
  },
  getCameraImage(cameraUrl, airportIATA) {
    return IntellActAPI.get(`/airport/${airportIATA}/image/`, {
      params: { url: cameraUrl },
      responseType: 'arraybuffer',
    });
  },
  // getCameraFrame(airportIATA, path, bbox, minSize) {
  //   return IntellActAPI.get(`/airport/${airportIATA}/${path}`, {
  //     params: { bbox, ...(minSize && { bbox_min_size: minSize }) },
  //     responseType: 'arraybuffer',
  //   });
  // },
  getCameraFrame(params) {
    return IntellActAPI.get(`image`, {
      params: {...params},
      responseType: 'arraybuffer',
    });
  },
  importCameraCSV(cameraCSV) {
    return IntellActAPI.post('/camera/import/', cameraCSV);
  },
};
