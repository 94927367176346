import React, { useMemo } from 'react';
import classnames from 'classnames';

import uniqBy from 'lodash/fp/uniqBy';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import { find, flow, forEach, reverse } from 'lodash';
import FlightRecord from './GateViewRecord';

import s from '../FlightsTable.module.scss';

export default function GateViewTable({ items, alerts }) {
  const records = useMemo(() => {

    const activeTurnarounds = flow([filter('ata'), sortBy('ata'), reverse])(items);

    const result = activeTurnarounds.reduce((acc, turnaround) => {
      const gates = flow([uniqBy('gate.id'), filter('gate')])([
        { type: 'incoming', gate: turnaround.incoming_gate },
        { type: 'outgoing', gate: turnaround.outgoing_gate },
        { type: 'parking', gate: turnaround.parking_gate },
      ]);

      forEach(gates, ({ gate, type }) => {
        if (!find(acc, (item) => item.gate.id === gate.id)) {
          acc.push({ gate: { ...gate, type }, turnaround });
        }
      });

      return acc;
    }, []);

    return result;
  }, [items]);

  return (
    <div className={s.table}>
      <div className={s.head}>
        <div className={classnames(s.th)} style={{ flexBasis: '35px' }} />
        <div className={classnames(s.th)} style={{ flexBasis: '8%' }}>
          Gate
        </div>
        <div className={classnames(s.th, s.flex)}>Current Flight</div>
        <div className={classnames(s.th, s.flex)}>Parking time</div>
        <div className={classnames(s.th, s.flex)}>EOBT</div>
        <div className={classnames(s.th, s.flex)}>Route</div>
        <div className={classnames(s.th, s.flex)}>Turn type</div>
        <div className={classnames(s.th)} style={{ flexBasis: '15%' }}>
          Latest event
        </div>
        <div className={classnames(s.th, s.flex)}>Next flight</div>
        <div className={classnames(s.th, s.flex)}>EIBT</div>
        <div className={classnames(s.th)} style={{ flexBasis: '50px' }} />
      </div>
      <div className={s.body}>
        {records.map((item) => (
          <FlightRecord key={item.id} record={item} alerts={alerts} />
        ))}
      </div>
    </div>
  );
}
