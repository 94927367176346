import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import ClickAwayListener from 'components/common/ClickAwayListener/ClickAwayListener';
import { Close, Plus } from 'assets/img/all-icons';
import FilterOptions from './FilterOptions';

import './Filter.scss';

const Filter = ({ onNewFilter, onFilterRemove, filters, options, airport }) => {
  const [openFilter, setOpenFilter] = useState(false);

  const toggleFilter = useCallback(() => {
    setOpenFilter((prevState) => !prevState);
  }, []);

  const closeFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const appendNewFilter = useCallback(
    (newFilter) => {
      onNewFilter(newFilter);
      setOpenFilter(false);
    },
    [onNewFilter],
  );

  return (
    <nav className="table-header-filter">
      {/*{filters.length > 0 && (*/}
        {airport && (
          <div className="filter-group">
              {/*{filters?.map((filter) => (*/}
              {/*  <div key={filter.id} className="filter-tag">*/}
              {/*    <span className="tag-key">{filter.name}:</span>*/}
              {/*    <span className="tag-value">{filter.value?.name}</span>*/}
              {/*    <button*/}
              {/*      type="button"*/}
              {/*      className="delete-filter-button"*/}
              {/*      onClick={() => onFilterRemove(filter.alias, filter.id)}*/}
              {/*    >*/}
              {/*      <Close width="18px" className="tag-close" />*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*))}*/}
              <div key={airport} className="filter-tag">
                  {/*<span className="tag-key">{airport.name}:</span>*/}
                  {/*<span className="tag-value">{airport.value?.name}</span>*/}
                  <span className="tag-key">Airport:</span>
                  <span className="tag-value">{airport}</span>
                  {/*<button*/}
                  {/*    type="button"*/}
                  {/*    className="delete-filter-button"*/}
                  {/*    onClick={() => onFilterRemove(airport.alias, airport.id)}*/}
                  {/*>*/}
                  {/*    <Close width="18px" className="tag-close"/>*/}
                  {/*</button>*/}
              </div>
          </div>
        )}
        <ClickAwayListener onClick={closeFilter}>
            <div className="add-filter">
            <button
                    type="button"
                    className="add-filter__box"
                    onClick={toggleFilter}
                >
                    <Plus width="20px" className="plus-icon"/>
                    <span className="add-filter-title">Add filter</span>
                </button>
                {openFilter && (
                    <FilterOptions options={options} onFilterSelect={appendNewFilter} />
          )}
        </div>
      </ClickAwayListener>
    </nav>
  );
};

Filter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      alias: PropTypes.string.isRequired,
      icon: PropTypes.string,
      type: PropTypes.string.isRequired,
      resolver: PropTypes.func.isRequired,
    }),
  ).isRequired,
  onNewFilter: PropTypes.func.isRequired,
  onFilterRemove: PropTypes.func.isRequired,
};

export default Filter;
