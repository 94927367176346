import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

import './AirportLabels.scss';

const AirportLabels = ({ data }) => {
  const { name, city, country, iata, icao } = data;
  return (
    <ul className="airports-labels">
      <li className="airports-labels__item name">
        <p className="airports-labels__title">airport</p>
        <p className="airports-labels__value">{`${name} Airport`}</p>
      </li>
      <li className="airports-labels__item">
        <p className="airports-labels__title">city</p>
        <p className="airports-labels__value">{city}</p>
      </li>
      <li className="airports-labels__item">
        <p className="airports-labels__title">Country</p>
        <div className="d-flex align-items-center">
          <ReactCountryFlag
            countryCode={country}
            svg
            style={{ width: '16px', height: '16px' }}
            className="airports-labels__icon"
          />
          <p className="airports-labels__value">{country}</p>
        </div>
      </li>
      <li className="airports-labels__item">
        <p className="airports-labels__title">IATA</p>
        <p className="airports-labels__value">{iata}</p>
      </li>
      <li className="airports-labels__item">
        <p className="airports-labels__title">ICAO</p>
        <p className="airports-labels__value">{icao}</p>
      </li>
    </ul>
  );
};

AirportLabels.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    iata: PropTypes.string.isRequired,
    icao: PropTypes.string.isRequired,
  }).isRequired,
};

export default AirportLabels;
