import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import * as Icons from 'assets/img/all-icons';
import useDebounce from 'utils/useDebounce';
import OptionInput from './OptionInput';

import s from './FilterOptions.module.scss';

const FilterOptions = ({ options, onFilterSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [referenceList, setReferenceList] = useState([]);
  const [isListLoading, setListLoading] = useState(false);

  const debouncedFilterQuery = useDebounce(query, 500);

  const selectOption = useCallback((option) => {
    setSelectedOption(option);
  }, []);

  const closeInput = useCallback(() => {
    setSelectedOption(null);
    setQuery('');
    setSelectedItem({});
    setReferenceList([]);
  }, []);

  const applyFilter = useCallback(
    (value) => {
      onFilterSelect({ ...selectedOption, value, id: uniqueId() });
      setSelectedOption(null);
    },
    [onFilterSelect, selectedOption],
  );

  const selectItem = useCallback((clickedItem) => {
    setSelectedItem(clickedItem);
    setQuery(clickedItem.name);
  }, []);

  const onQueryChange = useCallback(({ value }) => {
    setQuery(value);
    setSelectedItem({});
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setListLoading(true);
      selectedOption
        .resolver(debouncedFilterQuery)
        .then((data) => {
          setReferenceList(data);
          setListLoading(false);
        })
        .catch(() => {
          setListLoading(false);
        });
    }
  }, [debouncedFilterQuery, selectedOption]);

  return (
    <div className={s.container}>
      {!selectedOption &&
        options.map((item) => {
          const Icon = Icons[item.icon];
          return (
            <button
              key={item.alias}
              type="button"
              className={s.option}
              onClick={() => selectOption(item)}
            >
              <Icon width="17px" fill="#1f80b4" />
              {item.name}
            </button>
          );
        })}

      {selectedOption && (
        <OptionInput
          option={selectedOption}
          onClose={closeInput}
          onFilterApply={applyFilter}
          onItemClick={selectItem}
          selectedItem={selectedItem}
          list={referenceList}
          query={query}
          onQueryChange={onQueryChange}
          isLoading={isListLoading}
        />
      )}
    </div>
  );
};

FilterOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string,
      type: PropTypes.string.isRequired,
      resolver: PropTypes.func.isRequired,
    }),
  ).isRequired,
  onFilterSelect: PropTypes.func.isRequired,
};

export default FilterOptions;
