import React from 'react';
import Sidebar from 'components/ui/Sidebar/Sidebar';

import './Layout.scss';

const Layout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <div className="root">{children}</div>
    </>
  );
};

export default Layout;
