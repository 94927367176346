import React from 'react';
import classnames from 'classnames';
import {ArrowBack} from 'assets/img/all-icons';

import {defaultFormat} from 'utils/helpers';
import s from './TurnaroundDetails.module.scss';

const TurnaroundDetails = ({isOpen, turnaround, onCloseClick, children}) => {

    return (
        <div className={s.container}>
            <style>{`.root {padding: 0}`}</style>
            <div id={s.mainContent}>{children}</div>

            <div id={s.sideDetails} className={classnames({[s.open]: isOpen})}>
                <div className={s.content}>
                    <div className={s.header}>
                        <h6 className={s.headerTitle}>Turnaround details</h6>
                        <button type="button" className={s.closeButton} onClick={onCloseClick}>
                            <ArrowBack width="20px" height="20px"/>
                        </button>
                    </div>

                    <div className={s.block}>
                        <div className={s.heading}>General details</div>
                        <div className={s.item}>
                            <div className={s.param}>Airline</div>
                            <div className={s.value}>{turnaround.airline}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>Airport</div>
                            <div className={s.value}>{turnaround.current_airport}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>Route</div>
                            <div className={s.value}>
                                {`${turnaround.departure_airport} - ${turnaround.current_airport} - ${turnaround.arrival_airport}`}
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>Parking_gate</div>
                            <div className={s.value}>
                                {turnaround['parking_gate']}
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>Turn Type</div>
                            <div className={s.value}>{turnaround.turn_type}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>Registration Number</div>
                            <div className={s.value}>{turnaround.aircraft_reg_number}</div>
                        </div>
                    </div>

                    <div className={s.block}>
                        <div className={s.heading}>Arrival details</div>
                        <div className={s.item}>
                            <div className={s.param}>Arrival Flight</div>
                            <div
                                className={s.value}>{`${turnaround.airline} ${turnaround.departure_flight_number}`}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>STA</div>
                            <div className={s.value}>{defaultFormat(turnaround.sta)}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>ATA</div>
                            <div className={s.value}>{defaultFormat(turnaround.ata)}</div>
                        </div>
                    </div>

                    <div className={s.block}>
                        <div className={s.heading}>Departure details</div>
                        <div className={s.item}>
                            <div className={s.param}>Departure Flight</div>
                            <div className={s.value}>{`${turnaround.airline} ${turnaround.arrival_flight_number}`}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>STD</div>
                            <div className={s.value}>{defaultFormat(turnaround.std)}</div>
                        </div>
                        <div className={s.item}>
                            <div className={s.param}>ATD</div>
                            <div className={s.value}>{defaultFormat(turnaround.atd)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TurnaroundDetails;
