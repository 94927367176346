import React from 'react';

import Input from 'components/ui/MyInput/MyInput';
import s from './RequestParameter.module.scss';

const RequestParameter = ({ name, isRequired, type, description, value }) => {
  return (
    <div className={s.container}>
      <div className={s.left}>
        <div>
          <span className={s.name}>{name}</span> {isRequired && '(required)'}
        </div>
        <span className={s.type}>{type.toUpperCase()}</span>
      </div>

      <div className={s.input}>
        <Input value={value} />
        <div className={s.description}>{description}</div>
      </div>
    </div>
  );
};

export default RequestParameter;
