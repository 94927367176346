import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useAbility } from '@casl/react';

import { updateCamera, deleteCamera } from 'store/controllers/camera.controllers';
import Can, { AbilityContext } from 'services/Can';
import { EDIT } from 'services/ability';

import Confirmation from 'components/common/Confirmation/Confirmation';
import { ActiveMode, AddCamera, CardClose, FixesMode, OnboardingMode } from 'assets/img/all-icons';

import Button from 'components/ui/Button/Button';
import CameraModal from '../CameraModal/CameraModal';

import s from './Camera.module.scss';

const CameraCard = ({ camera, iata }) => {
  console.log(camera, '-------CAMERA- in CameraCard component---------')
  const dispatch = useDispatch();

  const [isFormOpen, setFormOpen] = useState(false);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [changedMode, setChangedMode] = useState('');

  const { selectedAirport } = useSelector(({ airport }) => airport, shallowEqual);

  const ability = useAbility(AbilityContext);
  const allowedToManage = useMemo(() => ability.can(EDIT, { type: 'airports', iata: selectedAirport.iata }), [
    ability,
    selectedAirport.iata,
  ]);

  const onCameraClick = useCallback(() => {
    if (allowedToManage) {
      setFormOpen(true);
    }
  }, [allowedToManage]);

  const handleCameraDelete = useCallback(() => {
    dispatch(deleteCamera(camera['cctv_id'], selectedAirport.iata));
    setDeleteConfirmationOpen(false);
  }, [camera['cctv_id'], dispatch, selectedAirport.iata]);

  const onDeleteCancel = useCallback(() => {
    setDeleteConfirmationOpen(false);
  }, []);

  const handleModalClose = useCallback(() => {
    setFormOpen(false);
  }, []);

  const handleCameraSave = useCallback(
    (updatedCamera) => {
      const infoToUpdate = {};
      if (camera.url !== updatedCamera.url) {
        infoToUpdate.url = updatedCamera.url;
      }

      infoToUpdate.position = updatedCamera.position;
      infoToUpdate.view = updatedCamera.view;
      infoToUpdate.gates = updatedCamera.gates.map(({ gate, visible_cones, clearance_points, x, y }) => ({
        gate: gate.id,
        visible_cones,
        clearance_points,
        x,
        y,
      }));

      dispatch(updateCamera(updatedCamera.id, infoToUpdate, selectedAirport.iata));
    },
    [dispatch, camera, selectedAirport.iata],
  );

  const onModeChange = useCallback((e) => {
    const { mode } = e.currentTarget.dataset;
    e.stopPropagation();
    setChangedMode(mode);
  }, []);

  const onChangeModeCancel = useCallback(() => {
    setChangedMode('');
  }, []);

  const changeCameraMode = useCallback(() => {
    dispatch(updateCamera(camera['cctv_id'], { mode: changedMode }, selectedAirport.iata));
    setChangedMode('');
  }, [camera['cctv_id'], dispatch, selectedAirport.iata, changedMode]);

  const onDeleteClick = useCallback((e) => {
    e.stopPropagation();
    setDeleteConfirmationOpen(true);
  }, []);

  return (
    <>
      <div
        className={s.container}
        key={camera['cctv_id']}
        tabIndex="0"
        role="button"
        style={{ cursor: allowedToManage && 'pointer' }}
        onKeyPress={() => {}}
        onClick={() => onCameraClick(camera['cctv_id'])}
      >
        <div className={s.head}>
          <span>
            <AddCamera width="36px" className={s.iconCamera} />
          </span>
          <p className={s.title}>{camera.url?.match(/([0-9]{1,3}(\.|:)+)+:?\d+/g)?.join('') || camera.url}</p>
          <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
            <button type="button" className={s.buttonClose} onClick={onDeleteClick}>
              <CardClose width="36px" className={s.iconClose} />
            </button>
          </Can>
        </div>

        <div className={s.inner}>
          <ul className={s.parameters}>
            <li className={s.item}>Gate: {camera.gates?.map(({ name}) => name).join(', ')}</li>
            <li className={s.item}>Position: {camera.position}</li>
            <li className={s.item}>View: {camera.view}</li>
          </ul>

          <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
            <div className={s.modeContainer}>
              <div>Mode</div>
              <div className={s.modeButtons}>
                <Button
                  type={camera.mode !== 'active' && 'secondary'}
                  onClick={onModeChange}
                  data-mode="active"
                  className={classnames(s.button, s.leftMode)}
                  icon={<ActiveMode width="16px" height="16px" />}
                />
                <Button
                  type={camera.mode !== 'onboarding' && 'secondary'}
                  onClick={onModeChange}
                  data-mode="onboarding"
                  className={classnames(s.button, s.centerMode, { [s.currentMode]: camera.mode === 'onboarding' })}
                  icon={<OnboardingMode width="16px" height="16px" />}
                />
                <Button
                  type={camera.mode !== 'fixes' && 'secondary'}
                  onClick={onModeChange}
                  data-mode="fixes"
                  className={classnames(s.button, s.rightMode)}
                  icon={<FixesMode width="14px" height="16px" />}
                />
              </div>
            </div>
          </Can>
        </div>
      </div>
      <Can I={EDIT} this={{ type: 'airports', iata: selectedAirport.iata }}>
        <CameraModal
          isOpen={isFormOpen}
          handleModalClose={handleModalClose}
          handleCameraSave={handleCameraSave}
          camera={camera}
        />
        <Confirmation
          question="Do you want to delete a camera?"
          isOpen={isDeleteConfirmationOpen}
          onConfirm={handleCameraDelete}
          onCancel={onDeleteCancel}
        />
        <Confirmation
          question={`Do you want to change mode to ${changedMode}?`}
          isOpen={!!changedMode}
          onConfirm={changeCameraMode}
          onCancel={onChangeModeCancel}
        />
      </Can>
    </>
  );
};

CameraCard.propTypes = {
  camera: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    gates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        visible_cones: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
};

export default CameraCard;
