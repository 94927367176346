import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import Button from 'components/ui/Button/Button';

import s from './Paginator.module.scss';

const MAX_VISIBLE_PAGES = 10;

const Paginator = ({totalPages, currentPage, onPageChanged}) => {
    const [portionNumber, setPortionNumber] = useState(1);
    const pages = useMemo(() => range(1, totalPages + 1), [totalPages]);

    const portionCount = Math.ceil(totalPages / MAX_VISIBLE_PAGES);
    const leftPortionPageNumber = (portionNumber - 1) * MAX_VISIBLE_PAGES;
    const rightPortionPageNumber = portionNumber * MAX_VISIBLE_PAGES;

    // dont display paginator if only one page
    if (totalPages === 1) return null;

    return (
        <div className={s.container}>
            <div className={s.paginator}>
                {portionNumber > 1 && <Button onClick={() => setPortionNumber(portionNumber - 1)}>Previous</Button>}

                {pages.slice(leftPortionPageNumber, rightPortionPageNumber).map((p) => (
                    <button
                        type="button"
                        className={p === currentPage ? s.button : s.current}
                        key={p}
                        onClick={() => {if (currentPage !== p) onPageChanged(p)}}>
                        {p}
                    </button>
                ))}
                {portionCount > portionNumber &&
                    <Button onClick={() => setPortionNumber(portionNumber + 1)}>Next</Button>}
            </div>
        </div>
    );
};

Paginator.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChanged: PropTypes.func.isRequired,
};

export default Paginator;
