import { IntellActAPI } from './api';

export default {
  // getAirports({ q, limit }) {
  //   return IntellActAPI.get('/airport/', { params: { q, limit } });
  // },
  getAirports({limit }) {
    return IntellActAPI.get('/airport', { params: {limit} });
  },
  getAirport(iata, token) {
    return IntellActAPI.get(`/airport/${iata}`, {headers: {Authorization: token}});
  },
  updateAirport(iata, updates) {
    return IntellActAPI.put(`/airport/${iata}/`, updates);
  },
  getTerminals(id) {
    return IntellActAPI.get(`/airport/${id}/terminals/`);
  },
  getGates(id) {
    return IntellActAPI.get(`/terminal/${id}/gates/`);
  },
  importAirportCSV(airportCSV, airportIATA) {
    return IntellActAPI.post(`/airport/${airportIATA}/import/`, airportCSV);
  },
};
