import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { Preloader } from 'components/common/Preloader/Preloader';
import AddNewAccountPermissions from 'components/accounts/accountPermissions/AccountPermissions';
import AddNewAccountUserInfo from 'components/accounts/accountInfo/AccountInfo';
import {
  fetchAccountById,
  updateAccount,
} from 'store/controllers/account.controllers';
import {
  SUPERUSER_ROLE,
  CLIENT_MANAGER_ROLE,
} from 'store/constants/user.constants';
import { routes } from 'store/constants/ui.constants';

import './EditAccount.scss';

const availableScopes = {
  [SUPERUSER_ROLE]: { value: SUPERUSER_ROLE, label: 'Superuser' },
  [CLIENT_MANAGER_ROLE]: {
    value: CLIENT_MANAGER_ROLE,
    label: 'Client manager',
  },
};

const EditAccount = ({ match }) => {
  console.log(match, '----match-------EditAccount-----on 28 line --------');
  const history = useHistory();
  const [account, setAccount] = useState({});
  const [infoToUpdate, setInfoToUpdate] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [scope, setScope] = useState(availableScopes[CLIENT_MANAGER_ROLE]);

  console.log(step, '------------STEP state in -----EditAccount-------')

  const onInputChange = useCallback(({ name, value }) => {
    setInfoToUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setAccount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const goToNextStep = useCallback(
    () => setStep((prevState) => prevState + 1),
    [],
  );

  const goToPrevStep = useCallback(
    () => setStep((prevState) => prevState - 1),
    [],
  );

  const handleSubmit = useCallback(async () => {
    const accountObject = { ...infoToUpdate };
    if (accountObject.login === account.login) delete accountObject.login;

    const success =
      Object.values(accountObject).length > 0
        ? await updateAccount(match.params.id, accountObject)
        : true;
    if (success) history.push(routes.ACCOUNTS);
  }, [account.login, history, infoToUpdate, match.params.id]);

  useEffect(() => {
    setLoading(true);
    fetchAccountById(match.params.id)
      .then((data) => {
        if (data) {
          setAccount(data);
          if (data.is_superuser) {
            setScope(availableScopes[SUPERUSER_ROLE]);
          }
          setLoading(false);
        }
      })
      .catch(console.error);
  }, [match.params.id]);

  if (isLoading) return <Preloader />;

  switch (step) {
    case 1:
      return (
        <AddNewAccountUserInfo
          account={account}
          scope={scope}
          setScope={setScope}
          availableScopes={availableScopes}
          onNextClick={goToNextStep}
          onChange={onInputChange}
          onSubmit={handleSubmit}
        />
      );
    case 2:
      return (
        <AddNewAccountPermissions
          account={account}
          onPrevClick={goToPrevStep}
          onChange={onInputChange}
          onSubmit={handleSubmit}
        />
      );
    default:
      return null;
  }
};

EditAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default EditAccount;
