import {
  SET_ACCOUNTS_LIST,
  SET_CURRENT_PAGE,
  SET_TOTAL_ACCOUNTS_COUNT,
  TOGGLE_IS_FETCHING,
  UPDATE_ACCOUNT_FILTERS,
  REMOVE_ACCOUNT_FILTER,
  DELETE_ACCOUNT,
} from 'store/constants/account.constants';

export default {
  setAccountsList(accounts) {
    return { type: SET_ACCOUNTS_LIST, accounts };
  },
  setCurrentPage(currentPage) {
    return {
      type: SET_CURRENT_PAGE,
      currentPage,
    };
  },
  setTotalAccountsCount(total) {
    return {
      type: SET_TOTAL_ACCOUNTS_COUNT,
      total,
    };
  },
  toggleIsFetching(isFetching) {
    return {
      type: TOGGLE_IS_FETCHING,
      isFetching,
    };
  },
  updateFilters(filter) {
    return { type: UPDATE_ACCOUNT_FILTERS, payload: filter };
  },
  removeFilter(filterType) {
    return { type: REMOVE_ACCOUNT_FILTER, payload: { type: filterType } };
  },
  deleteUser(id) {
    return { type: DELETE_ACCOUNT, payload: id };
  },
};
