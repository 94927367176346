import { toast } from 'react-toastify';

import { safetyAlertAPI } from 'services/api';
import safetyAlertsActions from 'store/actions/safetyAlerts.actions';
import { checkSuccessfulStatus, defaultError } from 'store/helpers';

export const fetchSafetyAlerts = () => async (dispatch) => {
  dispatch(safetyAlertsActions.fetchSafetyAlertsLoading(true));

  try {
    const response = await safetyAlertAPI.getSafetyAlerts();
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(safetyAlertsActions.fetchSafetyAlertsSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while loading safety alerts: ${errorMessage}`);
  }

  dispatch(safetyAlertsActions.fetchSafetyAlertsLoading(false));
};

export const createSafetyAlert = (newAlert) => async (dispatch) => {
  try {
    const response = await safetyAlertAPI.createSafetyAlert(newAlert);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(
      safetyAlertsActions.createSafetyAlert({
        ...newAlert,
        id: response.data.id,
      }),
    );
    toast.success('Safety alert successfully created');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while creating safety alert: ${errorMessage}`);
  }
};

export const updateSafetyAlert = (alertToUpdate) => async (dispatch) => {
  try {
    const response = await safetyAlertAPI.updateSafetyAlert(alertToUpdate.id, alertToUpdate);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    toast.success('Safety alert successfully updated');
    dispatch(safetyAlertsActions.updateSafetyAlert(alertToUpdate));
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while updating safety alert: ${errorMessage}`);
  }
};

export const deleteSafetyAlert = (alertId) => async (dispatch) => {
  try {
    const response = await safetyAlertAPI.deleteSafetyAlert(alertId);
    if (!checkSuccessfulStatus(response.status)) {
      throw new Error(defaultError);
    }

    dispatch(safetyAlertsActions.deleteSafetyAlert(alertId));
    toast.success('Safety alert successfully deleted');
  } catch (error) {
    const errorMessage = error.response?.data?.err || error.message;
    toast.error(`Error while deleting safety alert: ${errorMessage}`);
  }
};
