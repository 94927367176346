import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import AddNewAccountPermissions from 'components/accounts/accountPermissions/AccountPermissions';
import AddNewAccountUserInfo from 'components/accounts/accountInfo/AccountInfo';
import { createAccount } from 'store/controllers/account.controllers';
import { SUPERUSER_ROLE, CLIENT_MANAGER_ROLE } from 'store/constants/user.constants';
import { routes } from 'store/constants/ui.constants';

import './AddAccount.scss';

const availableScopes = {
  [SUPERUSER_ROLE]: { value: SUPERUSER_ROLE, label: 'Superuser' },
  [CLIENT_MANAGER_ROLE]: {
    value: CLIENT_MANAGER_ROLE,
    label: 'Client manager',
  },
};

const emptyAccount = {
  login: '',
  name: '',
  type_code: '',
  password: '',
  default_airport: '',
  type: 'airport',
  permissions: [],
};

const AddAccount = () => {
  const history = useHistory();
  const [account, setAccount] = useState(emptyAccount);
  const [step, setStep] = useState(1);
  const [scope, setScope] = useState(availableScopes[CLIENT_MANAGER_ROLE]);

  const onInputChange = useCallback(({ name, value }) => {
    setAccount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const goToNextStep = useCallback(() => setStep((prevState) => prevState + 1), []);

  const goToPrevStep = useCallback(() => {
    setAccount((prevState) => ({
      ...prevState,
      permissions: [],
      default_airport: '',
    }));
    setStep((prevState) => prevState - 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    const accountObject = {
      type_code: account.type_code,
      is_superuser: scope.value === SUPERUSER_ROLE,
      name: account.name,
      login: account.login,
      type: account.type,
      default_airport: scope.value === SUPERUSER_ROLE ? 'TLV' : account.default_airport,
      password: account.password,
      comment: account.comment,
      permissions: account.permissions,
    };

    const success = await createAccount(accountObject);
    if (success) history.push(routes.ACCOUNTS);
  }, [account, history, scope.value]);

  switch (step) {
    case 1:
      return (
        <AddNewAccountUserInfo
          account={account}
          scope={scope}
          setScope={setScope}
          availableScopes={availableScopes}
          onNextClick={goToNextStep}
          onChange={onInputChange}
          onSubmit={handleSubmit}
        />
      );
    case 2:
      return (
        <AddNewAccountPermissions
          account={account}
          onPrevClick={goToPrevStep}
          onChange={onInputChange}
          onSubmit={handleSubmit}
        />
      );
    default:
      return null;
  }
};

export default AddAccount;
