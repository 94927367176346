import {
  FETCH_SAFETY_ALERTS_LOADING,
  FETCH_SAFETY_ALERTS_SUCCESS,
  CREATE_SAFETY_ALERT,
  UPDATE_SAFETY_ALERT,
  DELETE_SAFETY_ALERT,
} from 'store/constants/safetyAlerts.constants';

export default {
  fetchSafetyAlertsSuccess(alerts) {
    return { type: FETCH_SAFETY_ALERTS_SUCCESS, payload: alerts };
  },
  fetchSafetyAlertsLoading(isLoading) {
    return { type: FETCH_SAFETY_ALERTS_LOADING, payload: isLoading };
  },
  createSafetyAlert(alert) {
    return { type: CREATE_SAFETY_ALERT, payload: alert };
  },
  updateSafetyAlert(alert) {
    return { type: UPDATE_SAFETY_ALERT, payload: alert };
  },
  deleteSafetyAlert(alertId) {
    return { type: DELETE_SAFETY_ALERT, payload: alertId };
  },
};
