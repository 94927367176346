import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';

import Button from 'components/ui/Button/Button';
import AirportFilter from 'components/ui/AirportFilter/AirportFilter';
import Header from 'components/common/Header/Header';
import MyCheckbox from 'components/ui/MyCheckbox/MyCheckbox';
import { CloseRed, Tasks } from 'assets/img/all-icons';

import { fetchEventClasses } from 'store/controllers/services.controllers';
import { SITE_MANAGER_PERMISSION, VIEWER_PERMISSION } from 'store/constants/user.constants';

import { map } from 'lodash';
import ServicesModal from './ServicesModal/ServicesModal';

const roleOptions = [
  { value: SITE_MANAGER_PERMISSION, label: 'Site manager' },
  { value: VIEWER_PERMISSION, label: 'Viewer' },
];

const AddAccountPermissions = ({ account, onPrevClick, onChange, onSubmit }) => {
  const dispatch = useDispatch();

  const [openedAirportIata, setOpenedAirportIata] = useState('');
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { eventClasses } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(fetchEventClasses());
  }, [dispatch]);

  const onSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const openPopupForAirport = useCallback((iata) => {
    setOpenedAirportIata(iata);
  }, []);

  const closePopup = useCallback(() => {
    setOpenedAirportIata('');
  }, []);

  const onPermissionsChange = useCallback(
    (eventClassIds) => {
      onChange({
        name: 'permissions',
        value: account.permissions.map((permission) =>
          permission.airport === openedAirportIata ? { ...permission, event_classes: eventClassIds } : permission,
        ),
      });
      closePopup();
    },
    [account.permissions, closePopup, onChange, openedAirportIata],
  );

  const onRoleChange = useCallback(
    ({ value }, iata) => {
      onChange({
        name: 'permissions',
        value: account.permissions.map((permission) =>
          permission.airport === iata
            ? {
                ...permission,
                is_manager: value === SITE_MANAGER_PERMISSION,
              }
            : permission,
        ),
      });
    },
    [account.permissions, onChange],
  );

  const onAddAirportClick = useCallback(() => {
    setSearchOpen((prevState) => !prevState);
  }, []);

  const addAirport = useCallback(
    (airport) => {
      // forbid adding the same airport
      if (account.permissions.find((permission) => permission.airport === airport.iata)) {
        return;
      }

      if (account.type === 'airport' && account.permissions.length > 0) {
        toast.warn('Airport client is allowed to have only one airport');
        return;
      }

      onChange({
        name: 'permissions',
        value: account.permissions.concat({
          airport: airport.iata,
          is_manager: true,
          event_classes: map(eventClasses, 'id'),
        }),
      });
      onChange({
        name: 'default_airport',

        value: account.type === 'airport' ? airport.iata : account.default_airport,
      });
    },
    [account, onChange, eventClasses],
  );

  const removeAirport = useCallback(
    (iata) => {
      onChange({
        name: 'permissions',
        value: account.permissions.filter(({ airport }) => airport !== iata),
      });
      onChange({
        name: 'default_airport',
        value: account.type === 'airport' ? '' : account.default_airport,
      });
    },
    [account, onChange],
  );

  const setDefaultAirport = useCallback(
    (iata) => {
      onChange({ name: 'default_airport', value: iata });
    },
    [onChange],
  );

  const closeSearch = useCallback(() => {
    setSearchOpen(false);
  }, []);

  return (
    <div className="add-new-account add-new-account-2">
      <div className="form-container">
        <div>
          <Header>
            <h2 style={{ color: '#383838', flex: 1 }}>Account Management</h2>
          </Header>
          <div className="add-new-account__container_header">
            <h2 className="add-new-account__permission-title">Airports permissions</h2>
            <div className="add-new-airport-button">
              <AirportFilter
                title="Add airport"
                isOpen={isSearchOpen}
                onClick={onAddAirportClick}
                closeFilter={closeSearch}
                query={searchQuery}
                onQueryChange={onSearchQueryChange}
                onAirportClick={addAirport}
              />
            </div>
          </div>
          <div className="add-new-account__container_no-border">
            {account.permissions?.length === 0 && (
              <div className="no-airport">
                <h5>No airport was added</h5>
              </div>
            )}
            <div className="selected-airports">
              {account.permissions.map(({ airport, is_manager }) => (
                <div key={airport} className="airport__container">
                  <div className="airport__head">
                    <div className="airport__group">
                      <h2 className="airport__name">{`${airport} Airport`}</h2>
                      {/* <h2 className="airport__country">{country}</h2> */}
                      {account.type !== 'airport' && (
                        <>
                          <div className="role-container">
                            <Select
                              options={roleOptions}
                              defaultValue={roleOptions[0]}
                              value={is_manager ? roleOptions[0] : roleOptions[1]}
                              onChange={(role) => onRoleChange(role, airport)}
                              isSearchable={false}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  background: 'transparent',
                                  boxShadow: 'none',
                                  cursor: 'pointer',
                                }),
                                indicatorSeparator: () => ({
                                  display: 'none',
                                }),
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: 25 }}>
                            <MyCheckbox
                              label="Default"
                              name="default"
                              onChange={() => setDefaultAirport(airport)}
                              id={airport}
                              checked={airport === account.default_airport}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="airport__group airport__group_end">
                      <div>
                        <button type="button" className="settings" onClick={() => openPopupForAirport(airport)}>
                          <Tasks width="20px" />
                        </button>
                      </div>
                      <button type="button" className="action action_empty" onClick={() => removeAirport(airport)}>
                        <CloseRed width="24px" className="delete-item" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <ServicesModal
            allowedEvents={
              account.permissions.find(({ airport }) => airport === openedAirportIata)?.event_classes || []
            }
            isOpen={!!openedAirportIata}
            onClose={closePopup}
            onSave={onPermissionsChange}
          />
        </div>
        <div className="add-new-account__footer add-new-account__footer_justify">
          <Button type="secondary" onClick={onPrevClick}>
            Previous
          </Button>
          <Button onClick={onSubmit}>Save</Button>
        </div>
      </div>
    </div>
  );
};

AddAccountPermissions.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
    type_code: PropTypes.string,
    password: PropTypes.string,
    default_airport: PropTypes.string,
    type: PropTypes.string,
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        airport: PropTypes.string,
        is_manager: PropTypes.bool.isRequired,
        event_classes: PropTypes.arrayOf(PropTypes.number),
      }),
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddAccountPermissions;
