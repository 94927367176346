import {
    CHANGE_PAGE,
    CLOSE_TURNAROUND_CONNECTION, CRITICAL_ERRORS, CRITICAL_LISTENER,
    FETCH_TURNAROUND_REQUEST,
    FETCH_TURNAROUND_SUCCESS, GET_CRITICAL_PATHS, GET_UPDATE_CRITICAL_PATH, IS_CRITICAL_PATH
} from 'store/constants/turnaround.constants';



export default {
    fetchTurnaroundSuccess(turnaround) {
        return {type: FETCH_TURNAROUND_SUCCESS, payload: turnaround};
    },

    fetchTurnaroundRequest() {
        return {type: FETCH_TURNAROUND_REQUEST};
    },
    changePage(page) {
        return {
            type: CHANGE_PAGE,
            payload: page,
        };
    },







    closeConnection(promise) {
        return {
            type: 'socket',
            types: ['', CLOSE_TURNAROUND_CONNECTION, ''],
            promise,
        };
    },

    isCriticalPath(boolean) {
        return {
            type: IS_CRITICAL_PATH,
            payload: boolean
        }
    },

    establishCriticalPathConnection(promise) {
      return {
          type: 'socket',
          types: ['', '', ''],
          promise
      }
    },

    criticalPathsListener(promise) {
      return {
          type: 'socket',
          types: ['', CRITICAL_LISTENER, CRITICAL_ERRORS],
          promise
      }
    },

    getCriticalPaths(criticalPaths) {
        return {
            type: GET_CRITICAL_PATHS,
            payload: criticalPaths
        }
    },
};
