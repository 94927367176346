import React, {useState, useCallback, useMemo} from 'react';
import classnames from 'classnames';
import {noop, map, flatMap, sortBy} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import {ArrowForward, Clock, InfoShield, Prediction, ReturnBack} from 'assets/img/all-icons';
import Header from 'components/common/Header/Header';
import TurnaroundDetails from 'components/turnaround/turnaroundDetails/TurnaroundDetails';
import Paginator from 'components/common/Paginator/Paginator';

import {defaultFormat} from 'utils/helpers';
import turnaroundActions from 'store/actions/turnaround.actions';

import TurnaroundFilter from '../turnaroundFilter/TurnaroundFilter';
import TurnaroundEventModal from '../turnaroundEventModal/TurnaroundEventModal';
import s from './FlightAdministrationView.module.scss';
import TurnaroundSwitch from '../turnaroundSwitch/TurnaroundSwitch';
import GateSwitch from '../gateSwitch/GateSwitch';

const turnaroundFilters = [
    {value: 'events', label: 'Turnaround events', icon: <ReturnBack width="21px" height="18px"/>},
    {value: 'alerts', label: 'Safety alerts', icon: <InfoShield width="18px" height="22px"/>},
    // { value: 'predictions', label: 'Predictions', icon: <Prediction width="16px" height="16px" /> },
];

const icons = {
    event: <ReturnBack width="21px" height="18px"/>,
    alert: <InfoShield width="18px" height="22px"/>,
    prediction: <Prediction width="16px" height="16px"/>,
};

const FlightAdministrationView = () => {
    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState(turnaroundFilters[0]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [areDetailsOpen, setDetailsOpen] = useState(false);

    const {turnaround, page, perPage} = useSelector((state) => state.turnaround);
    const {alerts} = useSelector((state) => state.alerts);

    const openTurnaroundDetails = useCallback(() => setDetailsOpen(true),[]);

    const closeTurnaroundDetails = useCallback(() => setDetailsOpen(false), []);

    const changePage = useCallback((p) => {dispatch(turnaroundActions.changePage(p));}, [dispatch],);

    const handleItemClick = useCallback((item) => setSelectedItem(item), []);

    const handleModalClose = useCallback(() => setSelectedItem(null), []);

    const switchToAnotherGate = useCallback((gate) => console.log(gate), []);

    const items = useMemo(() => {
        let result = [];

        if (selectedFilter.value === 'events') {
            const events = flatMap(turnaround.processes, (process) => {
                return flatMap(process['event_classes'], (eventClass) => {
                    return map(eventClass.events, (event) => ({
                        id: event.uuid,
                        type: 'event',
                        process: process.name,
                        event: event.name,
                        // eventClass: eventClass['event_class_name'],
                        actualTime: event.actual,
                        scheduledTime: eventClass.scheduled,
                        confidence: event.confidence,
                        // image: event.image,
                    }));
                });
            });

            result = result.concat(sortBy(events, 'actualTime'));
        }

        if (selectedFilter.value === 'alerts') {
            const alertItems = alerts.map((alert) => ({
                id: alert.id,
                type: 'alert',
                process: 'No process',
                alert: alert.name,
                timestamp: alert.timestamp,
            }));

            result = result.concat(alertItems);
        }

        return result;
    }, [turnaround.processes, selectedFilter, alerts]);

    console.log(items, 'items__items__items')

    const paginatedItems = useMemo(() => {
        return items.slice((page - 1) * perPage, (page - 1) * perPage + perPage);
    }, [items, perPage, page]);

    return (
        <TurnaroundDetails isOpen={areDetailsOpen} turnaround={turnaround} onCloseClick={closeTurnaroundDetails}>
            <div className={s.container}>
                <div className={s.header}>
                    <Header>
                        <div className={s.headerInner}>
                            <div className={s.leftSide}>
                                <h2 className={s.headerTitle}>{`${turnaround.current_airport} Airport`}</h2>
                            </div>

                            <div className={s.rightSide}>
                                <TurnaroundSwitch
                                    arrival={turnaround.ata || turnaround.sta}
                                    departure={turnaround.atd || turnaround.std}
                                />

                                <div className={s.gateSwitch}>
                                    <GateSwitch
                                        selectedGate={turnaround.incoming_gate}
                                        gateList={[]}
                                        onFilterSelect={switchToAnotherGate}
                                    />
                                </div>

                                <TurnaroundFilter
                                    filters={turnaroundFilters}
                                    selectedFilter={selectedFilter}
                                    onFilterSelect={setSelectedFilter}
                                />
                            </div>

                            {!areDetailsOpen && (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={noop}
                                    className={s.detailsButton}
                                    onClick={openTurnaroundDetails}
                                >
                                    <h6 className={s.title}>Turnaround details</h6>
                                    <ArrowForward width="20px" height="20px"/>
                                </div>
                            )}
                        </div>
                    </Header>
                </div>

                <div className={s.content}>
                    <div className={s.table}>
                        <div className={s.head}>
                            <div className={classnames(s.th)} style={{flexBasis: '50px'}}/>
                            <div className={classnames(s.th, s.flex)}>Process</div>
                            <div className={classnames(s.th, s.flex)}>Event class</div>
                            <div className={classnames(s.th, s.flex)}>Event/alert name</div>
                            <div className={classnames(s.th, s.flex)}>Timestamp</div>
                            <div className={classnames(s.th, s.flex)}>Confidence</div>
                        </div>
                        <div className={s.body}>
                            {paginatedItems.map((item) => (
                                <div role="button" tabIndex={0} onKeyPress={noop}
                                     className={s.row} key={item.id} onClick={() => handleItemClick(item)}
                                >
                                    <div className={classnames(s.td)} style={{flexBasis: '50px'}}>
                                        {icons[item.type]}
                                    </div>
                                    <div className={classnames(s.td, s.flex)}>{item.process}</div>
                                    <div className={classnames(s.td, s.flex)}>{item.eventClass}</div>
                                    <div className={classnames(s.td, s.flex)}>{item.event || item.alert}</div>
                                    <div className={classnames(s.td, s.flex, s.blue)}>
                                        <div className={s.paddingRight}>
                                            <Clock width="18px" fill="#1f80b4"/>
                                        </div>
                                        {defaultFormat(item.actualTime)}
                                    </div>
                                    <div className={classnames(s.td, s.flex)}>
                                        {item.confidence ? (`${(item.confidence * 100).toFixed(1)}%`)
                                            : (<span className={s.gray}>No data</span>)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <TurnaroundEventModal item={selectedItem} onClose={handleModalClose}/>

                <Paginator totalPages={Math.ceil(items.length / perPage)} currentPage={page}
                           onPageChanged={changePage}/>
            </div>
        </TurnaroundDetails>
    );
};

export default FlightAdministrationView;
