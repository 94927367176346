import {toast} from 'react-toastify';
import {airportAPI, gateAPI, terminalAPI} from 'services/api';
import {checkSuccessfulStatus, defaultError} from 'store/helpers';
import airportActions from 'store/actions/airport.actions';
import {createGetAirportHandler} from 'store/handlers/airport.handler';

// export const fetchAirportByIata = (airportIATA, token) => async (dispatch) => {
//   dispatch(airportActions.getAirportLoading(true));
//   console.log(token, '---------fetchAirportByIata check token line 9-----------')
//   const handler = createGetAirportHandler((data) => {
//     dispatch(airportActions.getAirportSuccess(data));
//     dispatch(airportActions.getAirportLoading(false));
//   });
//   await handler(airportIATA, token);
// };

export const fetchAirportByIata = (airportIATA, token) => async (dispatch) => {
    dispatch(airportActions.getAirportLoading(true));
    const handler = createGetAirportHandler((data) => {
        dispatch(airportActions.getAirportSuccess(data));
        dispatch(airportActions.getAirportLoading(false));
    }, token);
    await handler(airportIATA);
};


export const updateAirport = (airportIATA, updates) => async (dispatch) => {
    try {
        const response = await airportAPI.updateAirport(airportIATA, updates);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }

        dispatch(airportActions.updateAirport(updates));
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const createTerminal = (terminal, airportIATA) => async (dispatch) => {
    try {
        const response = await terminalAPI.createTerminal(terminal, airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        dispatch(
            airportActions.createTerminal({
                ...terminal,
                id: response.data.id,
                gates: [],
            }),
        );
        toast.success('Terminal created successfully');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const updateTerminal = (terminalId, terminal, airportIATA) => async (dispatch,) => {
    try {
        const response = await terminalAPI.updateTerminal(terminalId, terminal, airportIATA,);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        dispatch(airportActions.updateTerminal(terminal));
        toast.success('Terminal updated successfully');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const deleteTerminal = (terminalId, airportIATA) => async (dispatch) => {
    try {
        const response = await terminalAPI.deleteTerminal(terminalId, airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        dispatch(airportActions.deleteTerminal(terminalId));
        toast.success('Terminal deleted successfully');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const createGate = (gate, airportIATA) => async (dispatch) => {
    try {
        const response = await gateAPI.addGate(gate, airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        dispatch(
            airportActions.createGate({
                ...gate,
                id: response.data.id,
                cameras: [],
            }),
        );
        toast.success('Gate created successfully');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const updateGate = (gateId, gate, airportIATA) => async (dispatch) => {
    try {
        const response = await gateAPI.updateGate(gateId, gate, airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        dispatch(airportActions.updateGate(gate));
        toast.success('Gate updated successfully');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const deleteGate = (gate, airportIATA) => async (dispatch) => {
    try {
        const response = await gateAPI.deleteGate(gate.id, airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        dispatch(airportActions.deleteGate(gate));
        toast.success('Gate deleted successfully');
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
    }
};

export const fetchTerminalsByAirport = async (airportIATA) => {
    try {
        const response = await airportAPI.getTerminals(airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
        return [];
    }
};

export const fetchGatesByAirport = async (airportIATA) => {
    try {
        const response = await airportAPI.getGates(airportIATA);
        if (!checkSuccessfulStatus(response.status)) {
            throw new Error(defaultError);
        }
        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.err || error.message;
        toast.error(errorMessage);
        return [];
    }
};
