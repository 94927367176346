import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import uiActions from 'store/actions/ui.actions';
import { routes, processViews } from 'store/constants/ui.constants';
import { Preloader } from 'components/common/Preloader/Preloader';
import processActions from 'store/actions/process.actions';
import {updateListen, watchTurnaround} from 'store/controllers/process.controllers';
import {useParams} from 'react-router';
import {isEmpty} from 'lodash';
import turnaroundActions from 'store/actions/turnaround.actions';
import FlightMonitoringView from './monitoringView/FlightMonitoringView';
import FlightAdministrationView from './administrationView/FlightAdministrationView';

const Flight = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const view = useSelector(({ ui }) => ui.processView);
  const { turnaround, isLoading, isConnected, error } = useSelector((state) => state.turnaround);

  useEffect(() => {
    dispatch(uiActions.changePage(routes.TURNAROUND));
    dispatch(turnaroundActions.fetchTurnaroundRequest());
    return () => {
      dispatch(uiActions.changePage(''));
      dispatch(turnaroundActions.fetchTurnaroundSuccess({}));
      dispatch(turnaroundActions.closeConnection(async (socket) => socket.closeConnection()))
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isConnected) {
        dispatch(processActions.establishConnection(async (socket) => socket.connect('turnaround')));
        dispatch(processActions.initListener((socket, dispatch, getState) => watchTurnaround(
            socket, dispatch, getState,
            {emit: {turnaround_id: id}, event: {type: 'turnaround', meta: 'single'}})
        ));
    }
  }, [isConnected])

  useEffect(() => {
    if (isConnected) {
      dispatch(processActions.sendMessage(
          (socket, dispatch, getState) => updateListen(
              socket, dispatch, getState, {type: 'turnaround', meta: 'single'}
          )
      ));
    }
  }, [isConnected, turnaround]);

  if (isLoading) return <Preloader/>;

  if (isEmpty(turnaround)) {
    return <h3>No turnaround found with a given id</h3>;
  }

  if (error) {
    console.log(error)
    toast.error(error)
  }

  switch (view) {
    case processViews.MONITORING:
      return <FlightMonitoringView />;
    case processViews.ADMIN:
      return <FlightAdministrationView />;
    default:
      return null;
  }
};

export default Flight;
