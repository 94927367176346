import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as Icons from 'assets/img/all-icons';
import MyInput from 'components/ui/MyInput/MyInput';
import MyRadio from 'components/ui/MyRadio/MyRadio';
import Button from 'components/ui/Button/Button';

import { toast } from 'react-toastify';
import s from './OptionInput.module.scss';

const OptionInput = ({
  query,
  option,
  onClose,
  onFilterApply,
  onQueryChange,
  list,
  selectedItem,
  onItemClick,
  isLoading,
}) => {
  const [optionSelect, setOptionSelect] = useState(null);

  const onSelectChange = useCallback(
    (e) => {
      setOptionSelect(
        option.choices.find(({ label }) => label === e.target.value),
      );
    },
    [option.choices],
  );

  const onInputChange = useCallback(
    (e) => {
      onQueryChange({ value: e.target.value });
    },
    [onQueryChange],
  );

  const handleItemApply = useCallback(() => {
    if (isEmpty(selectedItem) && list.length > 0) {
      toast.warn('Filter option should be selected');
      return;
    }
    onFilterApply({ item_id: selectedItem?.id, name: query });
  }, [selectedItem, list.length, onFilterApply, query]);

  const Icon = Icons[option.icon];
  const { Close } = Icons;

  const renderMain = useCallback(
    (type) => {
      switch (type) {
        case 'input':
          return (
            <>
              <MyInput type="text" value={query} onChange={onInputChange} />
              <div className={s.itemsList}>
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  list.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => onItemClick(item)}
                      tabIndex="0"
                      role="button"
                      onKeyPress={() => {}}
                      className={classnames(s.item, {
                        [s.item_active]: item.id === selectedItem.id,
                      })}
                    >
                      <span
                        className={s.itemLabel}
                      >{`${item.name} ${option.name}`}</span>
                      <span className={s.itemCountry}>{item.extraLabel}</span>
                    </div>
                  ))
                )}
              </div>
              <div className={s.applyButton}>
                <Button onClick={handleItemApply}>Apply</Button>
              </div>
            </>
          );
        case 'select':
          return (
            <>
              {option.choices.map((choice) => (
                <div key={choice.label} className={s.select}>
                  <MyRadio
                    id={choice.label}
                    value={choice.label}
                    checked={optionSelect?.label === choice.label}
                    onChange={onSelectChange}
                  >
                    {choice.label}
                  </MyRadio>
                </div>
              ))}
              <div className={s.applyButton}>
                <Button
                  onClick={() =>
                    optionSelect &&
                    onFilterApply({
                      item_id: null,
                      ...optionSelect,
                      name: optionSelect.label,
                    })
                  }
                >
                  Apply
                </Button>
              </div>
            </>
          );
        default:
          return null;
      }
    },
    [
      handleItemApply,
      isLoading,
      list,
      onFilterApply,
      onInputChange,
      onItemClick,
      onSelectChange,
      option.name,
      option.choices,
      optionSelect,
      query,
      selectedItem.id,
    ],
  );

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div>
          <Icon width="17px" fill="#1f80b4" />
          {option.name}
        </div>
        <button type="button" className={s.close} onClick={onClose}>
          <Close width="18px" fill="#a8a8a8"/>
        </button>
      </div>
      <div className={s.main}>{renderMain(option.type)}</div>
    </div>
  );
};

OptionInput.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      extraLabel: PropTypes.string,
    }),
  ).isRequired,
  query: PropTypes.string.isRequired,
  option: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
    choices: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }),
    ),
  }).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    extraLabel: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  onFilterApply: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
};

OptionInput.defaultProps = {
  selectedItem: {},
};

export default OptionInput;
