import { IntellActAPI } from './api';

export default {
  getSafetyAlerts() {
    return IntellActAPI.get('/alert/', { params: { limit: 100 } });
  },
  createSafetyAlert(alert) {
    return IntellActAPI.post('/alert/', alert);
  },
  updateSafetyAlert(alertId, alert) {
    return IntellActAPI.put(`/alert/${alertId}/`, alert);
  },
  deleteSafetyAlert(alertId) {
    return IntellActAPI.delete(`/alert/${alertId}/`);
  },
};
