import React from 'react';
import AccountItem from './AccountItem';
import './AccountsList.scss';

const AccountsList = ({ data }) => {
  return (
    <div className="accounts-list">
      {data.map((account) => {
        return <AccountItem key={account.email} {...account} />;
      })}
    </div>
  );
};

export default AccountsList;
