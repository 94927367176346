import { IntellActAPI } from './api';
// import reducer

export default {
  login(_id, password) {
    return IntellActAPI.post('/login', {_id, password}, {withCredentials: true});
  },
  updateUser(data) {
    return IntellActAPI.put(`/account/profile/`, data);
  },
  deleteUser(userId) {
    return IntellActAPI.delete(`/account/${userId}`);
  },
  updatePassword(passwords) {
    return IntellActAPI.post(`/account/password/`, passwords);
  },
  updatePermissions(permissions) {
    return IntellActAPI.put('/account/events/', permissions);
  },
};
